/**=====================
    67. Dashboard bitcoin css starts
==========================**/
.bitcoin-graph {
  svg {
    height: 50px;
    fill: $primary-color;
    vertical-align: middle;
  }
  .top-bitcoin {
    margin-left: 10px;
    padding-top: 5px;
    h5 {
      margin-bottom: 4px;
      font-weight: 600;
    }
    span {
      color: $light-text;
    }
  }
  .flex-grow-1 {
    padding-top: 5px;
    text-align: right;
    .bitcoin-content {
      .bitcoin-numbers {
        margin-right: 20px;
        h6 {
          color: $light-text;
          span {
            svg {
              width: 16px;
              height: 16px;
              stroke-width: 5px;
            }
          }
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
.chat-box {
  .chat-right-aside.bitcoin-chat {
    .chat {
      .chat-msg-box {
        .message-data {
          margin-top: -4px !important;
        }
      }
    }
  }
}
.bitcoin-chat {
  .chat-msg-box {
    ul {
      li {
        &:first-child {
          .my-message {
            .message-data {
              text-align: right !important;
            }
          }
        }
        &:last-child {
          .my-message {
            .message-data {
              text-align: right !important;
            }
          }
        }
      }
    }
  }
}
.dashboard-chart-container {
  .bitcoinchart-1,
  .bitcoinchart-2,
  .bitcoinchart-3 {
    .ct-chart {
      margin-bottom: -6px;
      svg {
        .ct-series-a {
          .ct-area {
            stroke: none;
            fill-opacity: 1;
            fill: $primary-color;
          }
          .ct-line {
            stroke: $primary-color;
            fill-opacity: 1;
            fill: transparent;
          }
        }
      }
    }
  }
}
.show-value-top {
  .d-inline-block {
    .circle-graph {
      width: 10px;
      height: 10px;
      border-radius: 100%;
      border: 1px solid $light-text;
    }
  }
  .value-third {
    margin-left: 30px;
  }
}
.chart-bottom {
  margin-top: 30px;
  h5 {
    margin-bottom: 3px;
  }
  h6 {
    color: $light-text;
  }
}
.react-tabs__tab-panel--selected {
  padding: 30px;
}
.tab-coupon {
  border-bottom: 0;
}
.crypto-accordion {
  .accordion {
    border: none;
    .accordion-item {
      margin-top: 10px;
      box-shadow: 1px 5px 24px 0 rgba($primary-color, 0.05);
      &.active {
        .title {
          background-color: $primary-color;
          &:before {
            content: "\f068";
          }
        }
      }
      &:first-child {
        margin-top: 0;
      }
      .title {
        background-color: $primary-color;
        color: $white;
        padding: 30px;
        font-weight: 600;
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        border-radius: 5px;
        &:after {
          display: none;
        }
        &:before {
          content: "\f067";
          font-family: FontAwesome;
          float: right;
          font-weight: 100;
        }
      }
      .panel {
        .media-accordion {
          .d-flex {
            padding: 30px;
            + .d-flex {
              border-top: 1px solid $light-gray;
            }
            h6 {
              font-weight: 600;
            }
            p {
              margin-bottom: 1px;
              color: $light-text;
              &:last-child {
                margin-bottom: 0;
              }
            }
            &:nth-child(2) {
              background-color: $light;
            }
            .flex-grow-1 {
              p {
                color: $theme-body-font-color;
                &:first-child {
                  font-weight: 600;
                }
              }
            }
          }
        }
      }
    }
  }
}
.market-table {
  table {
    thead {
      tr {
        th {
          border-top: none;
          padding-top: 0;
          padding-bottom: 30px;
          + th {
            text-align: center;
          }
        }
      }
    }
    tbody {
      tr {
        &:last-child {
          border-bottom: 1px solid transparent;
        }
        td {
          padding: 30px 0;
          p {
            color: $light-text;
          }
          .text-center {
            display: flex;
            justify-content: center;
          }
          svg {
            width: 18px;
            height: 18px;
            stroke-width: 4px;
          }
          + td {
            text-align: center;
          }
          + td {
            p {
              margin-bottom: 2px;
            }
          }
        }
        &:last-child {
          td {
            padding-bottom: 0;
          }
        }
      }
    }
  }
}
.sales-block {
  canvas {
    height: 387px !important;
  }
}
.market-tabs {
  border-bottom: none;
  border-top: 1px solid $primary-color;
  &.nav {
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    .nav-link {
      width: 100%;
      height: 81px;
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
      border-radius: 4px;
      padding-top: 30px;
      text-align: center;
      border: none;
      &.react-tabs__tab--selected {
        background-color: $primary-color;
        color: $white;
      }
    }
  }
}
#linecharts-bitcoin {
  height: 381px !important;
}
.icons-section {
  h6 {
    margin-top: 18px;
    margin-bottom: 10px;
  }
  h5 {
    margin-bottom: 0;
    font-weight: 600;
    span {
      svg {
        width: 18px;
        height: 18px;
        stroke-width: 4px;
      }
    }
  }
}
.chat-box {
  .bitcoin-chat {
    .chat {
      .chat-msg-box {
        margin-bottom: 30px;
        height: auto;
        padding: 20px 0 0 0;
        min-height: auto;
        ul {
          margin-bottom: 0;
          li {
            &:nth-child(3) {
              margin-top: 21px;
            }
          }
        }
        .message {
          width: 79%;
          padding: 10px;
          &.my-message {
            background-color: rgba($primary-color, 0.15);
            .chat-user-img {
              border: 2px solid rgba($primary-color, 0.15);
            }
          }
          &.other-message {
            background-color: $light-gray;
            margin-bottom: 30px;
            margin-top: 22px;
            .chat-user-img {
              border: 2px solid #dad9d9;
            }
          }
        }
        .chat-user-img {
          margin-top: -31px;
          width: 45px;
        }
      }
      .bitcoin-message {
        .smiley-box {
          padding: 10px;
          border-top-left-radius: 0.25rem;
          border-bottom-left-radius: 0.25rem;
        }
        .text-box {
          .input-group-append {
            display: flex;
          }
          input {
            border-radius: 0;
            height: 49px;
            &:focus {
              border-color: $primary-color;
              box-shadow: none;
            }
            border-left: 0px;
          }
        }
      }
    }
  }
}
.market-chart {
  .ct-chart {
    height: 287px;
    svg {
      .ct-grids {
        stroke-width: 1px;
        stroke: rgba(0, 0, 0, 0.1);
      }
      .ct-series-a {
        .ct-bar {
          stroke: $primary-color;
        }
      }
      .ct-series-b {
        .ct-bar {
          stroke: $secondary-color;
          stroke-width: 12px;
        }
      }
    }
  }
}
.invest-dropdown {
  .btn {
    position: relative;
    background-color: transparent;
    padding: 0;
    color: $light-text;
  }
  .right-header-dropdown.onhover-show-div {
    padding: 20px;
    width: 100px;
    top: 30px;
    left: -22px;
    &:before,
    &:after {
      left: unset;
      right: 25px;
    }
    a {
      color: $theme-body-font-color;
    }
  }
}
.bitcoin-header {
  padding-top: 1px;
}
.bottom-morris-chart {
  margin-top: 21px;
}
.bitcoin-form {
  .form-row {
    display: flex;
    flex-wrap: wrap;
  }
  .input-group {
    .input-group-prepend {
      display: flex;
      .input-group-text {
        background-color: transparent;
      }
    }
  }
  label {
    color: $light-text;
  }
  .bitcoin-form-dropdown {
    .onhover-dropdown {
      .btn {
        padding: 8px 0;
        background-color: $light;
        color: $light-text;
        width: 100%;
      }
      .onhover-show-div {
        left: 0;
        right: 0;
        margin: 0 auto;
        padding: 20px;
        width: 100px;
        top: 50px;
        a {
          color: $light-text;
        }
      }
    }
  }
  .btn-bottom {
    .btn {
      width: 100%;
    }
  }
}
.right-header-color p {
  color: $light-text;
}
.active-order-table {
  table {
    th,
    td {
      padding-top: 30px;
      padding-bottom: 30px;
    }
    thead {
      tr {
        th {
          padding-top: 0;
          border-top: none;
          &:nth-child(2) {
            padding-left: 37px;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          vertical-align: middle;
          border-bottom: 1px solid $light-gray;
          p {
            margin-bottom: 3px;
            color: $light-text;
          }
          .btn {
            font-size: 13px;
          }
        }
        &:last-child {
          td {
            border-bottom: none;
            padding-bottom: 0;
          }
        }
      }
    }
  }
}
.markets {
  margin-bottom: 30px;
  align-items: center;
  .flex-grow-1 {
    margin-left: 20px;
  }
}
@media screen and (min-device-width: 1200px) and (max-device-width: 1440px) {
  .d-flex {
    &.bitcoin-graph {
      display: block !important;
      .flex-grow-1 {
        padding-top: 30px;
        .text-end {
          text-align: center !important;
        }
        .bitcoin-content {
          .bitcoin-numbers {
            h6 {
              font-size: 14px;
            }
          }
        }
      }
      .top-bitcoin {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
  .markets {
    p {
      height: 59px;
      overflow: auto;
    }
  }
  .active-order-table {
    table {
      tbody {
        tr {
          td {
            &:first-child {
              min-width: 110px;
            }
            &:nth-child(3) {
              min-width: 200px;
            }
            &:nth-child(5) {
              min-width: 100px;
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1200px) and (max-width: 1366px) {
  .bitcoin-graph {
    .flex-grow-1 {
      .bitcoin-content {
        .bitcoin-numbers {
          margin-right: 16px;
        }
      }
    }
  }
}
@media only screen and (max-width: 1366px) {
  #bitcoin-morris {
    canvas {
      height: 350px !important;
      width: auto !important;
      margin: 0 auto;
    }
  }
  .chat-box {
    .chat-right-aside {
      &.bitcoin-chat {
        flex: 0 0 100%;
        max-width: 100%;
        .chat {
          .chat-msg-box {
            height: 100%;
            .chat-user-img {
              margin-top: -37px;
            }
          }
        }
      }
    }
    .bitcoin-chat {
      .chat {
        .chat-msg-box {
          padding: 26px 0 0 0;
        }
      }
    }
  }
  .active-order-table {
    max-width: 443px;
    overflow: auto;
    .customers {
      width: 175px;
      ul {
        li {
          p {
            width: auto;
          }
        }
      }
    }
    table {
      tbody {
        tr {
          td {
            p {
              width: 100px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1199px) {
  .chat-box {
    .chat-right-aside {
      &.bitcoin-chat {
        .chat {
          .chat-msg-box {
            .message {
              width: 90%;
            }
          }
        }
      }
    }
  }
  .active-order-table {
    max-width: 100%;
    overflow: auto;
  }
}
@media only screen and (max-width: 991px) {
  .react-tabs__tab-panel--selected {
    padding: 20px;
  }
  .chart-bottom {
    margin-top: 20px;
  }
  .market-table {
    table {
      thead {
        tr {
          th {
            padding-bottom: 20px;
          }
        }
      }
      tbody {
        tr {
          td {
            padding: 20px 0;
          }
        }
      }
    }
  }
  .market-tabs {
    &.nav {
      .nav-link {
        height: 60px;
        padding-top: 20px;
      }
    }
  }
  .crypto-accordion {
    .accordion {
      .accordion-item {
        .title {
          padding: 20px;
        }
        .panel {
          .media-accordion {
            .d-flex {
              padding: 20px;
            }
          }
        }
      }
    }
  }
  .chat-box {
    .chat-right-aside {
      &.bitcoin-chat {
        .chat {
          .chat-msg-box {
            margin-bottom: 20px;
            .message {
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }
  .bottom-morris-chart {
    margin-top: 11px;
  }
  .active-order-table {
    table {
      th,
      td {
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }
  }
  .icons-section {
    h5 {
      span {
        svg {
          width: 14px;
          height: 14px;
        }
      }
    }
  }
  .markets {
    margin-bottom: 20px;
  }
  .show-value-top {
    .value-third {
      margin-left: 20px;
    }
  }
}
@media only screen and (max-width: 575px) {
  #bitcoin-morris {
    canvas {
      height: 250px !important;
    }
  }
  .crypto-accordion {
    .accordion {
      .accordion-item {
        .title {
          padding: 15px;
        }
        .panel {
          .media-accordion {
            .d-flex {
              padding: 15px;
            }
          }
        }
      }
    }
  }
  .react-tabs__tab-panel--selected {
    padding: 15px;
  }
  .sales-block {
    canvas {
      height: 219px !important;
    }
  }
  .chart-bottom {
    margin-top: 15px;
  }
  .bitcoin-graph {
    .flex-grow-1 {
      .bitcoin-content {
        .bitcoin-numbers {
          margin-right: 15px;
        }
      }
    }
  }
  .market-table {
    table {
      thead {
        tr {
          th {
            padding-bottom: 15px;
          }
        }
      }
      tbody {
        tr {
          td {
            padding: 15px 0;
          }
        }
      }
    }
  }
  .market-tabs {
    &.nav {
      .nav-link {
        height: 50px;
        padding-top: 15px;
      }
    }
  }
  .icons-section {
    h6 {
      margin-top: 15px;
    }
  }
  .chat-box {
    .chat-right-aside {
      &.bitcoin-chat {
        .chat {
          .chat-msg-box {
            margin-bottom: 15px;
            .message {
              margin-bottom: 15px;
            }
          }
        }
      }
    }
  }
  .active-order-table {
    table {
      th,
      td {
        padding-top: 15px;
        padding-bottom: 15px;
      }
    }
  }
  .markets {
    margin-bottom: 15px;
    .flex-grow-1 {
      margin-left: 15px;
    }
  }
  .show-value-top {
    .value-third {
      margin-left: 15px;
    }
  }
  .text-end {
    &.right-header-color {
      text-align: center !important;
    }
  }
}

@media only screen and (max-width: 360px) {
  .sales-block {
    canvas {
      height: 180px !important;
    }
  }
  .d-flex {
    &.bitcoin-graph {
      display: block !important;
      .top-bitcoin {
        display: inline-block;
        vertical-align: middle;
      }
      .flex-grow-1 {
        padding-top: 15px;
        .bitcoin-content {
          &.text-end {
            text-align: center !important;
          }
        }
      }
    }
  }
  .markets {
    align-items: end;
  }
}

/**=====================
     67. Dashboard bitcoin  CSS Ends
==========================**/

@font-face {
  font-family: "Titillium Web";
  src: url("/public/fonts/TitilliumWeb-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Titillium Web";
  src: url("/public/fonts/TitilliumWeb-ExtraLightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: "Titillium Web";
  src: url("/public/fonts/TitilliumWeb-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Titillium Web";
  src: url("/public/fonts/TitilliumWeb-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Titillium Web";
  src: url("/public/fonts/TitilliumWeb-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Titillium Web";
  src: url("/public/fonts/TitilliumWeb-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: "Titillium Web";
  src: url("/public/fonts/TitilliumWeb-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Titillium Web";
  src: url("/public/fonts/TitilliumWeb-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Titillium Web";
  src: url("/public/fonts/TitilliumWeb-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Titillium Web";
  src: url("/public/fonts/TitilliumWeb-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Titillium Web";
  src: url("/public/fonts/TitilliumWeb-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

html {
  height: 100%;
}

#root {
  width: 100%;
}

body {
  background-color: rgba(19, 95, 149, 0.03);
  color: #313131;
  font-family: "Titillium Web", sans-serif !important;
  font-size: 14px;
  margin: 0; /* removes default style */
  display: flex; /* enables flex content for its children */
  box-sizing: border-box;
  overflow: hidden;
}

h1 {
  font-size: 22px !important;
}

h2 {
  font-size: 20px !important;
}

h3 {
  font-size: 18px !important;
  margin-bottom: 0 !important;
}

h4 {
  font-size: 16px !important;
}

h5 {
  font-size: 14px !important;
}

.overflow-hidden {
  overflow: hidden !important;
}
a {
  text-decoration: none;
}

textarea {
  border: 1px solid #d8d8d8 !important;
}
/* 
textarea:focus-visible {
  outline: 0 !important;
  box-shadow: 0 !important;
  border: 0px solid #d8d8d8 !important;
} */

.ag-theme-alpine {
  --ag-font-family: "Titillium Web", sans-serif !important;
  font-family: "Titillium Web", sans-serif !important;
}

/* .ruleGroup {
  background-color: #fff !important;
  border-color: #ececec !important;
} */

.ruleGroup-combinators,
.rule-value {
  width: 200px !important;
  background: transparent !important;
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px !important;
  width: 100%;
  min-width: 0px;
  outline: 2px solid transparent;
  outline-offset: 2px;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  padding-bottom: 1px;
  border: 1px solid;
  border-top-color: currentcolor;
  border-right-color: currentcolor;
  border-bottom-color: currentcolor;
  border-left-color: currentcolor;
  border-color: #d8d8d8;
}

.ruleGroup-addRule,
.ruleGroup-addGroup {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: 2px solid transparent;
  outline-offset: 2px;
  line-height: 1.2;
}

.queryBuilder-dragHandle {
  font-size: 20px;
}

.ruleGroup-remove,
.rule-remove {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  outline: 2px solid transparent;
  outline-offset: 2px;
  line-height: 1.2;
}

.rule-fields,
.rule-operators {
  height: 38px !important;
  width: 150px !important;
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px !important;
  background: transparent !important;
  width: 100%;
  min-width: 0px;
  outline: 2px solid transparent;
  outline-offset: 2px;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  padding-bottom: 1px;
  border: 2px solid;
  border-top-color: currentcolor;
  border-right-color: currentcolor;
  border-bottom-color: currentcolor;
  border-left-color: currentcolor;
}

.rule-value:focus-visible {
  border: 0 !important;
  outline: 0 !important;
}

.chakra-button {
  background-color: transparent !important;
}

.rule-remove,
.ruleGroup-remove {
  background: #f86b4f !important;
  color: #fff;
}

.ruleGroup-addRule,
.ruleGroup-addGroup {
  background-color: transparent !important;
}

.page-wrapper .page-body-wrapper .page-sidebar .main-header-left .logo-wrapper {
  align-items: center;
  display: flex;
  height: 100%;
  padding-left: 10px;
  flex-direction: column;
}

.page-wrapper .page-body-wrapper .sidebar {
  height: calc(100vh - 80px);
  overflow: auto;
  background-color: rgb(5, 30, 52);
  color: white;
}

.page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu > li > a.active {
  color: rgb(100, 181, 246) !important;
  transition: 0.3s;
}

body.light-only .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu > li > a,
body.light-only .page-wrapper .page-body-wrapper .page-sidebar .sidebar-user p {
  color: white;
  font-weight: 400;
}

body.light-only .page-wrapper .page-body-wrapper .page-sidebar .sidebar-menu > li > a:hover {
  color: inherit;
  opacity: 0.8;
}

.footer {
  position: relative;
}

.sidebar-header {
  display: flex !important;
  align-items: center !important;
}

.custom-scrollbar {
  transition: none;
}

.flex-nowrap {
  display: flex !important;
  flex-wrap: nowrap !important;
}

/* Main Content Area */

.page-wrapper .page-body-wrapper .page-header {
  min-height: 83vh;
  padding-top: 30px;
  background-color: #fff;
  border-left: 1px solid #e5e8eb;
  border-right: 1px solid #e5e8eb;
  color: rgba(0, 0, 0, 0.87);
}

.minHeightResp {
  min-height: 83vh;
  padding-top: 30px;
  background-color: #fff;
  border-left: 1px solid #e5e8eb;
  border-right: 1px solid #e5e8eb;
  color: rgba(0, 0, 0, 0.87);
}

.ag-root-wrapper {
  border: 0 !important;
}

.ag-paging-panel {
  border-top-color: #efefef !important;
  border-bottom: 1px solid #efefef;
}

footer {
  background-color: #fff;
  border-top: 1px solid #f6f7fb;
  bottom: 0;
  left: 0;
  padding: 13px;
}

.page-size-select {
  position: absolute;
  transform: translateY(-31px);
  right: 400px;
  border: 1px solid #efefef;
  color: #181d1f;
  font-size: 14px;
}

/* Inputs */

.px-30px {
  padding-left: 30px;
  padding-right: 30px;
}
.td-active {
  padding: 0 8px;
}
.td-active:active {
  padding: 0 !important;
}
.noBorders {
  border: 0 !important;
  outline: none !important;
  box-shadow: none !important;
}

.segment-details-table {
  background-color: #fff;
  max-height: 0;
  opacity: 0;
  transition: max-height 0.15s ease-out;
  overflow: hidden;
}

.segment-details-table.table-open {
  margin-top: 15px;
  border: 1px solid #d2d2d2;
  max-height: 1000px;
  opacity: 1;
  transition: max-height 0.6s linear;
}

.custom-filter-select:focus-visible,
.custom-filter-select:focus {
  outline: 0;
  border: 0;
}

.custom-filter-select__control--menu-is-open,
.custom-filter-select__control--is-focused {
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.1) !important;
}

.custom-filter-select__control {
  width: 150px;
  border-radius: 8px !important;
  border: 0 !important;
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.1);
}

.segment-details-table div:nth-child(odd) {
  background-color: #f4f4f4;
}

.logo {
  width: 100%;
  height: 100%;
  max-width: 130px;
  max-height: 42.63px;
  display: block;
  margin: 0 auto;
}

.top-header-user-info {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: fit-content;
  gap: 15px;
}

.top-header-user-info h4 {
  text-align: left;
  font-size: 14px;
  color: #707070;
  margin-bottom: 0;
}

.top-header-settings {
  margin-right: 0 !important;
  font-size: 24px;
  color: var(--primaryColor) !important;
}

.top-header-settings:hover {
  cursor: pointer;
}

.ag-checkbox-input-wrapper {
  background: transparent !important;
}

.ag-header-select-all {
  transform: translateX(20px);
  height: 29px;
}

.ag-header-select-all .ag-checkbox-input-wrapper {
  height: 27px;
  background: #e9ecef !important;
  border-radius: 0;
}

.header-checkbox {
  height: 29px;
  border: 0;
}

.ag-header-select-all .ag-checkbox-input-wrapper::after {
  transform: translate(8px, 5px);
  z-index: 999;
}

.header-checkbox:hover.btn-white {
  background-color: #e9ecef !important;
}

.btn:hover {
  z-index: 2 !important;
  border: 1px solid !important;
  background-color: transparent !important;
  color: var(--primaryColor) !important;
  outline: 0 !important;
}

.data-table-checkbox:hover.btn-white {
  background-color: #e9ecef !important;
}

.p-no-margin p,
.label-no-margin label {
  margin-bottom: 0 !important;
}

.gap-40 {
  gap: 40px;
}

.header-checkbox {
  width: 36px;
  transform: translateX(-6px);
  height: 29px;
  border: 0 !important;
  font-size: 11px;
}

.translate-55px {
  transform: translate(20px, -2.5px) !important;
  background: #e9ecef;
  width: 38px;
}

.ag-input-field {
  z-index: 999999;
}

.dropdown-toggle::after {
  top: -1px !important;
  left: 8px !important;
}

.ag-checkbox-input-wrapper::after {
  z-index: 999;
}

.ag-checkbox-input-wrapper input {
  background-color: transparent !important;
}

.ag-checkbox-input-wrapper:focus-within,
.ag-checkbox-input-wrapper:active {
  box-shadow: none !important;
}

.no-checkbox-table .ag-cell-value {
  transform: translateX(0);
}

.no-checkbox-table .ag-ltr .ag-cell:not(:nth-child(3)) {
  padding-left: 18px !important;
}

.ag-cell-value {
  transform: translateX(-55px);
}

.right-menu nav {
  width: 100%;
  margin-bottom: -14px;
}

.right-menu label {
  width: 100%;
  margin-bottom: 0;
}

.right-menu span {
  color: 313131;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  display: block;
  text-transform: capitalize;
  content: "";
  width: 100%;
}

.right-menu .span-with-arrow {
  content: "";
  background-size: 16px;
  background-position: 100%;
  background-repeat: no-repeat;
  width: 100%;
}

.right-menu span::after {
  float: right;
  right: 10%;
  content: "";
}

.right-menu .slide {
  clear: both;
  width: 100%;
  height: 0px;
  overflow: hidden;
  display: grid;
  gap: 20px;
  text-align: left;
  margin-top: 10px;
}

.right-menu .slide li:last-child {
  margin-bottom: 20px;
}

.right-menu .slide li a {
  color: #313131;
  padding: 0 6px;
}

.right-menu .slide li:before {
  content: "•";
}

.right-menu .slide li:hover {
  background-color: #70707064;
}

.right-menu #touch {
  position: absolute;
  opacity: 0;
  height: 0px;
}

.right-menu #touch:checked + .slide {
  display: grid;
  gap: 10px;
  text-align: left;
  height: fit-content;
}

.right-menu-ul {
  width: 100%;
  padding: 10px;
  display: grid;
  gap: 10px;
}

.right-menu-ul li {
  width: 100%;
}

.right-menu-flex {
  display: flex !important;
  gap: 15px;
  padding: 0 6px 0 10px;
  align-items: center;
}

.right-menu-flex span,
.right-menu-flex .right-menu-icons {
  transition: 0.3s ease;
}

.right-menu svg line {
  color: #313131 !important;
}

.right-menu-flex:hover span,
.right-menu-flex:hover .right-menu-icons {
  transition: 0.3s ease;
  color: var(--primaryColor) !important;
  transform: translateX(5px);
}

.right-menu-flex:hover svg line {
  color: var(--primaryColor) !important;
}

.right-menu-icons {
  margin-top: 2px !important;
  height: 22px !important;
  width: 22px !important;
  color: #313131 !important;
}

.w-50 {
  width: 50% !important;
}

.w-80 {
  width: 80% !important;
}

.w-20 {
  width: 20% !important;
}

.flex {
  display: flex;
}

.ag-header-row-column-filter {
  z-index: -1 !important;
}

.items-center {
  align-items: center !important;
}

.gap-10 {
  gap: 10px;
}

.p-6 {
  padding: 6px !important;
}

.p-8 {
  padding: 8px !important;
}

.h-38 {
  height: 38px !important;
}

.w-25 {
  width: 25% !important;
}

.gap-5px {
  gap: 5px;
}

.p-10 {
  padding: 10px;
}

.text-area-custom {
  border: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.07);
}

.justify-center {
  justify-content: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-start {
  justify-content: flex-start;
}

.filter-tab-date {
  background-color: #fff;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  border-radius: 8px;
  padding: 12px 20px;
}

.date-picker-with-image {
  background: url("https://cdn-icons-png.flaticon.com/512/2370/2370264.png") no-repeat 5%;
  background-size: 22px 22px;
}

.ck-editor__editable_inline {
  min-height: 250px;
}

.height120 .ck-editor__editable_inline {
  min-height: 120px;
}

.cursor-disabled:hover {
  cursor: not-allowed;
}

.dzu-previewContainer {
  flex-direction: column !important;
  justify-content: center !important;
  width: fit-content !important;
  height: fit-content !important;
  gap: 20px !important;
  background: #e1e1e1 !important;
  border-radius: 14px !important;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.mx-20px {
  margin-left: 20px;
  margin-right: 20px;
}

.ml-20px {
  margin-left: 20px;
}

hr {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  border-top: 3px solid #888;
  box-sizing: content-box;
  height: 0;
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.tt-10 {
  transform: translateY(10px) !important;
}

.mb-30 {
  margin-bottom: 30px;
}

.ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within {
  border: 0 !important;
}

/* .ag-cell {
  display: flex!important;
} */

.ag-cell {
  white-space: nowrap;
}

.ag-header-cell {
  white-space: nowrap;
}

.download-button {
  padding: 0 10px;
  height: 100%;
  display: grid;
  place-items: center;
  background: #f4f4f4;
  border-radius: 0 6px 6px 0;
  border: 1px solid #d2d2d2;
}

.publish-button {
  padding: 0 5px;
  height: 100%;
  display: grid;
  place-items: center;
  background: #f4f4f4;
  border-radius: 6px 0px 0px 6px;
  border: 1px solid #d2d2d2;
}

.ag-header-cell-comp-wrapper {
  overflow: visible !important;
}

.ag-header,
.ag-header-row,
.ag-header-viewport {
  overflow: visible !important;
  z-index: 10;
}

.ag-popup-child {
  z-index: 11 !important;
  transform: translateX(-70%);
}

.frame {
  border: 0;
  width: 136vh;
  height: 65em;
  -ms-transform: scale(0.25);
  -moz-transform: scale(0.25);
  -o-transform: scale(0.25);
  -webkit-transform: scale(0.25);
  transform: scale(0.25);
  -ms-transform-origin: 0 0;
  -moz-transform-origin: 0 0;
  -o-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.normal-btn {
  background-color: transparent !important;
  box-shadow: none !important;
  border: 0 !important;
  padding: 0 !important;
  color: inherit !important;
}

.normal-btn .btn-white:hover {
  background-color: transparent !important;
}

.widget-menu {
  z-index: 10;
  position: absolute;
  width: fit-content;
  right: 15px;
  padding: 10px 0px;
  opacity: 0;
  visibility: hidden;
}

.widget-menu.open {
  -webkit-transform: translateY(0px) scaleY(1);
  transform: translateY(0px) scaleY(1);
  opacity: 1;
  visibility: visible;
  transition: all linear 0.3s;
  z-index: 100;
}

.edit-menu-item {
  padding: 5px 10px;
}

.edit-menu-item:hover {
  background-color: var(--primaryColor) !important;
  cursor: pointer;
  color: #fff;
}

.edit-menu-item:hover svg {
  color: #fff !important;
}

.widget-menu-tab {
  background-color: #fff;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
  border-radius: 8px;
  padding: 6px 6px;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.overflow-y-scroll::-webkit-scrollbar {
  width: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--primaryColor) !important;
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 0;
}

.under-title {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  padding: 0;
}

.under-title h3 {
  padding: 6px;
  color: #333;
}

.titledot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--primaryColor) !important;
  margin-right: 7px;
  display: inline-block;
  transition: all ease-in-out 0.31s;
}

.titleline {
  content: "";
  border: 1px solid #e5e5e5;
  border-width: 1px 0;
  flex-grow: 1;
  height: 4px;
  transition: all ease-in-out 0.31s;
}

.w-fit {
  width: fit-content !important;
}

.w-screen {
  width: 100vw !important;
}

.h-screen {
  height: 100vh !important;
}

/* Buttons */
.btn-white,
.ruleGroup-addRule,
.ruleGroup-addGroup {
  background-color: #e9ecef;
  color: #686868;
  padding: 6px;
  border: 1px solid #e0e0ef;
  transition: 0.3s ease-in-out;
}

.btn-white:hover {
  background-color: #e9ecef3d;
}

.btn-primary,
.btn-success {
  background-color: var(--primaryColor) !important;
  color: #fff !important;
  padding: 6px 12px !important;
  border: 1px solid var(--primaryColor) !important;
}

.formio-component .nav-item .active {
  background-color: var(--primaryColor) !important;
  color: #fff !important;
}

.formio-component .nav-item a {
  color: #313131 !important;
}

.btn-primary:hover,
.btn-success:hover {
  background-color: #1d83cc !important;
}

.btn-secondary:hover {
  background: rgb(155, 166, 176) !important;
}

.btn-danger:hover {
  background: #e14958 !important;
}

.formcomponent {
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
}

.ruleGroup-addRule,
.ruleGroup-addGroup {
  background-color: #e9ecef;
  color: #686868;
  padding: 0px 10px;
  border: 1px solid #e0e0ef;
}

.custom-select__menu .btn-white:hover,
.ruleGroup-addRule:hover,
.ruleGroup-addGroup:hover {
  z-index: 2;
  background-color: #f5f5f5;
  /* border: #e0e0ef 1px !important; */
}

.category-box-shadow {
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 30%), 0 2px 10px 0 rgb(0 0 0 / 40%);
}

.dropdown-toggle::after {
  display: inline-block !important;
  position: relative;
  margin-left: 0.255em !important;
  top: 0;
  -webkit-transform: translateY(-50%) !important;
  transform: translateY(50%) !important;
  content: "" !important;
  border-top: 0.4em solid !important;
  border-right: 0.4em solid transparent !important;
  border-bottom: 0 !important;
  border-left: 0.4em solid transparent !important;
  font-size: 12px;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: var(--primaryColor) !important;
}

.quill {
  border: 1px solid #d7d7d7;
  border-radius: 4px;
}

.ql-container.ql-snow {
  border: 0 !important;
}

.ql-toolbar.ql-snow {
  border-top: 0 !important;
  border-right: 0 !important;
  border-left: 0 !important;
  border-bottom: 1px solid #d7d7d7;
}

.quill .ql-editor {
  min-height: 400px;
  max-height: 400px;
  overflow-y: scroll;
  padding: 15px;
}

.quill .ql-editor::-webkit-scrollbar {
  width: 0;
}

.swal2-confirm {
  background-color: var(--primaryColor) !important;
}

.filter .search-form .form-group {
  width: 100%;
  position: relative;
}

.filter .search-form input {
  border-color: var(--primaryColor) !important;
  box-shadow: 0 0 5px rgb(0 0 0 / 12%);
  padding: 5px 5px 5px 70px;
  background-color: #fff;
}

.filter .search-form input::placeholder {
  color: var(--primaryColor) !important;
}

.filter .search-form input:focus {
  outline-color: var(--primaryColor) !important;
}

.filter .search-form .form-group:focus {
  outline-color: transparent;
}

.filter .search-form .form-group:focus {
  outline-color: transparent;
}

.filter .search-form .form-group:before {
  position: absolute;
  content: "";
  width: 1px;
  height: 25px;
  background: var(--primaryColor) !important;
  left: 51px;
  top: 4px;
  z-index: 2;
}

.filter .search-form .form-group:after {
  position: absolute;
  content: "\f002";
  font-family: FontAwesome;
  top: 6px;
  left: 22px;
  color: var(--primaryColor) !important;
}

.cursor-pointer:hover {
  cursor: pointer;
}

.cursor-grab:hover {
  cursor: grab;
}

.filter-icons {
  background-color: transparent;
  width: 43px;
  height: 33px;
  padding: 6px;
  border: 1px solid var(--primaryColor) !important;
}

.filter-icons.active {
  background: var(--primaryColor) !important;
  color: #fff;
}

.filter-icons.active:hover {
  background: #fff !important;
  color: var(--primaryColor) !important;
  border: #fff;
}

.filter-icons:hover {
  cursor: pointer;
  background-color: var(--primaryColor) !important;
  color: #fff !important;
  border-color: #fff;
}

.filter-icons:hover svg {
  color: #fff !important;
}

.filter-icons.active:hover svg {
  color: var(--primaryColor) !important;
}

.nodatacomponent {
  border: 1px solid var(--primaryColor) !important;
  padding: 50px;
  text-align: center;
  width: 100%;
  display: grid;
  place-items: center;
  min-height: 450px;
}

/* table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border: 1px solid #ddd;
}

th,
td {
    text-align: left;
    padding: 8px 12px;
}

th {
    border-bottom: 1px solid #d2d2d2;
    background: #f3f3f3;
}

th:not(:last-child),
td:not(:last-child) {
  border-left: 1px solid #d2d2d2;
} */

.py-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.px-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.mt-30 {
  margin-top: 30px;
}

.mt-20 {
  margin-top: 20px !important;
}

.custom-loader .line:nth-child(1),
.custom-loader .line:nth-child(2),
.custom-loader .line:nth-child(3),
.custom-loader .line:nth-child(4) {
  background-color: var(--primaryColor) !important;
}

.__rdt_custom_sort_icon__ {
  transform: translate(5px, 3px) !important;
}

.rdt_Table {
  border: 0;
  background-color: transparent !important;
  box-shadow: 0 0 10px rgb(0 0 0 / 20%) !important;
}

.sc-dmRaPn::-webkit-scrollbar {
  width: 0;
  height: 4px;
}

.gelpcX::-webkit-scrollbar {
  width: 0;
  height: 4px;
}

.rdt_TableRow {
  padding: 0px 25px !important;
  justify-content: space-between;
  max-height: 40px !important;
  min-height: 0 !important;
  font-size: 12px !important;
}

.rdt_TableHeadRow {
  padding: 10px 25px;
  background-color: #f2f2f2 !important;
  justify-content: space-between;
  max-height: 45px;
  min-height: 0 !important;
}

.rdt_TableHeadRow .TableCol {
  color: #000;
  font-size: 12px !important;
  font-weight: bold !important;
}

.table-user-name {
  color: #000000de;
  transition: 0.3s ease-in-out;
}

.table-user-name:hover {
  color: #000000a4;
}

.booking-table {
  border-collapse: collapse;
  width: 100%;
}

.booking-table td,
.booking-table th {
  border: 1px solid #d2d2d2;
  padding: 8px;
}

.booking-table tr td:first-child {
  width: 40%;
}

.booking-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #f3f3f3;
  color: #333;
}

.mandatory-field::after,
.required::after {
  content: "*";
  color: red;
  margin-left: 3px;
}

.tasks-list {
  font-size: 12px;
  list-style: circle;
  padding-left: 2rem;
}

.tasks-list li {
  margin-top: 5px;
  font-size: 12px;
}

.task-spinner-text {
  padding: 0px 3px;
  font-size: 10px;
  display: block;
  margin: 0 auto;
  text-align: center;
  background: var(--primaryColor) !important;
  color: white;
  width: fit-content;
  position: relative;
  top: -5px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.h-200-dropzone .dzu-dropzone {
  height: 200px;
  border: 2px dashed var(--primaryColor) !important;
  background: var(--activeMenuBgColor) !important;
}

.task-spinner {
  display: grid;
  place-items: center;
  padding: 10px;
  background: #efefef;
  border: 1px solid #d2d2d2;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.filteredFields p {
  text-align: start;
}

.border-bottom {
  border-bottom: 1px solid #d2d2d2;
}

.border-left {
  border-left: 1px solid #d2d2d2;
}

.py-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.py-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.tab-table {
  border-collapse: collapse;
  width: 100%;
}

.tab-table td,
.tab-table th {
  border: 1px solid #d2d2d2;
  padding: 8px;
}

.tab-table tr td:first-child {
  max-width: 45px;
}

.tab-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #f3f3f3;
  color: #333;
}

.__rdt_custom_sort_icon__ svg {
  color: #000 !important;
}

.main-section {
  transition: 0.3s ease-in-out;
  padding-right: 30px;
  padding-left: 30px;
  min-height: 94vh;
  transition: 0.3s ease-in-out;
}

.vertical-timeline-element-content .vertical-timeline-element-content-arrow {
  border-right: 15px solid #f6f7fb !important;
}

.booking-item {
  background: white !important;
  color: #3f903f;
  border-left: 1px solid #3f903f !important;
  border-top: 1px solid #3f903f !important;
  border-right: 1px solid #3f903f !important;
  border-bottom: 1px solid #3f903f;
  border-radius: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.3s ease-in-out;
  padding: 6px 10px;
}

.company-accordition {
  background: var(--primaryColor) !important;
  color: #fff;
  border-radius: 4px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.3s ease-in-out;
  padding: 6px 10px;
}

.company-accordition:hover {
  color: white !important;
}

.booking-item-red {
  background: white !important;
  color: #d9534f;
  border-left: 1px solid #d9534f !important;
  border-top: 1px solid #d9534f !important;
  border-right: 1px solid #d9534f !important;
  border-bottom: 1px solid #d9534f;
  border-radius: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: 0.3s ease-in-out;
  padding: 6px 10px;
}

.booking-item:hover {
  background-color: white !important;
  color: #3f903f;
}

.booking-item-red:hover {
  background-color: white !important;
  color: #d9534f;
}

.booking-item-content {
  padding: 10px;
  background: white;
  border: 1px solid #3f903f;
  border-top: 0;
}

.company-accordition-content {
  padding: 10px;
  background: white;
  border: 1px solid #555;
  border-top: 0;
}

.booking-item-content-red {
  padding: 10px;
  background: white;
  border: 1px solid #d9534f;
  border-top: 0;
}

.booking-item:focus {
  background: white !important;
  color: #3f903f;
  border: 1px solid #3f903f;
}

.company-accordition:focus {
  background: white !important;
  color: #555;
  border: 1px solid #555;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.booking-item-red:focus {
  background: white !important;
  color: #d9534f;
  border: 1px solid #d9534f;
}

.small-editor .ck-restricted-editing_mode_standard,
.small-editor .ck-editor__main {
  height: 100px;
  min-height: 100px;
}

.medium-editor .ck-restricted-editing_mode_standard,
.medium-editor .ck-editor__main {
  height: 150px;
  min-height: 150px;
}

.pl-12px {
  padding-left: 12px;
}

.pr-12px {
  padding-right: 12px;
}

.reportsAgGridTable > div > .ag-theme-alpine > .no-drag-table > .ag-root-wrapper.ag-ltr.ag-layout-auto-height {
  overflow-x: auto !important;
}

.reportsTablePagination {
  position: absolute;
  left: 0;
  right: 0;
  margin-top: 20px;
}

.ReportsPie > canvas {
  height: 220px !important;
  width: 220px !important;
  margin-left: auto;
  display: flex;
  margin-right: auto;
}

.historyDetails .dl-horizontal dd {
  margin-bottom: 0;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.tooltip {
  position: absolute;
  bottom: 35px;
  left: 0;
  font-size: 12px;
  padding: 3px 8px;
  max-width: 200px;
  border-radius: 3px;
  width: max-content;
  border: 1px solid black;
}

.tooltip.highlighted {
  background-color: rgb(224, 224, 224);
  color: rgb(0, 0, 0);
}

.tooltip.default {
  background-color: black;
  color: white;
}

.historyDetails table {
  border: none !important;
}

.EmailPreview td {
  text-align: center;
}

.SourceSelect .react-flow__handle,
.eventSelect .react-flow__handle {
  font-weight: bolder;
  border: 1px solid lightgray;
  border-radius: 100%;
  width: 12px;
  height: 12px;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;
  position: absolute;
  bottom: -6px;
  background-color: gray;
}

.sourceSelectBuilder option:hover,
.SourceSelectPlus:hover {
  background-color: #f3f3f3;
}

.NewCategoryPopUp .error-input-category input {
  border: 1px solid red !important;
}

@media (min-width: 1024px) {
  .main-section-padding {
    padding-left: calc(255px + 1.25rem);
    padding-right: 1.25rem;
    transition: 0.3s ease-in-out;
  }
}

.pt-80 {
  padding-top: 80px;
}

.pt-30 {
  padding-top: 30px;
}

.right-menu-ul a {
  color: #313131;
}

.rdt_TableRow:nth-child(even) {
  background-color: #f2f2f2;
}

.rdt_TableCell:not(:first-child):not(:last-child):not(:nth-child(2)) {
  border-right: 2px solid #fff;
  padding: 20px 18px;
}

.rdt_TableCell:last-child {
  padding: 20px 0px !important;
}

.rdt_TableCell div:first-child {
  overflow: visible !important;
}

.rdt_TableCol:not(:first-child) {
  padding-left: 0;
  margin-left: -3px;
}

.rdt_TableCol:not(:nth-child(2)) {
  padding-left: 3px !important;
}

.rdt_TableRow:hover {
  background-color: #135f953c;
}

.table-links {
  color: #313131;
}

.table-links:hover {
  color: #7c7c7c;
}

.main-outline-button {
  background-color: #fff;
  border: 1px solid var(--primaryColor) !important;
  color: var(--primaryColor) !important;
  border-radius: 0;
  padding: 6px;
  transition: 0.2s ease-in-out;
}

.loading-page {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.main-outline-button:hover {
  background-color: #135f9572 !important;
  color: #fff;
  border-color: #fff;
}

.input-container {
  height: 38px;
  position: relative;
  width: 100%;
}

.select-container {
  height: 40px;
  position: relative;
  width: 100%;
}

.jump-input {
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 4px;
  box-sizing: border-box;
  color: #333;
  font-size: 14px;
  height: 100%;
  outline: 0;
  padding: 4px 10px 0;
  width: 100%;
  font-family: "Titillium Web";
}

.jump-input::placeholder {
  font-size: 14px;
}

.no-border-radius {
  border-radius: 0 !important;
  max-height: 32px !important;
}

.cut {
  background-color: #fff;
  border-radius: 10px;
  height: 20px;
  left: 10px;
  position: absolute;
  top: -25px;
  transform: translateY(0);
  transition: transform 200ms;
  width: 76px;
  display: none;
}

.cut-short {
  width: 50px;
}

.pl-27 {
  padding-left: 27px;
}

.accordion-list {
  width: 100%;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  transition: 0.3s ease-in-out;
  border-radius: 8px;
  transition: 0.2s ease-in-out;
}

.dropzone-mask {
  position: absolute;
  top: 0;
  background: #135f9575;
  color: #fff;
  margin-left: -8px !important;
}

.dropzone-mask-message {
  font-size: 24px;
  text-align: center;
  width: 100%;
  height: calc(100% - 10px);
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed #fff;
  margin: 0 4px;
  cursor: default;
  width: 100%;
}

.accordion-list::-webkit-scrollbar {
  width: 0;
}

.transition-in-out {
  transition: 0.3s ease-in-out;
}

.accordion-list-item {
  padding: 10px 15px;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  margin: 10px 0;
}

.accordion-list-item:hover {
  background-color: var(--primaryColor);
  color: white;
}

.jump-input:focus ~ .cut,
.jump-input:not(:placeholder-shown) ~ .cut {
  transform: translateY(8px);
}

.jump-placeholder {
  color: rgba(0, 0, 0, 0.87);
  font-family: sans-serif;
  font-size: 14px;
  left: 10px;
  line-height: 14px;
  pointer-events: none;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  transition: color 0.2s, -webkit-transform 0.2s;
  transition: transform 0.2s, color 0.2s;
  transition: transform 0.2s, color 0.2s, -webkit-transform 0.2s;
  font-family: "Titillium Web", sans-serif !important;
}

.jump-placeholder-select {
  color: rgba(0, 0, 0, 0.87);
  font-family: sans-serif;
  font-size: 14px;
  left: 4px;
  line-height: 14px;
  pointer-events: none;
  position: absolute;
  top: -14px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  transition: color 0.2s, -webkit-transform 0.2s;
  transition: transform 0.2s, color 0.2s;
  transition: transform 0.2s, color 0.2s, -webkit-transform 0.2s;
  font-family: "Titillium Web", sans-serif !important;
}

.jump-placeholder-textarea {
  color: rgba(0, 0, 0, 0.87);
  font-family: sans-serif;
  font-size: 14px;
  left: 10px;
  line-height: 14px;
  pointer-events: none;
  position: absolute;
  top: 15px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  transition: color 0.2s, -webkit-transform 0.2s;
  transition: transform 0.2s, color 0.2s;
  transition: transform 0.2s, color 0.2s, -webkit-transform 0.2s;
  font-family: "Titillium Web", sans-serif !important;
}

.jump-input:focus ~ .jump-placeholder,
.jump-input:not(:placeholder-shown) ~ .jump-placeholder {
  transform: translateY(-35px) translateX(-10px) scale(1);
  font-size: 12px;
}

.jump-input:focus ~ .jump-placeholder-textarea,
.jump-input:not(:placeholder-shown) ~ .jump-placeholder-textarea {
  transform: translateY(-32px) translateX(0px) scale(1);
  font-size: 12px;
}

.jump-input:not(:placeholder-shown) ~ .jump-placeholder,
.jump-input:not(:placeholder-shown) ~ .jump-placeholder-textarea {
  color: rgba(0, 0, 0, 0.87);
}

.jump-input:focus ~ .jump-placeholder,
.jump-input:focus ~ .jump-placeholder-textarea {
  background-color: #e9ecef;
  border-radius: 14px;
  border-color: #000;
  color: #000;
  padding: 4px 12px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  transform: translateY(-35px);
}

.jump-input:focus ~ .cut {
  display: block;
}

.z-10 {
  z-index: 10;
}

.pr-10px {
  padding-right: 10px;
}

.pr-5px {
  padding-right: 5px;
}

.pl-5px {
  padding-left: 5px;
}

.br-1 {
  border-right: 1px solid #ebebeb;
}

.bl-1 {
  border-left: 1px solid #ebebeb;
}

.pl-10px {
  padding-left: 10px;
}

.pl-18 {
  padding-left: 18px !important;
}

.place-center {
  place-items: center;
}

.mt-10px {
  margin-top: 10px;
}

.mb-10px {
  margin-bottom: 10px;
}

.mb-20px {
  margin-bottom: 20px !important;
}
.mb-8px {
  margin-bottom: 8px !important;
}

.normal-select {
  font-size: 12px;
  background: white;
  box-shadow: 0;
  border: 1px solid #d2d2d2;
  border-radius: 12px;
  padding: 0;
}

.mt-20px {
  margin-top: 20px;
}

.mt-40px {
  margin-top: 40px;
}

.font-label {
  font-size: 13px !important;
  letter-spacing: 0.7px;
}

.ace_print-margin {
  left: 100% !important;
}

.items-start {
  align-items: start !important;
}

.switch-holder {
  display: grid;
}

.switch-label {
  width: fit-content;
}

.switch-label i {
  margin-right: 5px;
}

.switch-toggle-field {
  height: 20px;
}

.switch-toggle input[type="checkbox"] {
  position: relative;
  opacity: 0;
  z-index: -2;
  margin-left: -10px;
}

.switch-toggle input[type="checkbox"] + label {
  position: relative;
  display: inline-block;
  width: 70px;
  height: 28px;
  margin: 0;
  cursor: pointer;
  box-shadow: inset 0 0 5px rgb(0 0 0 / 20%);
  border-radius: 8px;
}

.switch-toggle input[type="checkbox"] + label::before {
  position: absolute;
  content: "NO";
  font-size: 10px;
  text-align: center;
  line-height: 28px;
  top: 0;
  left: 0;
  width: 42px;
  height: 28px;
  border-radius: 0px;
  background-color: #d1dad3;
  border-radius: 8px;
  transition: 0.3s ease-in-out;
}

.switch-toggle input[type="checkbox"]:checked + label::before {
  left: 45%;
  content: "YES";
  color: #fff;
  background-color: var(--primaryColor) !important;
  border-radius: 8px;
}

.switch-toggle-field input[type="checkbox"] {
  position: relative;
  opacity: 0;
  z-index: -2;
}

.switch-toggle-field input[type="checkbox"] + label {
  position: relative;
  display: inline-block;
  width: 70px;
  height: 28px;
  margin: 0;
  cursor: pointer;
  box-shadow: inset 0 0 5px rgb(0 0 0 / 20%);
  border-radius: 8px;
}

.switch-toggle-field input[type="checkbox"] + label::before {
  position: absolute;
  content: "NO";
  font-size: 10px;
  text-align: center;
  line-height: 28px;
  top: 0;
  left: 0;
  width: 42px;
  height: 28px;
  border-radius: 0px;
  background-color: #d1dad3;
  border-radius: 8px;
  transition: 0.3s ease-in-out;
}

.switch-toggle-field input[type="checkbox"]:checked + label::before {
  left: 45%;
  content: "YES";
  color: #fff;
  background-color: var(--primaryColor) !important;
  border-radius: 8px;
}

.switch-toggleExport {
  height: 40px;
}

.switch-toggleExport input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  z-index: -2;
}

.switch-toggleExport input[type="checkbox"] + label {
  position: relative;
  display: inline-block;
  width: 140px;
  height: 28px;
  margin: 0;
  cursor: pointer;
  box-shadow: inset 0 0 5px rgb(0 0 0 / 20%);
  border-radius: 8px;
}

.switch-toggleExport input[type="checkbox"] {
  position: absolute;
  opacity: 0;
  z-index: -2;
}

.switch-toggleExport input[type="checkbox"] + label::before {
  position: absolute;
  content: "Export Excel";
  font-size: 10px;
  text-align: center;
  line-height: 28px;
  top: 0;
  left: 0;
  width: 82px;
  height: 28px;
  border-radius: 0px;
  background-color: #128fc5;
  border-radius: 8px;
  transition: 0.3s ease-in-out;
}

.switch-toggleExport input[type="checkbox"]:checked + label::before {
  left: 45%;
  content: "Export CSV";
  color: #fff;
  background-color: #15925a !important;
  border-radius: 8px;
}

.toggle-label {
  font-size: 12px;
}

.switch-margin .switch-published {
  margin-left: 28px;
}

.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
}

.justify-end {
  justify-content: flex-end;
}

.flex-col {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.afterEdit {
  display: flex;
  align-items: center;
  gap: 5px;
}

.afterEdit::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  content: "\f304";
  display: none;
  position: absolute;
  transform: translateX(-18px);
}

.editableFields .hoverable-edit::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  content: "\f304";
  display: none;
  position: absolute;
  right: 26px;
}

.afterEdit-relative::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  content: "\f304";
  position: relative;
  display: none;
}

.add-value {
  color: #fff;
  display: none;
}

.upperCase {
  text-transform: uppercase;
}

.afterEdit:hover::before {
  display: flex;
  justify-content: flex-end;
}

.afterEdit-relative:hover::after {
  display: flex;
  justify-content: flex-end;
}

.afterEdit-relative:hover::before {
  display: flex;
  justify-content: flex-end;
}

.editableFields .hoverable-edit:hover::before,
.editableFields .hoverable-edit:hover .add-value {
  display: block;
}

/* .editableFields .hoverable-edit {
  
}

.editableFields .hoverable-edit:hover {
} */

.dataType-options-btn {
  height: 28px;
  width: 63px;
  background: #d1d1d1;
  border: 0;
  box-shadow: none;
}

.addOptions-btn {
  background-color: var(--primaryColor) !important;
  border-radius: 0;
  border: 0;
  width: 100%;
  color: white;
  margin-bottom: 10px;
}

.rdt_Pagination,
.rdt_TableRow {
  border-right: 1px solid #ebebeb;
  border-left: 1px solid #ebebeb;
}

.sc-fnykZs {
  background-color: var(--primaryColor) !important;
  color: #fff !important;
}

.mt--40px {
  transition: 0.4s ease-in-out;
  margin-top: -40px;
}

.mt--10 {
  margin-top: -10px !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt--20px {
  margin-top: -10px;
}

.relative {
  position: relative;
}

.z-10 {
  z-index: 10;
}

.z-100 {
  z-index: 100;
}

.z-200 {
  z-index: 200;
}

.br-0 {
  border-right: 0 !important;
}

.search-eraser {
  background: #e9ecef;
  height: 100%;
  display: grid;
  place-items: center;
  padding: 0 8px;
  border-top: 1px solid var(--primaryColor) !important;
  border-bottom: 1px solid var(--primaryColor) !important;
  border-right: 1px solid var(--primaryColor) !important;
}

.switch-published {
  position: relative;
  display: inline-block;
  width: 27px;
  height: 14px;
}

.switch-published input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider-published {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.p-0 {
  padding: 0 !important;
}

.form-control {
  border-radius: 0 !important;
  font-size: 18px;
}

.input-group-text {
  border-radius: 0 !important;
  font-size: 0.8rem;
}

.font-12 {
  font-size: 13px !important;
}

.slider-published:before {
  position: absolute;
  content: "";
  height: 11px;
  width: 11px;
  left: 0;
  bottom: 1.5px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider-published {
  background-color: var(--lightPrimaryColor);
}

input:focus + .slider-published {
  box-shadow: 0 0 1px var(--lightPrimaryColor);
}

input:checked + .slider-published:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.slider-published.round-published {
  border-radius: 34px;
}

.slider-published.round-published:before {
  border-radius: 50%;
}

.h-38 {
  height: 38px !important;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 10.4px 14px !important;
}

.MuiFormControl-root label {
  background: #fff !important;
  padding-right: 4px !important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root,
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  font-size: 12px !important;
  line-height: 12px !important;
  color: #d1d1d1 !important;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root.Mui-error {
  color: #313131 !important;
  border-color: #313131 !important;
}

.rdt_TableCol_Sortable {
  overflow: visible !important;
}

.data-table-checkbox {
  background-color: #e9ecef !important;
  color: #838485;
  border-radius: 0;
}

.bMgaAx div:first-child {
  overflow: visible !important;
}

.center-loader {
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  justify-content: center;
  background: #00000059;
  width: 100%;
  height: 100%;
  z-index: 99999;
}

.mb-5px {
  margin-bottom: 5px;
}

.table-buttons {
  transition: 0.3s ease-in-out;
  position: absolute;
  min-width: 87px;
}

.headingCheckBox {
  margin-left: -25px;
  margin-top: -1px;
}

.dropdownButton {
  margin-top: 6px;
}

.rdt_TableCell:nth-child(2),
.rdt_TableCol:nth-child(2) {
  position: absolute;
  left: 90px;
}

.rdt_TableCol:first-child {
  margin-right: 40px;
}

.rdt_TableCell:first-child {
  margin-right: 50px;
}

.nav-tabs .nav-link {
  border-top-left-radius: 6px !important;
  border-top-right-radius: 6px !important;
  color: #000;
  cursor: pointer;
}

.pl-10 {
  padding-left: 10px;
}

.error-input,
.error-select__control {
  border: 1px solid red !important;
}

.ck .ck-editor__main {
  max-height: 400px;
  overflow-y: scroll;
}

.ck .ck-editor__main::-webkit-scrollbar {
  width: 0;
}

.pr-10 {
  padding-right: 10px;
}

.dzu-dropzone {
  background: var(--activeMenuBgColor) !important;
  min-height: 25px !important;
}

.small-blur {
  filter: blur(2px);
}

.absolute-loading {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  padding-bottom: 50px;
}

.grid {
  display: grid;
}

.gap-30 {
  gap: 30px;
}

.justify-center {
  justify-items: center !important;
}

.w-10 {
  width: 10%;
}

.w-75 {
  width: 75%;
}

.max-w-fit {
  max-width: fit-content;
}

.mb-0 {
  margin-bottom: 0;
}

.text-center {
  text-align: center;
}

.ml-auto {
  margin-left: auto;
}

.p-30 {
  padding: 30px !important;
}

.z-9999 {
  z-index: 9999;
}

.place-self-center {
  place-self: center;
}

.info-box-button {
  width: 5em;
  color: white !important;
  height: 20px;
  padding: 13px 8px;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%) !important;
  border: 0 !important;
}

.custom-card {
  /* margin-bottom: 30px; */
  border: 0px;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  border-radius: 8px;
  box-shadow: 1px 5px 24px 0 rgb(19 95 149 / 5%);
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  padding: 20px !important;
}

.custom-card .custom-card-header {
  background-color: #fff;
  border-bottom: none;
  border-bottom: 1px solid #135f9517;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.custom-card .custom-.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.custom-card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.custom-card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
}

.dzu-inputLabel {
  margin-bottom: 0;
  padding: 10px !important;
  font-size: 13px !important;
  text-align: center;
}

.pt-40 {
  padding-top: 40px;
}

.pt-20 {
  padding-top: 20px;
}

.px-10 {
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.custom-border-radius {
  border-radius: 0 0.25rem 0.25rem 0 !important;
}

.warning-text {
  position: absolute;
  background-color: rgba(255, 94, 109, 0.2);
  border: 1px solid #eb5664;
  color: #c24753;
  padding: 20px;
  margin-right: 30px;
  display: none;
  transition: 0.3s ease-in-out;
}

.display-block {
  display: block !important;
  transition: 0.3s ease-in-out;
}

.button-with-x-background {
  background: url("https://cdn-icons-png.flaticon.com/512/1828/1828778.png") !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: 8px !important;
}

.bgd1 {
  background-color: #d1d1d1 !important;
}

.time-picker-comp fieldset {
  border-color: #d1d1d1 !important;
}

.time-picker-comp label {
  line-height: 10px !important;
  font-size: 12px;
}

.radio-btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 8.5px 15px;
  font-size: 13px;
  line-height: 1.3856;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #5d6c7c;
  background-color: #fff;
  border-color: #ddd;
}

.radio-btn:hover {
  color: #5d6c7c;
  background-color: #e6e6e6;
  border-color: #bebebe;
}

.non-active-btn {
  background-color: #fff;
  border: 1px solid var(--primaryColor) !important;
  color: var(--primaryColor) !important;
  border-radius: 0;
  padding: 6px;
  transition: 0.3s ease-in-out;
}

.active-btn {
  background-color: var(--primaryColor) !important;
  color: #fff !important;
  transition: 0.3s ease-in-out;
}

.sc-dmRaPn,
.gelpCx {
  min-height: 445px !important;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}

.ag-row,
.ag-header-row {
  z-index: 0;
}

.filter-input {
  border-radius: 0 !important;
  max-height: 20px !important;
}

.ag-row.ag-row-focus,
.ag-header.ag-header-row-focus {
  z-index: 1;
}

.ag-cell-value,
.ag-header-cell-value {
  overflow: visible !important;
}

.ag-cell {
  overflow: visible;
}

.ag-header-cell {
  overflow: visible !important;
}

.ag-cell,
.ag-header-cell {
  font-size: 12px;
}

.ag-root,
.ag-body-viewport,
.ag-body-viewport-wrapper {
  overflow: visible !important;
}

.w-full {
  width: 100% !important;
}

.max-screen-scroll {
  max-height: 90vh;
  overflow-y: auto;
}

.transition-inout {
  transition: 0.3s ease-in-out;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.bg-white {
  background-color: #fff;
}

.shadow {
  box-shadow: 0 0 10px rgb(0 0 0 / 20%);
}

.h-full {
  height: 100% !important;
}

.transition-3 {
  transition: 0.3s ease-in-out;
}

.rotate-0 {
  transition: 0.3s ease-in-out;
  transform: rotate(0) !important;
}

.rotate-180 {
  transition: 0.3s ease-in-out;
  transform: rotate(180deg);
}

.ag-input-field {
  background: #e9ecef !important;
}

.ag-ltr .ag-row-drag {
  z-index: 99999;
}

.ag-checkbox-input-wrapper::after {
  top: -6px !important;
  left: -6px !important;
  padding: 6px !important;
  font-size: 12px !important;
}

.rule-value {
  width: 150px;
}

.ag-checkbox-input-wrapper.ag-checked::after {
  top: -6px !important;
  left: -6px !important;
}

.checkbox-normal {
  min-width: 27px;
  max-width: 27px;
  font-size: 9px;
  padding: 0;
  min-height: 27px;
  max-height: 27px;
}

.nav-tabs .nav-link.active {
  border-color: var(--primaryColor) !important;
}

.checkbox-small {
  font-size: 9px;
  border: 0;
  max-height: 28px !important;
}

.custom-pagination {
  padding: 25px 20px;
  border: 1px solid #efefef;
}

.ag-root.ag-layout-normal::-webkit-scrollbar,
.ag-body-horizontal-scroll-viewport::-webkit-scrollbar {
  display: none;
}

.ag-root.ag-layout-normal {
  overflow-y: scroll !important;
}

.cursor-help:hover {
  cursor: help !important;
}

.badge p {
  margin-bottom: 0;
}

/* .badge {
  max-width: 160px;
} */
.badge {
  max-width: 100%;
  margin: 0 5px 3px 0;
  height: 32px;
  line-height: 32px;
  display: inline-block;
  padding: 0 12px;
  border-radius: 32px;
  font-size: 13px;
}
.rounded {
  border-radius: 8px;
}

.no-padding-caret::after {
  left: 0 !important;
}

.header-checkbox::after {
  top: -3px !important;
}

.MuiTreeView-root {
  height: 100% !important;
  min-height: 400px;
}

.MuiTreeItem-content.Mui-selected,
.MuiTreeItem-content.Mui-focused {
  background-color: transparent !important;
}

.MuiTreeItem-content:hover {
  background-color: rgba(0, 0, 0, 10%) !important;
}

.builder-modal {
  --bs-modal-width: 99%;
  height: 93vh;
}

.builder-modal .modal-content {
  height: 100%;
}

.MuiTreeItem-content {
  align-items: center !important;
  margin-bottom: 10px;
}

.no-drag-table .ag-ltr .ag-cell:not(:nth-child(3)) {
  padding-left: 46px;
}

.css-1g86id8-MuiTreeItem-content .MuiTreeItem-label {
  font-family: "Titillium Web", sans-serif !important;
  font-size: 12px !important;
}

.css-2cq9xd-MuiTreeView-root {
  height: 100% !important;
  min-height: 378px !important;
}

.right-click-menu {
  position: absolute !important;
  background: rgb(255, 255, 255) !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 10px !important;
  padding: 10px 0 !important;
  z-index: 999 !important;
  border-radius: 8px !important;
  display: grid !important;
  gap: 10px !important;
  transform: translateY(20px);
}

.right-click-menu a {
  color: inherit;
  padding: 3px 10px;
}

.right-click-menu a:hover {
  background-color: rgba(0, 0, 0, 0.068);
}

.css-1g86id8-MuiTreeItem-content .MuiTreeItem-iconContainer svg {
  font-size: 17px !important;
}

.rstm-tree-item-group li {
  display: flex;
  gap: 5px;
  align-items: center;
  padding: 5px;
  padding-left: 5px;
  margin-bottom: 10px;
  border-bottom: 1px solid #d2d2d2;
}

.drag-n-drop {
  left: -30px !important;
}

.view-contact-heading-bar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  padding: 20px 30px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.pl-47 {
  padding-left: 47px !important;
}

.view-contact-heading-bar-items {
  justify-content: space-between;
  width: 70%;
  max-width: fit-content;
}

.view-contact-heading-bar-items2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.header-item-2 {
  padding: 0 30px;
  display: grid;
  place-items: center;
  gap: 10px;
}

.view-contact-heading-bar-items img {
  border-radius: 6px;
  width: 200px;
  height: 200px;
  object-fit: cover;
}

.header-item-2-border {
  border-left: 1px solid #d2d2d2;
}

.view-contact-heading-bar-items2 div p {
  margin-bottom: 0;
}

.name-position {
  display: grid;
  gap: 5px;
}

#select-input,
#full-search {
  width: 100%;
}

.p6 {
  padding: 10px;
}

.history-filter {
  border: 1px solid #ebedf0;
  border-radius: 0;
  box-shadow: 0 10px 40px 0 rgba(62, 57, 107, 0.07), 0 2px 9px 0 rgba(62, 57, 107, 0.06);
  padding: 20px;
  display: grid;
  place-items: center;
  gap: 20px;
  width: 100%;
  margin-bottom: 30px;
}

.history-table-header,
.advancedReport-table-header,
.logsTable-header {
  background-color: #f3f3f3;
}

.history-table-content,
.advancedReport-table-content,
.logsTable-content {
  background-color: #ffffff;
}

.history-table,
.advancedReport-table,
.logsTable {
  border: 1px solid #e1e1e1;
}

.history-table-header div:not(:last-child),
.history-table-content div:not(:last-child),
.advancedReport-table-header div:not(:last-child),
.advancedReport-table-content div:not(:last-child),
.logsTable-header div:not(:last-child),
.logsTable-content div:not(:last-child) {
  border-right: 1px solid #e1e1e1;
}

.history-table-header div:not(:first-child),
.advancedReport-table-header div:not(:last-child),
.history-table-content div:not(:first-child),
.advancedReport-table-content div:not(:last-child),
.logsTable-content div:not(:last-child),
.advancedLastDiv {
  min-height: 57px;
  display: grid;
  place-items: center start;
}

.history-table-content,
.advancedReport-table-content,
.logsTable-content {
  border-top: 1px solid #e1e1e1;
}

.grid-center {
  display: grid;
  place-items: center !important;
}

.table-link {
  color: inherit;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.gap-20 {
  gap: 20px !important;
}

.p-20 {
  padding: 20px !important;
}

.checkmark-container {
  display: block;
  position: relative;
  padding-left: 23px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.sidebar-submenu li a {
  color: #fff !important;
  transition: 0.3s ease-in-out !important;
}

.btn {
  border-radius: 0 !important;
  border-color: transparent !important;
}

.sidebar-submenu li a i {
  font-size: 6px;
  margin-top: 2px;
}

.sidebar-submenu li a:hover {
  padding-left: 3px;
}

.checkmark-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #eee;
}

.checkmark-container:hover input ~ .checkmark {
  background-color: #ccc;
}

.checkmark-container input:checked ~ .checkmark {
  background-color: var(--primaryColor) !important;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkmark-container input:checked ~ .checkmark:after {
  display: block;
}

.checkmark-container .checkmark:after {
  left: 7px;
  top: 3.5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.float-right {
  float: right !important;
}

.icon-btn {
  background: transparent;
  border: 0;
  padding: 0;
  height: 18px;
  width: 18px;
  display: block !important;
}

.icon-btn svg {
  margin-top: 3px !important;
}

.folderPlaceholder {
  display: none;
}

.focus-no-outline:focus-visible {
  outline: 0;
}

.no-min-height div {
  min-height: 0 !important;
}

.folderContext {
  width: 15% !important;
}

.folder-input {
  padding: 1px 3px !important;
  font-size: 12px !important;
}

.folderContext .react-contextmenu-wrapper {
  width: 100% !important;
}

.sidebar-submenu {
  color: #fff !important;
  transition: 0.3s ease-in-out;
}

.sidebar-submenu i {
  font-size: 6px;
  margin-top: 2px;
}

.sidebar-submenu li:hover {
  padding-left: 3px;
}

.mt-10 {
  margin-top: 10px !important;
}

.actions-cards {
  border: 0;
  border-radius: 5px;
  padding: 8px;
  background: white;
  margin-bottom: 8px;
  min-height: 40px;
}
.fields-container {
  padding: 8px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.actions-cards-buttons {
  display: flex;
  border: 1px solid #d8d8d8;
}

.action-cards-button {
  background-color: white;
  border: 1px solid var(--primaryColor);
  border-radius: 5px;
  display: grid;
  padding: 10px;
  place-items: center;
}

.action-cards-button:hover {
  cursor: pointer;
  background-color: #e2e2e2;
}

.action-cards-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  flex-wrap: nowrap;
  width: 100%;
}
.actions-cards {
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: whitesmoke;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  min-height: 56px;
}

.preview-panel {
  height: fit-content !important;
}

.blur-2 {
  filter: blur(2px);
  overflow: hidden;
}

.MuiTreeItem-iconContainer {
  width: 24px !important;
  height: 24px !important;
  position: relative;
  z-index: 9999;
}

.transition-in-out {
  transition: 1s ease-in-out;
}

.component-edit-container div:has(button) {
  display: flex !important;
}

.formbuilder {
  align-items: start !important;
}

.relative {
  position: "relative";
}

.absolute {
  position: "absolute";
}

.main-loader-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background: #fff;
  height: 100vh;
  width: 100vw;
  display: grid;
  place-items: center;
}

.form-actions-card {
  background-color: #fbfbfb;
  border: 1px solid #f0f0f0;
  padding: 17px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.theme-select__menu {
  height: 100%;
  position: relative !important;
}

.gradient-round:last-child::after {
  display: none !important;
}

.dragHandle {
  /* width: 100%; */
  background: #fff;
  cursor: move;
  transform: translateY(20px);
  position: relative;
  display: block;
  z-index: 1;
  height: 40px;
}

.custom-select__menu {
  z-index: 999999 !important;
}

.mid-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
}

.no-padding-caret.btn:hover {
  background-color: #f5f5f5 !important;
  border: 1px solid #d8d8d8 !important;
}

.formio-component .form-group,
.preview-panel {
  width: 100%;
}

.component-edit-container div:has(button) {
  flex-wrap: wrap;
}

.flex-1 {
  flex: 1;
}

.formio-component-logic label,
.formio-component-logic ul,
.formio-component-logic button {
  width: 100%;
}

.full-modal {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
}

.modal-backdrop.show {
  opacity: 0.1;
}

.assets .modal-content {
  padding: 15px 35px 35px 35px;
}

.square-button {
  display: inline-block;
  line-height: 1.5;
  border-radius: 0.125rem;
  -webkit-box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  border: 0;
  padding: 0.2em 0.6em 0.3em;
}

.text-updater-node {
  height: 60px;
  width: 165px;
  padding: 5px;
  border-radius: 5px;
  background: white;
  padding: 10px 20px;
}

.text-updater-node label {
  display: block;
  color: #777;
  font-size: 12px;
}

.pl-15 {
  padding-left: 15px !important;
}

@media (min-width: 1024px) {
  .large-px-30 {
    padding-left: 30px;
    padding-right: 30px;
  }

  .heading-padding {
    padding-left: 30px;
    padding-right: 30px;
  }

  .lg-bl-1 {
    border-left: 1px solid #ebebeb;
  }
}

@media (max-width: 1440px) {
  .table-btn {
    padding: 0;
  }

  .history-table-content i,
  .history-table-content svg {
    height: 20px;
    width: 20px;
  }

  .auditlog button {
    padding: 6px !important;
  }

  .history-table,
  .logsTable {
    font-size: 12px;
  }

  .minHeightResp {
    min-height: 160vh;
  }
  .smallDateFromTo {
    flex-flow: column;
    margin-top: 20px;
    align-items: center;
    font-size: 15px;
  }
  .smallDateFromTo input {
    padding: 8.5px 14px !important;
  }
}

@media (max-width: 1024px) {
  .mid-wrap {
    flex-wrap: wrap;
  }

  .mid-w-33 {
    width: 33.33333%;
  }

  .mid-justify-space-between {
    justify-content: space-between;
  }

  .mid-mt-30 {
    margin-top: 30px;
  }

  .mid-justify-center {
    justify-content: center;
  }

  .col-md-12 {
    width: 100%;
    padding: 0 !important;
    margin-bottom: 20px;
  }

  .mid-column-reverse {
    flex-direction: column-reverse;
  }

  .mid-full-width {
    width: 100%;
  }

  .mid-no-wrap {
    flex-wrap: nowrap;
  }

  .mid-full-width {
    width: 100%;
  }

  .px-5 {
    padding-right: 1px !important;
    padding-left: 10px !important;
  }

  .mid-gap-15 {
    gap: 15px;
  }

  .mid-justify-center {
    justify-content: center;
  }
  .Columns {
    width: 100%;
    display: block !important;
  }
  .childColumns {
    width: 100% !important;
    margin: 0 !important;
  }
  .flex-column1024 {
    flex-flow: column;
  }
}

@media (max-width: 768px) {
  .col-sm-12 {
    width: 100%;
    padding: 0 !important;
    margin-bottom: 20px;
  }

  .view-contact-heading-bar-items {
    width: 100%;
  }

  .view-contact-heading-bar-items2 div {
    padding: 10px 30px;
    display: grid;
    place-items: center;
    width: 50%;
    border-left: 0 !important;
  }

  .mid-wrap {
    flex-wrap: nowrap;
  }

  .small-wrap {
    flex-wrap: wrap;
  }

  .customizer-links {
    transform: translateX(0px);
  }

  .page-main-header .main-header-right .nav-right > ul {
    justify-content: center;
  }

  .col-sm-6 {
    max-width: 50%;
  }

  .mobile-search {
    display: none !important;
  }

  .main-section {
    padding-right: 15px;
    padding-left: 15px;
  }

  .small-column-reverse {
    flex-direction: column-reverse;
  }

  .small-full-width {
    width: 100%;
  }
}

@media (min-width: 576px) {
  .question-margin-left {
    margin-left: -10px;
  }
}

@media (max-width: 576px) {
  .question-margin-left {
    margin-left: 5px;
  }

  .main-table-folder {
    display: flex !important;
    flex-direction: column !important;
  }

  .folderContext {
    width: 100% !important;
  }

  .custom-pagination {
    padding: 12px 15px !important;
    border: 1px solid #efefef !important;
  }

  .gap-40 {
    gap: 5px !important;
  }

  .gap-10 {
    gap: 2px !important;
  }

  .justify-between {
    justify-content: space-between;
    margin: 5px;
  }

  .page-wrapper .page-body-wrapper .sidebar {
    height: calc(100vh - 60px) !important;
  }
}

label.required:after {
  content: " *";
  color: #f86b4f;
}

span.required:after {
  content: " *";
  color: #f86b4f;
}

label {
  font-weight: 400;
  font-size: 13px;
  line-height: 1;
  vertical-align: top;
  margin-bottom: 0.5rem;
  color: black;
}

.form-label {
  font-weight: 400;
  font-size: 13px;
  line-height: 1;
  vertical-align: top;
  margin-bottom: 0.5rem;
  color: black;
}

.wrapper {
  border: 1px solid #b6b6b6;
}

.editor {
  background-color: white;
}

.rfipbtn.rfipbtn--bluegrey.rfipbtn--close,
.rfipbtn.rfipbtn--bluegrey.rfipbtn--open {
  height: 40px !important;
  min-height: 40px !important;
}

.rfipbtn--bluegrey {
  border: 1px solid #ccced1 !important;
}

.rfipbtn__current {
  flex: 0 0 65px !important;
}

.rfipbtn__button {
  display: none !important;
}

.rfipbtn {
  height: 100% !important;
  width: 100% !important;
}

.rfip {
  margin: 0 0 0.5em 0 !important;
  width: 100% !important;
  height: 40px;
}

.rfipbtn__icon--empty {
  margin-left: 0 !important;
  padding: 0 14px !important;
}

.rfipdropdown {
  width: 100% !important;
}

.wrapper.rdw-editor-wrapper {
  width: 100%;
  min-height: 200px;
}

.hover-white:hover {
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
}

.lineAfter::after {
  width: 100%;
  border-bottom: 1px solid black;
}

.modalForMergeInAddCompany > .modal-dialog > .modal-content {
  height: 87em;
  width: 100em;
}
.modal-content {
  /* min-width: 700px; */
}

.modalForMergeInAddCompany > .modal-dialog {
  margin-left: 30em;
}
.modal-dialog {
  margin-left: auto;
  margin-right: auto;
  max-width: 50%;
}
.modal {
  --bs-modal-margin: 1.75rem;
  --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0,0,0,.15);
  backdrop-filter: blur(1px);
}
.white-icon svg path {
  stroke: #fff;
}

.active .white-icon svg path {
  stroke: rgb(100, 181, 246) !important;
}

.border-radius-left-none {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.border-radius-right-none {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.color-input-background {
  width: 40px;
  height: 40px;
}

.line-height-normal {
  line-height: normal;
}

.wrapper-contact {
  margin: 30px auto;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

.no-contact {
  width: fit-content;
  display: flex;
  flex-direction: column;
  background-color: rgba(245, 166, 35, 0.2);
  border: 1px solid #e19920;
  color: #ba7e1b;
  padding: 15px;
  border-radius: 3px;
  align-items: center;
}

.place-holder {
  background-color: white;
  padding: 5px 0;
}

.w-40 {
  width: 40%;
}

.panel {
  border: 1px solid #ebedf0;
  border-radius: 0;
  box-shadow: 0 10px 40px 0 rgba(62, 57, 107, 0.07), 0 2px 9px 0 rgba(62, 57, 107, 0.06);
  margin-bottom: 18px;
  background-color: #fff;
}

.panel-info {
  border-color: rgba(0, 0, 0, 0.03);
}

.panel-heading {
  color: #fff;
  background-color: var(--primaryColor);
  border-color: rgba(0, 0, 0, 0.03);
  padding: 0 15px;
  border-bottom: 1px solid transparent;
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
}

.panel-title {
  padding: 10px 0;
  display: table-cell;
  width: 1%;
  vertical-align: middle;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 15px;
  color: inherit;
}

.panel-body {
  padding: 15px;
}

.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.custom-btn {
  margin-left: -1px !important;
  background-color: #e9ecef !important;
  color: #686868 !important;
  border: 1px solid #e0e0ef !important;
}

.custom-btn:hover {
  border: 1px solid #e0e0ef !important;
  background-color: #f6f6f7 !important;
}

.forgotPassword {
  display: flex;
  padding: 4px;
  margin: 4px;
  justify-content: flex-end;
}

.forgotPassword:hover {
  text-decoration: underline;
}

.jump-select {
  background-color: #fff;
  /* border: 1px solid #ced4da; */
  border-radius: 4px;
  box-sizing: border-box;
  color: #333;
  font-size: 14px;
  height: 100%;
  outline: 0;
  /* padding: 4px 10px 0; */
  width: 100%;
  font-family: "Titillium Web";
}

.accResponsivness {
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .accResponsivness {
    display: flex;
    flex-direction: row;
  }
}

.borderSection {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.select-container {
  width: 100%;
  position: relative;
}

.error-message {
  color: red;
  font-size: 12px;
  margin: 5px;
}

.text-right {
  justify-content: flex-end;
}

.help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 5px !important;
  color: #a94442;
}

.alert {
  padding: 5px 15px;
  margin-bottom: 18px;
  border: 1px solid transparent;
  border-radius: 3px;
}

.alert-danger {
  background-color: rgba(255, 94, 109, 0.2);
  border-color: #eb5664;
  color: #c24753;
}

.custom-add-group-class {
  display: none !important;
}

.span-center {
  width: 5em;
  justify-content: center;
  display: flex;
}

.form-field-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-field-label {
  display: flex;
  justify-content: flex-start;
}

.form-field-input {
  display: flex;
  justify-content: flex-start;
}

.select-container {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 15px;
}

.label-history {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 92%;
  font-weight: 600;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}

.header-center-align {
  display: flex;
  justify-content: center;
  align-items: center;
}

.d-flex {
  display: flex !important;
}

.ml-2 {
  margin-left: 0.5rem;
}

.pa-md {
  padding: 15px !important;
}

.pa-md-h3 {
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 20px;
}

.pa-md-table {
  width: 100%;
  max-width: 100%;
  font-size: 12px;
  color: rgb(51, 55, 64);
  border: none;
  border-spacing: 0;
}

.table-th {
  vertical-align: middle;
  border-bottom: 1px solid #ebedf0;
  border-top: 0;
  background-color: #f3f3f3;
  padding: 8px 15px;
  line-height: 1.3856;
  font-weight: 600;
  text-align: left;
}

.table-td {
  border: none !important;
}

.list-campaign-leadsource {
  width: 160px;
  height: 60px;
  position: absolute;
  padding: 10px 15px;
  z-index: 1010;
  background: #fff;
  border-radius: 0px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  border: solid 1px #3a3f51;
  background: #3a3f51;
  color: #fff;
  bottom: -1px;
}

.nav-builder {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.my-class-name {
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  text-transform: capitalize;
}

.my-class-name:hover {
  background-color: var(--primaryColor);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-transform: capitalize;
}

.custom-nav-trash {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ruleGroup {
  border: none !important;
}

.builderButtonsHover {
  display: none;
}

.SourceSelect:hover .builderButtonsHover,
.eventSelect:hover .builderButtonsHover {
  display: block;
}

.react-flow__handle.connectionindicator {
  cursor: pointer !important;
}

.theme-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.theme-card-row {
  width: calc(33.33% - 20px); /* Adjust the width as needed, considering margins */
  margin-bottom: 20px; /* Add spacing between rows */
  box-sizing: border-box;
}

.theme-card {
  flex: 1 0 auto;
  width: 100%;
  padding: 15px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.theme-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
}

.tab-navigation {
  display: flex;
  padding: 10px 0;
}

.tab-item {
  cursor: pointer;
  padding: 10px 20px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-radius: 5px 5px 0 0;
}

.active-tab {
  background-color: var(--primaryColor) !important;
  border-bottom-color: #ffffff !important;
  color: white !important;
  cursor: pointer !important;
}
.toggle-field-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.toggle-label {
  font-size: 13px;
  margin-right: 10px;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.toggle-switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.tab-content-generator {
  display: flex;
  flex-direction: column;
}

.cursor-grab {
  cursor: grab;
}

.ag-sort-indicator-container {
  display: none !important;
}

.details-filters-container {
  overflow: hidden;
  transition: max-height 0.7s ease;
}

.details-container,
.filters-container {
  max-height: 500px;
  overflow: visible;
}

.details-hidden,
.filters-hidden {
  max-height: 0;
}

.arrow-icon {
  transition: transform 0.7s ease;
}

.arrow-rotate {
  transform: rotate(180deg);
}

.accordion-container {
  overflow-y: visible;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

.details-filters-container .details-hidden {
  overflow: hidden;
}

.whiteButton {
  background-color: white;
  color: black;
  font-size: 12px;
  letter-spacing: 0.7px;
  border: 1px solid black;
  padding: 10px 20px;
  border-radius: 5px;
}

.whiteButton:hover {
  background-color: var(--primaryColor);
  color: white;
}

.group-badge-styles {
  background-color: #ebecf0;
  border-radius: 2em;
  color: #172b4d;
  display: inline-block;
  font-size: 12px;
  font-weight: normal;
  line-height: 1;
  min-width: 1px;
  padding: 0.16666666666667em 0.5em;
  text-align: center;
}

.group-styles {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ruleGroup-combinators {
  padding: 5px 10px;
}

.ruleGroup-addGroup,
.ruleGroup-addRule {
  padding: 3px 8px;
  border-radius: 5px;
  font-weight: 600;
  font-size: 13px;
}

.rule-value-input {
  padding: 5px 10px;
}

.rule-remove {
  border: none;
  font-size: 15px;
  padding: 3px 9px;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: auto;
}

.input-group-text2 {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  color: #212529;
  letter-spacing: 0.6px;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: #f8f9fa;
  background-color: var(--bs-tertiary-bg);
  border: 1px solid #dee2e6;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: 0 0.375rem 0.375rem 0;
}

.input-group-text3 {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  color: #212529;
  letter-spacing: 0.6px;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: #f8f9fa;
  background-color: var(--bs-tertiary-bg);
  border: 1px solid #dee2e6;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: 0.375rem 0 0 0.375rem !important;
}

.input-group-text2:hover {
  background-color: #e9ecef;
}

.pl-64px {
  padding-left: 10px !important;
}

.pr-64px {
  padding-right: 280px !important;
}

.pr-270px {
  padding-right: 230px !important;
}

.pl-23px {
  padding-left: 15px !important;
}

.main-table-folder {
  display: flex !important;
  justify-content: flex-start !important;
}

.noDrag {
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ag-center-cols-viewport,
.ag-center-cols-clipper,
.ag-root,
.ag-root-wrapper,
div.ag-root.ag-unselectable.ag-layout-normal {
  overflow: visible !important;
}

.page-header {
  overflow: hidden;
}

.ag-center-cols-container {
  width: unset !important;
}

.segment-details-table {
  transition: max-height 0.4s ease-in-out;
  overflow: hidden;
  max-height: 0;
}

.react-datepicker__close-icon:hover::after {
  color: #1c1c1c;
  transition: color 0.2s ease-in-out;
}

.react-datepicker__close-icon::after {
  cursor: pointer;
  background-color: transparent;
  color: #ccc;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  padding: 0px;
  padding-right: 5px;
  font-size: 24px;
  line-height: 1;
  text-align: unset;
  display: inline;
  vertical-align: unset;
  content: "×";
  transition: color 0.2s ease-in-out;
}

.handleToggle {
  z-index: -1 !important;
}

.handleToggle:hover {
  z-index: 999999 !important;
}

.handleToggle:hover::after {
  content: "+";
  color: white;
}

.intervalModeSpan {
  padding: 4px;
  font-size: 12px;
  font-weight: 600;
  width: -webkit-fill-available;
  max-width: fit-content;
}

.interval_settings .input-container {
  height: auto;
}

/* STYLE FOR DOT IN INPUTS (REQUIRED) REUSABLE */
.MuiInputLabel-asterisk.MuiInputLabel-asterisk {
  color: rgba(255, 0, 0, 0.7);
  font-size: 18px;
  vertical-align: text-top;
}

.disabled-cursor,
.disabled-cursor * {
  cursor: not-allowed !important;
}

.text-ellipsis {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}

.react-flow__edge-textbg {
  fill: #f6f6f6 !important;
  stroke: #b0b0b0;
  stroke-width: 1;
}
.react-flow__edge-text {
  font-size: 10px !important;
  font-weight: 600;
  fill: #333 !important;
}

.react-flow__edge-text {
  font-size: 8px !important;
}

.w-90 {
  width: 90%;
}

.boolean-button {
  padding: 5px 15px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 5px;
}

.boolean-button.active {
  color: white;
  font-weight: bold;
}

.boolean-button.active.yes {
  background-color: green;
}

.boolean-button.active.no {
  background-color: red;
}

.boolean-button.active.x {
  background-color: #000;
}

.border-4px {
  border-radius: 4px !important;
}

.w-90 {
  width: 90%;
}

.custom-textfield .MuiInputBase-input {
  font-size: 16px;
}

.font-13 {
  font-size: 13px;
}

.font-15 {
  font-size: 15px !important;
}

.ck-editor__editable_inline p {
  font-size: 15px !important;
}

.details-closed {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.6s linear, opacity 0.6s linear;
}

.queryBuilder-dragHandle {
  align-self: center;
  margin-top: 5px;
}

.rule-remove {
  align-self: flex-start;
  margin-top: 8px;
}

.h-40 {
  height: 40px !important;
}
.h-32 {
  height: 32px !important;
}
.inherit {
  background-color: inherit !important ;
}
.white {
  background-color: white !important ;
}

.parent-100 div {
  width: 100%;
}

.group-btn-border-left {
  border-left: 1px solid rgba(0, 0, 0, 0.12) !important;
}

.inner-inputs {
  border: 1px solid #135f9517;
  display: flex;
  flex-direction: column;
  gap: 15px;
  background-color: #ebeff224;
  padding: 20px 25px;
  border-radius: 5px;
}

.gap-0px {
  gap: 0 !important;
}

.app-container {
  display: flex;
}

.content {
  flex-grow: 1;
  overflow-y: auto;
  margin: 0 20px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #6c757d !important;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #6c757d !important;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.header {
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  /*border-bottom: 1px solid gainsboro;*/
}

.layout {
  display: flex;
  width: 100%;
  height: 100vh;
}

.datepicker-placeholder {
  color: #44546f;
}

.group-fields-style {
  border: 1px solid #135f9517;
  /* background-color: #ebeff224; */
  border-radius: 5px;
  gap: 15px;
  padding: 20px 25px 25px 25px;
}

.hover-effect {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 5px;
}

.hover-effect:hover {
  /* transform: scale(1.003); */
  /* box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1); */
  /* border-color: #5a5a5a; */
  text-decoration: none;
  background-color: rgba(19, 95, 149, 0.04);
  border: 1px solid var(--primaryColor);
}

.wrapper-container {
  background-color: transparent;
  gap: 20px;
}
.no-padding {
  padding: 0 !important;
}
.wrapper-child {
  background-color: white;
  min-height: calc(100vh - 83px);
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  align-self: stretch;
  margin-bottom: 10px;
}

.wrapper-childOne {
  width: calc(75% - 20px);
  padding: 20px;
}
.wrapper-childTwo {
  width: 25%;
  padding: 20px;
}
.wrapper-childTwoNoMargin {
  width: 25%;
}
.wrapper-childOneNoMargin {
  width: calc(75% - 20px);
}
.wrapper-childOne80 {
  width: calc(80% - 20px);
}
.wrapper-childTwo20 {
  background: transparent;
  padding: 0;
  width: 20%;
  min-height: auto;
  height: fit-content;
}

.css-v84d5j-MuiSvgIcon-root {
  fill: var(--primaryColor) !important;
}

.blueBorder {
  border: 1px solid rgba(19, 95, 149, 0.5);
}

.cardShadow {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.wrapper-w-100 {
  width: 100%;
  padding: 20px;
}

.add_role_form {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 3em;
  row-gap: 2em;
}

.checkbox-wrapper-46 input[type="checkbox"] {
  display: none;
  visibility: hidden;
}

.checkbox-wrapper-46 .cbx {
  margin: auto;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}
.checkbox-wrapper-46 .cbx span {
  display: inline-block;
  vertical-align: middle;
  transform: translate3d(0, 0, 0);
}
.checkbox-wrapper-46 .cbx span:first-child {
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 3px;
  transform: scale(1);
  vertical-align: middle;
  border: 1px solid #9098a9;
  transition: all 0.2s ease;
}
.checkbox-wrapper-46 .cbx span:first-child svg {
  position: absolute;
  top: 3px;
  left: 2px;
  fill: none;
  stroke: #ffffff;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: 16px;
  transition: all 0.3s ease;
  transition-delay: 0.1s;
  transform: translate3d(0, 0, 0);
}
.checkbox-wrapper-46 .cbx span:first-child:before {
  content: "";
  width: 100%;
  height: 100%;
  background: var(--primaryColor);
  display: block;
  transform: scale(0);
  opacity: 1;
  border-radius: 50%;
}
.checkbox-wrapper-46 .cbx span:last-child {
  padding-left: 8px;
}
.checkbox-wrapper-46 .cbx:hover span:first-child {
  border-color: var(--primaryColor);
}

.checkbox-wrapper-46 .inp-cbx:checked + .cbx span:first-child {
  background: var(--primaryColor);
  border-color: var(--primaryColor);
  animation: wave-46 0.4s ease;
}
.checkbox-wrapper-46 .inp-cbx:checked + .cbx span:first-child svg {
  stroke-dashoffset: 0;
}
.checkbox-wrapper-46 .inp-cbx:checked + .cbx span:first-child:before {
  transform: scale(3.5);
  opacity: 0;
  transition: all 0.6s ease;
}

@keyframes wave-46 {
  50% {
    transform: scale(0.9);
  }
}

.checkbox-wrapper-22 {
  height: 18px;
}
.MuiTable-root {
  --header-mrt_row_action-size: unset !important;
  --col-mrt_row_action-size: unset !important;
  --header-name-size: unset !important;
  --col-name-size: unset !important;
  --header-category-size: unset !important;
  --col-category-size: unset !important;
  --header-points-size: unset !important;
  --col-points-size: unset !important;
  --header-delta-size: unset !important;
  --col-delta-size: unset !important;
  --header-action-size: unset !important;
  --col-action-size: unset !important;
  --header-slotName-size: unset !important;
  --col-slotName-size: unset !important;
  --header-type-size: unset !important;
  --col-type-size: unset !important;
  --header-style-size: unset !important;
  --col-style-size: unset !important;
  --header-title-size: unset !important;
  --col-title-size: unset !important;
  --header-hits-size: unset !important;
  --col-hits-size: unset !important;
  --header-downloadCount-size: unset !important;
  --col-downloadCount-size: unset !important;
  --header-contacts-size: unset !important;
  --col-contacts-size: unset !important;
  --header-Score-size: unset !important;
  --col-Score-size: unset !important;
  --header-companywebsite-size: unset !important;
  --col-companywebsite-size: unset !important;
  --header-email-size: unset !important;
  --col-email-size: unset !important;
  --header-location-size: unset !important;
  --col-location-size: unset !important;
  --header-stage-size: unset !important;
  --col-stage-size: unset !important;
  --header-last_active-size: unset !important;
  --col-last_active-size: unset !important;
  --header-label-size: unset !important;
  --col-label-size: unset !important;
  --header-alias-size: unset !important;
  --col-alias-size: unset !important;
  --header-group-size: unset !important;
  --col-group-size: unset !important;
  --header-settings-size: unset !important;
  --col-settings-size: unset !important;
  --header-totalLeads-size: unset !important;
  --col-totalLeads-size: unset !important;
  --header-webhookUrl-size: unset !important;
  --col-webhookUrl-size: unset !important;
  --header-role_name-size: unset !important;
  --col-role_name-size: unset !important;
  --header-onlineStatus-size: unset !important;
  --col-onlineStatus-size: unset !important;
  --header-lastActive-size: unset !important;
  --col-lastActive-size: unset !important;
  --header-username-size: unset !important;
  --col-username-size: unset !important;
  --header-source-size: unset !important;
  --col-source-size: unset !important;
  --header-companycontacts-size: unset !important;
  --col-companycontacts-size: unset !important;
  --col-companyemail-size: unset !important;
  --header-companyemail-size: unset !important;
  --col-companyname-size: unset !important;
  --header-companyname-size: unset !important;
  --header-Unique_Click-size: unset !important;
  --col-Unique_Click-size: unset !important;
  --header-URL-size: unset !important;
  --col-URL-size: unset !important;
  --header-pendingCount-size: unset !important;
  --col-pendingCount-size: unset !important;
  --header-sentCount-size: unset !important;
  --col-sentCount-size: unset !important;
  --header-read-size: unset !important;
  --col-read-size: unset !important;
  --header-readPercentage-size: unset !important;
  --col-readPercentage-size: unset !important;
  --header-status-size: unset !important;
  --col-status-size: unset !important;
}

.checkbox-wrapper-22 .switch {
  display: inline-block;
  height: 17.969px;
  position: relative;
  width: 35.938px;
  margin-bottom: 0 !important;
}

.checkbox-wrapper-22 .slider {
  background-color: #ccc;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
  border-radius: 17.969px;
}

.checkbox-wrapper-22 .slider:before {
  background-color: #fff;
  bottom: 2.695px;
  content: "";
  height: 12.578px;
  left: 2.695px;
  position: absolute;
  transition: 0.4s;
  width: 12.578px;
  border-radius: 50%;
}

.checkbox-wrapper-22 input:checked + .slider {
  /* background-color: #66bb6a; */
  background-color: var(--lightPrimaryColor);
}

.checkbox-wrapper-22 input:checked + .slider:before {
  transform: translateX(17.969px);
}

.result-component {
  height: 30px;
  border: 1px solid var(--primaryColor) !important;
  background-color: white !important;
  display: flex !important;
  color: var(--primaryColor) !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 5px;
  border-radius: 5px !important;
  max-width: 200px;
  cursor: pointer !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  letter-spacing: 0.5px !important;
}
.result-component:hover {
  background-color: #135f950c !important;
}
.delete-component {
  height: 30px;
  border: 1px solid #d11a2a !important;
  background-color: white !important;
  display: flex !important;
  color: #d11a2a !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 5px !important;
  border-radius: 5px !important;
  max-width: 150px;
  cursor: pointer !important;
  font-size: 13px;
  font-weight: 500 !important;
  letter-spacing: 0.5px !important;
}
.delete-component:hover {
  background-color: #d11a2a0c !important;
}
.confirm-component {
  height: 30px;
  border: 1px solid #039477 !important;
  background-color: white !important;
  display: flex !important;
  color: #039477 !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 5px !important;
  border-radius: 5px !important;
  max-width: 150px;
  cursor: pointer !important;
  font-size: 13px;
  font-weight: 500 !important;
  letter-spacing: 0.5px !important;
}
.confirm-component:hover {
  background-color: #0394770c !important;
}
.rebuild-progress {
  font-size: 13px !important;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 !important;
}
span[class^="dot-"] {
  opacity: 0;
}
.dot-one {
  animation: dot-one 2s infinite linear;
}
.dot-two {
  animation: dot-two 2s infinite linear;
}
.dot-three {
  animation: dot-three 2s infinite linear;
}
@keyframes dot-one {
  0% {
    opacity: 0;
  }
  15% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes dot-two {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes dot-three {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.rfipbtn,
.borderRadius {
  border-radius: 4px !important;
}

.rfipbtn:hover {
  transform: scale(1.003);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  -webkit-transition: -webkit-transform 0.4s ease, box-shadow 0.4s ease;
  transition: transform 0.4s ease, box-shadow 0.4s ease;
}

.rfipbtn:focus {
  border-color: var(--primaryColor) !important;
  border-width: 2px !important;
}

.form-control:focus {
  box-shadow: 0 0 0 0.07rem var(--primaryColor);
}

.noBorderRadius {
  border-radius: 0;
}

.ruleGroup-header {
  display: flex;
  align-items: center;
  gap: 15px;
}

.ruleGroup-addRule,
.ruleGroup-combinators,
.ruleGroup-addGroup {
  background-color: white !important;
  color: var(--primaryColor);
  border: 1px solid var(--primaryColor);
}
/* .ruleGroup {
  background-color: #f5f5f5 !important;
  padding: 10px;
  border-radius: 6px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1) !;
} */

.rule-remove,
.ruleGroup-remove {
  background-color: rgba(255, 94, 109, 0.9) !important;
  color: white;
  border-radius: 4px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.rule-remove:hover {
  transform: scale(1.02);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
}
.queryBuilder-dragHandle {
  cursor: move !important;
}

.ruleGroup-remove {
  background-color: rgba(255, 94, 109, 0.9) !important;
  color: white;
  border-radius: 4px;
  border: none;
  padding: 5px 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.ruleGroup:not(:first-child) {
  border-top: 1px solid black;
  padding-top: 10px;
}

.details-container-accordion {
  font-family: "Arial", sans-serif;
  color: #333;
  background-color: #fff;
  border-radius: 8px;
  max-width: 400px;
}

.details-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem;
  border-bottom: 1px solid #eaeaea;
  transition: all 0.3s ease-in-out;
  font-size: 12px;
  font-family: Titillium Web, sans-serif;
}

.details-item:hover {
  background-color: #f9f9f9;
}

.details-label {
  font-weight: 600;
  color: #333;
}

.details-value {
  color: #666;
}

.custom-datepicker {
  height: 32px;
}
.MuiInputBase-root {
  height: 32px;
}

.MuiOutlinedInput-input {
  height: 32px;
}

.chart-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
}

.widget-shadow {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  background-color: white;
  border-radius: 8px;
  height: fit-content;
}
#myUniqueChartId .ec-toolbox {
  opacity: 0;
  transition: opacity 0.3s;
}

#myUniqueChartId:hover .ec-toolbox {
  opacity: 1;
}

.queryBuilder {
  background-color: #f5f5f5 !important;
  padding: 10px;
  border-radius: 6px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.queryBuilder .ruleGroup {
  background-color: #f5f5f5 !important;
}

.queryBuilder .ruleGroup:nth-child(n + 2),
.queryBuilder .ruleGroup .ruleGroup {
  background-color: rgb(209, 209, 209, 0.3) !important;
  padding: 10px;
  border-radius: 6px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.07) !important;
}

.json-html-style {
  display: flex;
  flex-direction: column;
}

.custom-toast-width {
  width: 50%;
}

.swal-custom {
  height: 40px !important;
  width: 6.5em !important;
  font-family: Titillium Web, sans-serif !important;
  font-size: 0.8125rem !important;
  line-height: 1.75 !important;
}
.swal2-actions {
  display: flex;
  flex-direction: row-reverse !important;
}

.swal-custom:focus,
.swal2-styled.swal2-confirm:focus {
  outline-color: var(--primaryColor) !important;
  box-shadow: none !important;
}

.swal-custom:hover {
  background-image: none !important;
}

.reportViewChildOne {
  box-shadow: none;
  background-color: unset;
  width: 75%;
}

.smallDatePicker {
  border-radius: 4px !important;
  border-width: 1px !important;
}

.smallDatePicker:hover {
  transform: scale(1.003);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  -webkit-transition: -webkit-transform 0.4s ease, box-shadow 0.4s ease;
  transition: transform 0.4s ease, box-shadow 0.4s ease;
}

.smallDatePicker:focus {
  border-color: var(--primaryColor) !important;
}

.smallDateFromTo input {
  padding: 9px 6px;
  font-size: 15px;
}

.custom-cursor {
  cursor: move !important;
}

.MuiToolbar-root {
  overflow: inherit !important;
  z-index: auto !important;
  border-radius: 10px !important;
}

.react-flow__attribution {
  display: none !important;
}

.card-top {
  background-color: white;
  width: 100%;
  height: auto;
  padding: 16px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
}
.right-info {
  display: flex;
  flex-direction: column;
  height: 40px;
  /* border-right: 1px solid gainsboro; */
  align-items: center;
  justify-content: center;
  padding: 4px;
}
.active-bg {
  background: var(--primaryColor) !important;
  color: white !important;
}
.hide-on-hover {
  opacity: 0;
  transition: opacity 0.3s;
}

.hover-parent:hover .hide-on-hover {
  opacity: 1;
}
/* Add this CSS to your component or stylesheet */
.detail-table {
  display: table;
  width: 100%;
  border-collapse: collapse;
  margin-top: 8px;
}

.detail-row {
  display: table-row;
}

.detail-cell {
  display: table-cell;
  padding: 8px;
  border: 1px solid #ccc;
}

.header-row {
  font-weight: bold;
  background-color: #fafafa;
}

.detail-subcell {
  margin-top: 4px;
}

.orange {
  background: #fdb933;
}

.center {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-shadow: "0 0 10px rgb(0 0 0 / 20%)";
}

.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}

.master {
  color: black;
  float: right;
}

@media (max-width: 900px) {
  .master {
    display: none;
  }
}
.grid-container {
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 10px;
  padding: 10px;
}

.grid-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.grid-header {
  font-weight: bold;
  text-align: center;
}

a.list-group-item:hover,
a.list-group-item:focus {
  text-decoration: none;
  color: #555;
  background-color: #f5f5f5;
}

.w-60 {
  width: 60% !important;
}

.border-radius-5px {
  border-radius: 5px !important;
}

.handleJump:hover {
  cursor: pointer;
  border: 1px solid rgb(11, 148, 247);
}
.monacoEditor {
  height: 100% !important;
}
.dl-horizontal dd::before {
  content: " "; /* Add content for the before pseudo-element */
  display: table-caption;
}

.dl-horizontal dd::after {
  clear: both;
  display: block;
  content: "";
}
.dl-horizontal dt {
  width: auto;
  text-align: left;
}
dl {
  margin-top: 0;
  margin-bottom: 18px;
}
.dl-horizontal dt {
  float: left;
  width: auto;
  margin-right: 10px;
  clear: left;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.timeline-campaign-event-text-160 {
  font-size: 12px;
  margin-left: 6px;
}

.your-div-class {
  padding: 20px;
  display: flex;
  justify-content: center;
  min-height: 100vh;
}

.your-div-class > div {
  text-align: center;
}

.caymlandform-page-wrapper > div {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.caymlandform-page-wrapper > div > label {
  font-weight: 600;
}
.caymlandform-page-wrapper > div > input {
  width: 100%;
  padding: 0.5em 0.5em;
  border: 1px solid #ccc;
  background: #fff;
  box-shadow: 0px 0px 0px #fff inset;
  border-radius: 4px;
  box-sizing: border-box;
}

.caymlandform-button {
  height: 30px;
  border: 1px solid var(--primaryColor) !important;
  background-color: white !important;
  display: flex !important;
  color: var(--primaryColor) !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 5px;
  border-radius: 5px !important;
  cursor: pointer !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  letter-spacing: 0.5px !important;
  width: fit-content;
  margin: 0 auto;
}

.caymlandform-button:hover {
  background-color: #135f950a !important;
}

.h-92 {
  height: 92vh;
}

.overflow-auto {
  overflow: auto;
}

.d-none {
  display: none;
}
.timezone-select-container {
  white-space: nowrap;
}
.selectIcon:hover {
  transform: scale(1.2);
}
.EmailTestingPreview {
  width: 100%;
}

.line {
  width: 99%;
  height: 0;
  border: 1px solid gainsboro;
  /* margin: 3px; */
  display: inline-block;
}

/* .login-inner {
  background-image: url("../assets/images/banner.png");
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
} */

.login-inner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
  z-index: 0;
}

.login-inner > * {
  position: relative;
  z-index: 1;
}

.logo-image img {
  width: 25%;
  padding-bottom: 20px;
  filter: invert(24%) sepia(100%) saturate(935%) hue-rotate(178deg) brightness(95%) contrast(89%);
}

.element {
  background-color: var(--dominant-color, lightgray);
}

.react-tel-input .form-control {
  height: 39px !important;
  width: 100% !important;
  font-size: 13px !important;
}

/* .message-content-preview-device {
  background-image: url("../assets/images/viberPhone/viberPhone.svg");
  padding: 35px 23px 100px;
} */

.message-content-preview-wrapper {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

.device {
  width: 322px;
  height: 640px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
}

.message-content-preview-children-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex-grow: 1;
}

.message-content-preview-sender-children-wrapper {
  min-height: 64px;
  width: 100%;
  padding: 30px 70px 0 40px;
}

.preview-sender {
  font-size: 14px;
  color: #545452;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.message-content-preview-body-wrapper {
  width: auto;
  margin: 10px;
  background-color: #fff;
}

.message-content-preview-body {
  max-height: 410px;
  overflow-y: auto;
}

.message-content-preview-body-content {
  display: flex;
  gap: 8px;
  align-items: flex-start;
}

.message-content-preview-body-content-icon {
  display: flex;
  flex-shrink: 0;
}
/* 
.message-content-preview-body-content-icon-image {
  background-image: url("https://local.caymland.app/plugins/CaymlandAMSMBundle/Assets/img/amsm.png");
  height: 32px;
  width: 32px;
  background-size: cover;
} */

.message-content-preview-body-content-text {
  max-width: 210px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 12px;
  border-radius: 0 10px 10px;
  background-color: #e6e6e8;
  object-fit: cover;
  word-break: break-word;
  white-space: break-spaces;
  overflow: hidden;
}

.message-content-preview-body-content-text-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 8px;
}

.message-content-preview-body-content-text-content-children {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 8px;
}

.message-content-preview-body-message {
  line-height: 14px;
  font-size: 12px;
}

.message-content-preview-body-image {
  width: 100%;
  /* margin-top: -40px; */
  border-radius: 8px;
  overflow: hidden;
}

.message-content-preview-body-image-radio {
  aspect-ratio: 1;
  object-fit: cover;
}

.message-content-preview-body-image-width {
  height: 100%;
  width: 100%;
}

.message-content-preview-body-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 186px;
  min-height: 28px;
  background-color: #6458bf;
  border-radius: 50px;
}

.message-content-preview-body-button-text {
  padding: 4px 16px;
  font-size: 12px;
  text-align: center;
  color: #fff;
}

.message-content-preview-body-button-text a {
  color: #fff;
}

.hide {
  display: none;
}

.stats-box {
  border-radius: 5px;
  padding: 2px 10px;
  font-size: 14px;
  margin: 2px 0;
  margin-left: 2px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  width: fit-content;
}

.stats-pending {
  border: 1px solid #6c757d;
  color: #6c757d;
}

.stats-sent {
  border: 1px solid var(--primaryColor);
  color: var(--primaryColor);
}

.stats-read {
  border: 1px solid #28a745;
  color: #28a745;
}

.stats-percentage {
  border: 1px solid #155724;
  color: #155724;
}

.stats-pending:hover {
  background-color: #6c757d;
  color: white;
}

.stats-sent:hover {
  background-color: var(--primaryColor);
  color: white;
}

.stats-read:hover {
  background-color: #28a745;
  color: white;
}

.stats-percentage:hover {
  background-color: #155724;
  color: white;
}
.MuiIconButton-root .MuiIconButton-sizeMedium {
  display: none !important;
}
.css-8g82x-MuiPaper-root {
  width: 100%;
}

@media print {
  .page-break {
    page-break-before: always; /* Forces the element to start on a new page */
  }
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  max-width: 1000px;
  margin: 0 auto;
}

.custom-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  height: 400px; /* Consistent height */
  position: relative;
}

.custom-card-header {
  padding: 8px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  max-height: 30px;
}

.custom-card-body {
  flex-grow: 1;
  width: 100%;
  height: 100%; /* Ensures full height coverage */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.theme-frame {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.custom-card-footer {
  padding: 10px;
  display: flex;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.custom-card:hover .custom-card-footer,
.custom-card.selected .custom-card-footer {
  opacity: 1; /* Always visible on selected or hover */
}
.hover-preview {
  position: fixed;
  left: calc(100% + 20px);
  top: 0;
  z-index: 10;
  width: 400px;
  height: auto;
  border: 2px solid #ccc;
  background-color: #fff;
  padding: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}
.hover-preview img {
  width: 100%;
  height: auto;
}
/* Wrapper for positioning */
.search-bar-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
  transition: transform 0.3s ease;
}
.search-bar-wrapper:hover {
  transform: scale(1.05);
}

.search-bar-wrapper.active {
  transform: scale(1.05);
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); */
  /* padding: 2px; */
}

.search-bar-wrapper.active .search-bar {
  border-bottom-color: #0073e6; /* Different color when active */
}

.search-bar-wrapper:hover .search-bar {
  border-bottom-color: #005bb5; /* Different color on hover */
}

/* Search bar with button and input */
.search-bar {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--primaryColor);
  border-radius: 0px;
  transition: all 0.4s ease;
  padding: 0px;
  width: 100%; /* Expanded width */
  overflow: hidden;
  height: 40px;
}

/* Button styles */
.search-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #333;
  padding: 5px;
}

/* Icon */
.search-button svg {
  font-size: 18px;
  color: #666;
}

/* Input styles */
.search-input {
  border: none;
  outline: none;
  font-size: 16px;
  padding: 5px;
  color: gray;
  margin-left: 5px;
  width: 150px; /* Adjust based on your preferred width */
  opacity: 1; /* Fade in */
  transition: opacity 0.4s ease, width 0.4s ease;
}
/* Search results container */
.search-results {
  position: absolute;
  bottom: 100%; /* Position results above the search bar */
  left: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom: none; /* Remove bottom border */
  max-height: 300px;
  overflow-y: auto;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  overflow-x: hidden;
}

.search-category {
  border-bottom: 1px solid #eee;
}

/* Flex container for category header */
.search-category-header {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: bold;
  align-items: center;
  padding: 8px;
  width: 100%;
  color: white;
  margin-bottom: 4px;
  background-color: var(--primaryColor);
}

/* Style for the count */
.category-count {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: normal;
  color: var(--primaryColor);
  background-color: white;
  width: 24px; /* Adjust the size as needed */
  height: 24px; /* Same as width to make it a circle */
  border-radius: 50%; /* Makes the element a circle */
  padding: 0; /* Remove padding since we use width/height */
  font-size: 0.8em; /* Adjust font size as needed */
}
/* Result items */
.search-result-item {
  padding: 8px;
  border-bottom: 1px solid #f1f1f1;
  font-size: 14px;
  color: #333;
  cursor: pointer;
  transition: background-color 0.2s;
}

.search-result-item:hover {
  background-color: #f9f9f9;
}

/* Last item without border */
.search-category .search-result-item:last-child {
  border-bottom: none;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Takes up full height of the container */
  min-height: 50px; /* Minimum height for smaller loading containers */
}

.contact-points {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: normal;
  color: white;
  background-color: var(--primaryColor);
  width: 24px; /* Adjust the size as needed */
  height: 24px; /* Same as width to make it a circle */
  border-radius: 50%; /* Makes the element a circle */
  padding: 0; /* Remove padding since we use width/height */
  font-size: 0.8em; /* Adjust font size as needed */
}

.img-wrapper {
  display: flex;
  width: 30px;
  height: 30px;
}
.img-rounded {
  border-radius: 50%;
}

.hover-scale {
  color: gray;
}

.hover-scale:hover {
  transform: scale(1.2);
  color: darkgreen;
}
.preview-controls {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 10px;
  padding: 5px;
}

.preview-button {
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  border: none;
  border-radius: 5px;
  background-color: #f0f0f0;
  transition: background-color 0.3s, transform 0.2s;
}

.preview-button:hover {
  background-color: #f0f0f0;
  transform: scale(1.05);
}
.circle {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-shadow: "0 0 10px rgb(0 0 0 / 20%)";
}
.orange {
  background: #fdb933;
}
.green {
  background: lightgreen;
}
.red {
  background: lightcoral;
}
.blue {
  background: lightblue;
}

.info-card {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 16px;
  text-align: center;
  border: 1px solid #e0e0e0;
  flex: 1 1 200px; /* Flex basis of 200px but able to grow and shrink */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card-title {
  font-size: 16px;
  color: #888;
  margin-bottom: 5px;
}

.card-value {
  font-size: 20px;
  font-weight: bold;
  color: #333;
}

.card-value.highlighted {
  color: #333; /* Example highlight color */
}

.icon {
  font-size: 20px;
  margin-bottom: 10px;
}
.chip-container {
  display: flex; /* Flex layout for alignment */
  align-items: center; /* Vertically align label and value */
  gap: 0.5rem; /* Space between label and chip */
  margin-bottom: 20px; /* Add spacing between fields */
}

.chip-label {
  color: #6c757d; /* Muted gray color for label */
  font-size: 14px; /* Consistent font size */
  margin: 0; /* Remove extra margin */
  flex: 0 0 150px; /* Fixed width for labels */
  text-align: left; /* Right-align the label text */
}

.chip {
  display: inline-block; /* Inline chip appearance */
  padding: 0.1rem 1rem; /* Padding for chip look */
  font-size: 0.9rem; /* Adjust font size */
  font-weight: 500; /* Slightly bold */
  color: #fff; /* White text for contrast */
  background-color: #4d82d3; /* Primary blue color */
  border-radius: 6px; /* Rounded edges */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}
.chiphover {
  width: 100%;
  padding: 10px;
  cursor: pointer;
}
.chiphover:hover {
  width: 100%;
  background-color: #e0e0e0;
}
.chip:empty::before {
  content: "None/Keine"; /* Add placeholder text */
  color: #d1d1d1; /* Light gray color for placeholder */
  font-style: italic; /* Italicize placeholder */
}
.auth-bg {
  padding: 50px 100px;
  background-repeat: no-repeat;
  background-size: cover;
}

.authentication-box {
  margin: 0 auto;
  width: 460px;
  z-index: 2;
  background-color: white;
  border-radius: 6px;
  padding-top: 20px;
}
.ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
  border: 1px solid #ccced1 !important;
  box-shadow: none !important;
}

.react-datepicker .react-datepicker__current-month {
  color: var(--primaryColor) !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: var(--lightPrimaryColor) !important;
}
.react-datepicker__day--keyboard-selected:hover {
  background-color: var(--primaryColor) !important;
  color: white !important;
}

.react-datepicker .react-datepicker__day--selected {
  background-color: var(--lightPrimaryColor) !important;
}

.react-datepicker .react-datepicker__day--selected:hover {
  background-color: var(--primaryColor) !important;
}

.react-datepicker__close-icon::after {
  background-color: var(--primaryColor) !important;
}

.dzu-inputLabel {
  color: var(--primaryColor) !important;
}
/**=====================
   55. theme layout CSS Start
==========================**/
//Sidebar color scss
$color1: #405365;
$color2: #1c1d1e;
$color3: #0c0c3c;
$color4: #0747a6;
$color5: #4d3096;
@each $bg-name, $bg-color in (color1, $color1), (color2, $color2),
  (color3, $color3), (color4, $color4), (color5, $color5), (light, $light-color),
  (dark, #2f3c4e)
{
  .bg-#{$bg-name} {
    background-color: $bg-color !important ;
  }
}
$i: 1;
@while $i<=6 {
  .bg-pattern#{$i} {
    background-image: url(../../images/sidebar-pattern/#{$i}.png) !important;
    background-color: #2f3c4e;
  }
  .bg-img#{$i} {
    background-image: url(../../images/sidebar-image/#{$i}.jpg) !important;
  }
  $i: $i + 1;
}
.page-wrapper {
  .page-body-wrapper {
    .page-sidebar {
      @each $sidebar-name, $sidebar-color in (color1, $color1),
        (color2, $color2), (color3, $color3), (color4, $color4),
        (color5, $color5), (light, $light-color), (dark, #2f3c4e)
      {
        &.#{$sidebar-name}-sidebar {
          background-color: $sidebar-color;
          .sidebar-menu {
            > li {
              > a {
                &:hover {
                  color: $white;
                }
              }
            }
            .sidebar-submenu {
              > li {
                > a {
                  &:hover,
                  &.active {
                    color: $white;
                  }
                }
                &.active {
                  > a {
                    &.active {
                      color: $white;
                    }
                  }
                }
              }
            }
          }
          @if ($sidebar-name == light) {
            .sidebar-user {
              h6 {
                color: $primary-color!important;
              }
              p {
                color: $theme-body-font-color;
              }
            }
            .sidebar-menu {
              > li {
                > a {
                  color: $theme-body-font-color;
                  &:hover {
                    color: $primary-color;
                  }
                }
              }
              .sidebar-submenu {
                > li {
                  > a {
                    color: $theme-body-font-color;
                    &:hover,
                    &.active {
                      color: $primary-color;
                    }
                  }
                  &.active {
                    > a {
                      &.active {
                        color: $primary-color;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      $i: 1;
      @while $i<=6 {
        &.sidebar-pattern#{$i} {
          background-image: url(../../images/sidebar-pattern/#{$i}.png);
          background-color: #2f3c4e !important;
        }
        &.sidebar-img#{$i} {
          background-image: url(../../images/sidebar-image/#{$i}.jpg);
        }
        $i: $i + 1;
      }
    }
  }
}
//Sidebar settings
.page-wrapper {
  .page-body-wrapper {
    .page-sidebar {
      &[sidebar-layout="border-sidebar"] {
        .sidebar-header {
          border-bottom: 1px solid rgba(124, 124, 124, 0.15);
          transition: all 0.3s ease;
        }
      }
      &[sidebar-layout="iconcolor-sidebar"] {
        .sidebar-menu {
          li {
            $colors: $success-color, $secondary-color, $primary-color,
              $danger-color, $info-color, $warning-color, $light-color;
            $repeat: 100;
            @for $i from 1 through $repeat {
              &:nth-child(#{length($colors)}n + #{$i}) {
                svg {
                  color: lighten(nth($colors, random(length($colors))), 15%);
                }
                a {
                  &:hover {
                    > span {
                      color: lighten(
                        nth($colors, random(length($colors))),
                        15%
                      );
                    }
                  }
                }
              }
            }
          }
        }
        &.light-sidebar {
          .sidebar-menu {
            li {
              $colors: $success-color, $secondary-color, $primary-color,
                $danger-color, $info-color, $warning-color, $dark-color;
              $repeat: 100;
              @for $i from 1 through $repeat {
                &:nth-child(#{length($colors)}n + #{$i}) {
                  svg {
                    color: darken(nth($colors, random(length($colors))), 15%);
                  }
                  a {
                    &:hover {
                      span {
                        color: darken(
                          nth($colors, random(length($colors))),
                          15%
                        );
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .sidebar-header {
        transition: all 0.3s ease;
      }
    }
    &.sidebar-icon {
      .page-sidebar {
        &.light-sidebar {
          .sidebar-menu {
            li {
              &:hover {
                .single-header {
                  background-color: $light-color !important;
                }
                .sidebar-submenu {
                  background-color: $light-color !important;
                }
              }
              a:hover + ul {
                background-color: $light-color !important;
              }
              ul {
                li {
                  &:hover {
                    .sidebar-submenu {
                      background-color: $light-color !important;
                    }
                  }
                }
              }
            }
          }
        }
        &.color1-sidebar {
          .sidebar-menu {
            li {
              &:hover {
                .single-header {
                  background-color: $color1 !important;
                }
                .sidebar-submenu {
                  background-color: $color1 !important;
                }
              }
              a:hover + ul {
                background-color: $color1 !important;
              }
              ul {
                li {
                  &:hover {
                    .sidebar-submenu {
                      background-color: $color1 !important;
                    }
                  }
                }
              }
            }
          }
        }
        &.color2-sidebar {
          .sidebar-menu {
            li {
              &:hover {
                .single-header {
                  background-color: $color2 !important;
                }
                .sidebar-submenu {
                  background-color: $color2 !important;
                }
              }
              a:hover + ul {
                background-color: $color2 !important;
              }
              ul {
                li {
                  &:hover {
                    .sidebar-submenu {
                      background-color: $color2 !important;
                    }
                  }
                }
              }
            }
          }
        }
        &.color3-sidebar {
          .sidebar-menu {
            li {
              &:hover {
                .single-header {
                  background-color: $color3 !important;
                }
                .sidebar-submenu {
                  background-color: $color3 !important;
                }
              }
              a:hover + ul {
                background-color: $color3 !important;
              }
              ul {
                li {
                  &:hover {
                    .sidebar-submenu {
                      background-color: $color3 !important;
                    }
                  }
                }
              }
            }
          }
        }
        &.color4-sidebar {
          .sidebar-menu {
            li {
              &:hover {
                .single-header {
                  background-color: $color4 !important;
                }
                .sidebar-submenu {
                  background-color: $color4 !important;
                }
              }
              a:hover + ul {
                background-color: $color4 !important;
              }
              ul {
                li {
                  &:hover {
                    .sidebar-submenu {
                      background-color: $color4 !important;
                    }
                  }
                }
              }
            }
          }
        }
        &.color5-sidebar {
          .sidebar-menu {
            li {
              &:hover {
                .single-header {
                  background-color: $color5 !important;
                }
                .sidebar-submenu {
                  background-color: $color5 !important;
                }
              }
              a:hover + ul {
                background-color: $color5 !important;
              }
              ul {
                li {
                  &:hover {
                    .sidebar-submenu {
                      background-color: $color5 !important;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

//Dark layout
body {
  &.light-only {
    .page-wrapper {
      .page-body-wrapper {
        .page-sidebar {
          background-color: $white;
          .sidebar-user {
            h6 {
              color: $primary-color;
            }
            p {
              color: $theme-body-font-color;
            }
          }
          .sidebar-menu {
            > li {
              > a {
                color: $theme-body-font-color;
                &:hover {
                  color: $primary-color;
                }
              }
            }
            .sidebar-submenu {
              > li {
                > a {
                  color: $theme-body-font-color;
                  
                  &.active {
                    color: $primary-color!important;
                  }
                }
                &.active {
                  > a {
                    &.active {
                      color: $primary-color;
                    }
                  }
                }
              }
            }
          }
          .sidebar {
            &:hover {
              &.custom-scrollbar {
                &::-webkit-scrollbar-thumb {
                  background-color: rgba(0, 0, 0, 0.15);
                }
              }
            }
          }
        }
        .page-body {
          background-color: $light-color;
        }
      }
    }
  }
  &.dark-only {
    color: $dark-all-font-color;
    background-color: $dark-body-background;
    .offcanvas-bookmark {
      &.page-wrapper {
        .page-body-wrapper {
          .page-body {
            .bookmark {
              ul {
                li {
                  .Typeahead-menu {
                    a {
                      color: $dark-small-font-color;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .ProfileCard-realName {
      a,
      span {
        color: $dark-small-font-color;
      }
    }
    .ProfileCard {
      &:hover {
        background: $dark-border-color;
      }
    }
    .kUKEuI {
      background-color: $dark-body-background;
    }
    .bqNMrp {
      border-bottom: 1px solid $dark-border-color;
    }
    .vertical-timeline-element-content {
      background-color: $dark-border-color !important;
      .vertical-timeline-element-content-arrow {
        border-right: 7px solid $dark-border-color !important;
      }
    }
    .vertical-timeline-element-icon {
      box-shadow: 0 0 0 4px $dark-border-color, inset 0 2px 0 rgba($black, 0.08),
        0 3px 0 4px rgba($black, 0.05);
    }
    .vertical-timeline {
      &::before {
        background: $dark-border-color !important;
      }
    }
    .sweet-alert,
    .reactour__helper,
    .fileContainer,
    .ceCBVJ {
      background-color: $dark-card-background !important;
    }
    .rbc-month-view,
    .rbc-time-view {
      border: 1px solid $dark-border-color;
    }
    .react-datepicker {
      background-color: $dark-body-background;
      .react-datepicker__day-name,
      .react-datepicker__day {
        color: $dark-all-font-color;
      }
      .react-datepicker__header {
        border-bottom: 1px solid $dark-border-color;
      }
    }
    .crm-activity {
      ul.dates {
        li {
          + li {
            border: 1px solid $dark-border-color;
          }
        }
      }
    }
    .rbc-toolbar {
      button {
        color: $dark-small-font-color;
        &:hover,
        &:focus {
          background-color: $primary-color;
          color: $white;
        }
      }
    }
    .rbc-month-row {
      + .rbc-month-row {
        border-top: 1px solid $dark-border-color;
      }
    }
    .rbc-off-range-bg {
      background: $dark-body-background;
    }
    .rbc-day-bg {
      + .rbc-day-bg {
        border-left: 1px solid $dark-border-color;
      }
    }
    .rbc-header,
    .rbc-timeslot-group {
      border-bottom: 1px solid $dark-border-color;
      + .rbc-header {
        border-left: 1px solid $dark-border-color;
      }
    }
    .rbc-time-content {
      border-top: 2px solid $dark-border-color;
    }
    .rbc-agenda-view {
      table {
        &.rbc-agenda-table {
          border: 1px solid $dark-border-color;
          tbody {
            > tr {
              > td {
                + td {
                  border-left: 1px solid $dark-border-color;
                }
              }
            }
          }
          thead {
            > tr {
              > th {
                border-bottom: 1px solid $dark-border-color;
              }
            }
          }
        }
      }
    }
    .rbc-day-slot {
      .rbc-time-slot {
        border-top: 1px solid $dark-border-color;
      }
    }
    .rbc-time-header-content,
    .rbc-time-content > * + * > * {
      border-left: $dark-border-color;
    }
    #external-events {
      border: 1px solid $dark-border-color;
    }
    .fc-unthemed {
      th,
      td,
      thead,
      tbody,
      .fc-divider,
      .fc-row,
      .fc-content,
      .fc-popover,
      .fc-list-view,
      .fc-list-heading td {
        border-color: $dark-border-color;
      }
      td {
        &.fc-today {
          background: $dark-body-background;
        }
      }
      .fc-divider,
      .fc-popover,
      .fc-list-heading {
        background-color: $dark-border-color;
        .fc-header,
        td {
          background-color: $dark-border-color;
        }
      }
    }
    .react-calendar__navigation__prev-button,
    .react-calendar__navigation__next-button,
    .react-calendar__navigation__label,
    .react-calendar__tile {
      color: $dark-all-font-color;
    }
    .react-calendar__month-view__days__day--neighboringMonth {
      color: $dark-gray;
    }
    .react-datepicker__time-container {
      .react-datepicker__time {
        background-color: $dark-body-background;
      }
      .react-datepicker__time-list {
        color: $white;
      }
    }
    .table-responsive {
      table,
      .table-inverse {
        .thead-light {
          th {
            color: $white !important;
          }
        }
        tbody {
          tr {
            border-bottom: 1px solid $dark-border-color;
          }
        }
      }
    }
    .react-datepicker {
      .react-datepicker__today-button {
        background-color: $dark-body-background !important;
        color: $white !important;
        border-top: 1px solid $dark-border-color;
      }
    }
    .txt-dark {
      color: $dark-all-font-color !important;
    }
    .progress {
      background-color: $dark-body-background;
    }
    .default-according {
      .card {
        .card-header,
        .card-body {
          border: 1px solid $dark-border-color;
        }
      }
    }
    .breadcrumb {
      .txt-dark {
        color: $theme-body-font-color !important;
      }
    }
    .u-step {
      .u-step-desc {
        span,
        p {
          color: $dark-all-font-color;
        }
      }
    }
    .border-tab.nav-left {
      .nav-link {
        color: $dark-all-font-color;
      }
    }
    .border-tab.nav-tabs {
      .nav-item {
        .nav-link {
          color: $dark-all-font-color;
        }
      }
    }
    .loader {
      .bg-dark {
        background-color: $dark-all-font-color !important;
      }
    }
    .alert-dismissible {
      .txt-dark {
        color: $theme-body-font-color !important;
      }
    }
    .btn-light {
      background-color: $dark-body-background !important;
      color: $dark-all-font-color !important;
      border-color: $dark-border-color !important;
    }
    .page-main-header {
      .main-header-right {
        .nav-right {
          .profile-dropdown {
            li {
              &:nth-child(4) {
                border-top: 1px solid $dark-border-color;
              }
            }
          }
        }
      }
    }
    .project-box {
      background-color: $dark-card-background;
      border: 1px solid $dark-border-color;
    }
    .editor-toolbar {
      button {
        color: $white;
      }
    }
    .server-widgets {
      .bottom-server {
        h5 {
          .second-color {
            span {
              color: $dark-all-font-color;
            }
          }
        }
      }
    }
    .crypto-accordion {
      .accordion {
        .accordion-item {
          border: 1px solid $dark-border-color;
          .panel {
            .media-accordion {
              background-color: $dark-body-background;
              .d-flex {
                .flex-grow-1 {
                  p {
                    color: $dark-all-font-color;
                  }
                }
                + .d-flex {
                  background-color: $dark-card-background !important;
                  border-top: 1px solid $dark-border-color;
                }
              }
            }
          }
        }
      }
    }
    .email-wrap {
      .email-app-sidebar {
        .main-menu {
          > li {
            a {
              &.active,
              &:hover {
                background: $dark-body-background;
              }
            }
          }
        }
      }
    }
    .file-sidebar {
      .pricing-plan {
        border: 1px solid $dark-border-color;
      }
    }
    .jkanban-container {
      .custom-board {
        .kanban-drag {
          div.react-kanban-column {
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
              div {
                &:nth-child(2) {
                  div {
                    background: $dark-card-background;
                  }
                }
              }
            }
          }
        }
      }
    }
    .user-card {
      .user-badge {
        .badge-light {
          background-color: $dark-body-background;
        }
      }
    }
    .search-knowledge {
      input {
        background-color: $dark-card-background;
      }
    }
    .product-filter .color-selector ul li.white {
      background-color: $white;
    }
    .file-content {
      .files,
      .folder {
        .file-box,
        .folder-box {
          background-color: $dark-body-background;
          border: 1px solid $dark-border-color;
          .file-top {
            background-color: $dark-card-background;
            border: 1px solid $dark-border-color;
          }
        }
      }
      .form-inline {
        border: 1px solid $dark-border-color;
        background-color: $dark-body-background;
      }
      .form-control {
        border: unset !important;
      }
    }
    .form-select {
      color: $dark-all-font-color;
      background-color: $dark-body-background;
      border: 1px solid $dark-border-color;
    }
    .list-persons {
      .profile-mail {
        .d-flex {
          .flex-grow-1 {
            ul {
              li {
                + li {
                  border-left: 2px solid $dark-border-color;
                }
              }
            }
          }
        }
      }
    }
    .page-main-header {
      .main-header-right {
        .nav-right {
          .profile-dropdown {
            li {
              a {
                svg {
                  color: $dark-all-font-color !important;
                  &:hover {
                    color: $primary-color !important;
                  }
                }
              }
            }
          }
        }
      }
    }
    .contacts-tabs {
      .nav-pills {
        border-right: 1px solid $dark-border-color;
        .nav-link {
          + .nav-link {
            border-top: 1px solid $dark-border-color;
          }
        }
      }
    }
    .contact-profile {
      .icon-wrapper {
        background-color: $dark-body-background;
      }
    }
    .social-app-profile {
      .market-tabs {
        border-top: none;
      }
    }
    .browse {
      .browse-articles {
        h6 {
          border-bottom: 1px solid $dark-border-color;
        }
      }
    }
    .my-gallery {
      &.gallery-with-description {
        .caption {
          border: 1px solid $dark-border-color;
        }
        figure {
          .img-thumbnail {
            background-color: $dark-card-background !important;
          }
        }
      }
    }
    .masonry-with-dec {
      .my-masonry-grid_column {
        li {
          img,
          .caption {
            border: 1px solid $dark-border-color;
          }
        }
      }
    }
    .form-group {
      .form-control {
        background-color: $dark-body-background;
      }
    }
    .table-primary,
    .table-secondary,
    .table-success,
    .table-info,
    .table-warning,
    .table-danger,
    .table-light {
      th,
      td {
        color: $black !important;
      }
    }
    @each $border-tab-name, $border-tab-color in (primary, $primary-color),
      (secondary, $secondary-color), (success, $success-color),
      (danger, $danger-color), (info, $info-color), (light, $light-color),
      (dark, $dark-color), (warning, $warning-color)
    {
      .borderb-tab-#{$border-tab-name} {
        .nav-tabs {
          &.nav-item {
            .nav-link {
              color: $dark-all-font-color;
            }
          }
          .nav-link {
            &.active {
              color: $border-tab-color;
              border-bottom: 2px solid $border-tab-color !important;
            }
          }
        }
      }
    }
    ol {
      &.progtrckr {
        li {
          &.progtrckr-doing,
          &.progtrckr-done {
            color: $dark-small-font-color;
          }
          &.progtrckr-todo {
            &:before {
              background-color: $dark-card-background;
            }
          }
        }
      }
    }
    .search-page {
      ul {
        &.search-info {
          li {
            + li {
              border-left: 1px solid $dark-border-color;
            }
          }
        }
      }
    }
    .styles_modal__gNwvD {
      background-color: $dark-card-background;
    }
    .product-size {
      ul {
        li {
          button {
            color: $dark-small-font-color;
          }
        }
      }
    }
    .product-wrapper {
      &.sidebaron {
        .product-sidebar {
          .filter-section {
            .card {
              .left-filter {
                background-color: $dark-card-background;
              }
            }
          }
        }
      }
    }
    .mega-inline,
    .mega-horizontal {
      .card {
        border: 1px solid $dark-border-color;
      }
    }

    // draggable card
    .react-kanban-column {
      background-color: $dark-body-background;
    }
    .react-kanban-card,
    .react-kanban-card-adder-form,
    .react-kanban-card-skeleton {
      background-color: $dark-card-background;
    }
    .react-kanban-card__title {
      border-bottom: 1px solid $dark-border-color;
    }
    .draggable-card {
      .react-kanban-card__title {
        border-bottom: none;
      }
    }

    .input-cropper {
      border: 1px solid $dark-border-color;
    }
    .dzu-previewContainer {
      border-bottom: 1px solid #665f5f;
    }
    .dzu-previewButton {
      filter: brightness(0.5) invert(1);
    }

    // react datatable
    .rdt_Table {
      border: 1px solid $dark-border-color;
    }
    .rdt_TableHeadRow {
      background-color: $dark-card-background;
    }
    .rdt_TableCol,
    .rdt_TableCol_Sortable,
    .___react-data-table-allow-propagation___ {
      color: $dark-all-font-color;
    }
    .rdt_TableRow {
      &:nth-of-type(odd) {
        color: $dark-all-font-color;
        background-color: $dark-body-background;
      }
      &:nth-of-type(even) {
        color: $dark-all-font-color;
        background-color: $dark-card-background;
      }
    }
    .fpZHHx {
      fill: $dark-all-font-color;
      color: $dark-all-font-color;
    }
    .rdt_Pagination {
      background-color: $dark-card-background;
      border-top-color: $dark-border-color;
      color: $dark-all-font-color;
      button {
        color: $dark-all-font-color;
        fill: $dark-all-font-color;
        &:disabled {
          color: $dark-small-font-color;
          fill: $dark-small-font-color;
        }
      }
    }
    .rdt_TableCol_Sortable {
      &:hover {
        color: $dark-all-font-color;
      }
    }
    .rdt_TableHeader {
      background-color: $dark-body-background !important;
      > div {
        &:last-child {
          background-color: $dark-body-background !important;
          color: $dark-all-font-color;
        }
      }
    }

    .popover-header {
      color: $theme-body-font-color;
    }
    .grid-showcase {
      span {
        background-color: $dark-card-background;
        border: 1px solid $dark-border-color;
      }
    }
    .form-control {
      background-color: $dark-body-background;
      border: 1px solid $dark-border-color;
      color: $dark-all-font-color;
    }
    .dropdown-menu {
      background-color: $dark-body-background !important;
    }
    .dropdown-item,
    span.twitter-typeahead .tt-suggestion {
      color: $dark-all-font-color;
      &:hover,
      &:focus,
      &.active {
        background-color: $dark-card-background !important;
        color: $dark-all-font-color !important;
      }
    }

    // kanban board
    .kanban-container {
      .kanban-item {
        .kanban-box {
          background-color: $dark-card-background;
        }
      }
    }
    .jkanban-container {
      .kanban-container {
        .kanban-item {
          .kanban-box {
            border: 1px solid $dark-border-color;
          }
        }
      }
    }

    .modal-header {
      .close {
        color: $dark-small-font-color;
      }
    }
    .modal-footer {
      border-top: 1px solid $dark-border-color;
    }
    .tab-vertical {
      .nav-tabs {
        border: 1px solid hsla(0, 0%, 100%, 0.3);
        .nav-link {
          + .nav-link {
            border-top: 1px solid hsla(0, 0%, 100%, 0.3);
          }
          &:hover,
          &:focus {
            background-color: $dark-body-background;
          }
        }
      }
    }
    .default-according {
      .list-group-item {
        border: 1px solid $dark-border-color !important;
      }
    }
    .bar-chart-widget {
      .num {
        .color-bottom {
          span {
            color: $dark-all-font-color;
          }
        }
      }
    }
    .Typeahead-menu {
      background-color: $dark-body-background;
    }
    .ecommerce-widget {
      border: 1px solid $dark-border-color;
    }
    .bookmark {
      ul {
        li {
          .search-form {
            .form-control-search {
              input {
                background-color: $dark-body-background;
                border: 1px solid $dark-border-color;
                color: $dark-all-font-color;
              }
              &:before {
                background: $dark-border-color;
              }
            }
          }
        }
      }
    }
    .cart {
      .qty-box {
        .input-group {
          .btn {
            background-color: $dark-border-color !important;
            border-color: $dark-border-color !important;
          }
        }
      }
    }
    .checkout {
      .checkout-details {
        background-color: $dark-body-background;
        border: 1px solid $dark-border-color;
        padding: 40px;
      }
    }
    .order-box {
      .title-box {
        color: #bfc2c6;
        border-bottom: 1px solid $dark-border-color;
      }
      .qty {
        li {
          color: #bfc2c6;
          span {
            color: #bfc2c6;
          }
        }
        border-bottom: 1px solid $dark-border-color;
      }
      .sub-total {
        li {
          color: #bfc2c6;
        }
      }
      .total {
        li {
          color: #bfc2c6;
        }
      }
    }
    .shopping-checkout-option {
      .checkbox_animated {
        &:after {
          border: 2px solid $dark-border-color;
        }
      }
    }
    .animate-chk {
      .radio_animated {
        &:after {
          border: 2px solid $dark-border-color;
        }
      }
    }
    .custom-scrollbar::-webkit-scrollbar-thumb {
      background-color: rgba(41, 50, 64, 0.52);
    }
    .card {
      .card-header {
        .card-header-right {
          background-color: $dark-card-background;
        }
      }
    }
    .product-box {
      .product-img {
        .product-hover {
          ul {
            li {
              .btn {
                color: $dark-small-font-color;
              }
              &:hover {
                .btn {
                  color: $white;
                }
              }
            }
          }
        }
      }
    }
    .radio_animated,
    .checkbox_animated {
      &:after {
        background: $dark-body-background;
        border: 2px solid $dark-body-background;
      }
    }
    .slider-product {
      border-top: 1px solid $dark-border-color;
      border-bottom: 1px solid $dark-border-color;
    }
    .square-product-setting {
      .icon-grid {
        background-color: $dark-card-background;
        svg {
          color: $dark-all-font-color;
        }
      }
    }
    .bitcoin-form {
      .bitcoin-form-dropdown {
        .onhover-dropdown {
          .btn {
            background-color: $dark-border-color;
            color: $dark-all-font-color;
          }
          .onhover-show-div {
            background-color: $dark-body-background;
            box-shadow: 0 0 2px 2px $dark-body-background;
          }
        }
      }
      .form-row {
        .input-group {
          .input-group-prepend {
            .input-group-text {
              border: 1px solid $dark-border-color;
            }
          }
        }
      }
    }
    .active-order-table,
    .market-table {
      table {
        thead {
          tr {
            th {
              border-top: none !important;
            }
          }
        }
        tbody {
          tr {
            td {
              border-bottom: 1px solid $dark-border-color;
            }
            &:last-child {
              td {
                border-bottom: none;
              }
            }
          }
        }
      }
    }
    .pricing-card-design-2 {
      .pricing-block {
        .pricing-inner {
          ul {
            li {
              border-bottom: 1px solid $dark-border-color;
            }
          }
        }
      }
    }
    .flot-chart-container {
      .legend {
        .table {
          tbody {
            background-color: $dark-card-background;
            color: $white;
            .legendLabel {
              padding-left: 5px;
            }
          }
        }
      }
    }
    .google-visualization-tooltip {
      text {
        fill: rgba(0, 0, 0, 0.7) !important;
      }
    }
    .nav-dark,
    .nav-pills.nav-dark {
      .nav-link {
        color: $white;
      }
    }
    .loader-wrapper {
      background-color: $dark-body-background;
      .loader {
        background-color: $dark-body-background !important;
      }
    }
    // project
    .theme-tab {
      .tab-title {
        border-bottom: 1px solid $dark-border-color;
        li {
          &.react-tabs__tab--selected {
            background-color: $dark-card-background;
          }
        }
      }
    }
    .schedule {
      .d-flex {
        .schedule-dropdown {
          .dropdown {
            background-color: $dark-body-background;
          }
        }
      }
    }
    // datatable
    .ReactTable {
      border: 1px solid $dark-border-color;
      .rt-table {
        .rt-thead {
          border-bottom: 1px solid $dark-border-color;
        }
        .rt-tbody {
          .rt-tr-group {
            .rt-tr {
              &.-odd {
                background-color: $dark-body-background;
              }
              &:hover {
                background-color: #28303c;
              }
            }
          }
        }
      }
      .-pagination {
        border-top: 1px solid $dark-border-color !important;
        .-btn {
          color: $dark-small-font-color;
        }
        input,
        select {
          background: $dark-body-background;
          color: $white;
        }
      }
    }
    .page-wrapper {
      /* Main Header start */
      .page-main-header {
        background-color: $dark-card-background;
        .main-header-right {
          background-color: $dark-card-background;
          li {
            i {
              color: $light-color;
            }
          }
          .nav-right {
            > ul {
              > li {
                border-left: 1px solid $dark-border-color;
                &:last-child,
                &:nth-child(5),
                &:nth-child(1) {
                  border-left: none;
                }
                &:nth-child(5) {
                  border-right: 1px solid $dark-border-color;
                }
                &:first-child {
                  .search-form {
                    .form-group {
                      &:before {
                        background: $dark-border-color;
                      }
                    }
                  }
                }
              }
            }
            .nav-menus {
              li {
                .d-flex {
                  .flex-grow-1 {
                    .txt-dark {
                      color: $dark-all-font-color !important;
                    }
                  }
                }
                .onhover-show-div {
                  background-color: $dark-body-background;
                  box-shadow: 0 0 2px 2px $dark-card-background;
                  &:before {
                    border-bottom: 7px solid $dark-body-background;
                  }
                  &:after {
                    border-bottom: 7px solid $dark-body-background;
                  }
                }
                .notification-dropdown.onhover-show-div {
                  li {
                    border-bottom: 1px solid $dark-border-color;
                    span {
                      color: $dark-small-font-color;
                    }
                    &:hover {
                      background-color: #282e38;
                    }
                  }
                  .bg-light {
                    background-color: #282e38 !important;
                    color: $dark-all-font-color !important;
                  }
                }
                .language-dropdown {
                  li {
                    a {
                      color: $dark-all-font-color;
                    }
                  }
                }
              }
            }
            .profile-dropdown {
              li {
                a {
                  color: $dark-all-font-color;
                  svg {
                    path,
                    line,
                    circle {
                      color: $dark-all-font-color !important;
                    }
                  }
                  &:hover {
                    color: $primary-color;
                    svg {
                      line,
                      path,
                      circle {
                        color: $primary-color !important;
                      }
                    }
                  }
                }
              }
            }
            .search-form {
              input {
                background-color: $dark-body-background;
                border: 1px solid $dark-border-color;
                color: $dark-all-font-color;
              }
            }
            > ul {
              > li {
                .d-flex {
                  .flex-grow-1 {
                    .txt-dark {
                      color: $dark-all-font-color !important;
                    }
                  }
                }
                .onhover-show-div {
                  background-color: $dark-body-background;
                  box-shadow: 0 0 2px 2px $dark-card-background;
                  &:before {
                    border-bottom: 7px solid $dark-body-background;
                  }
                  &:after {
                    border-bottom: 7px solid $dark-body-background;
                  }
                }
                .notification-dropdown.onhover-show-div {
                  li {
                    border-bottom: 1px solid $dark-border-color;
                    span {
                      color: $dark-small-font-color;
                    }
                  }
                }
                .language-dropdown {
                  li {
                    a {
                      color: $dark-all-font-color;
                    }
                  }
                }
              }
            }
          }
          .mega-menu-header {
            .vertical-menu-main {
              .main-nav {
                .main-menu {
                  li {
                    background-color: $dark-card-background;
                  }
                }
              }
            }
          }
        }
        .main-header-left {
          background-color: $dark-card-background;
          .logo-wrapper {
            a {
              .image-dark {
                display: none;
              }
              .image-light {
                display: block;
              }
            }
          }
        }
        @media only screen and (max-width: 991px) {
          .main-header-right {
            .nav-right {
              > ul {
                background-color: $dark-card-background;
                box-shadow: 0 2px 2px 2px $dark-border-color;
              }
            }
          }
        }
        @media only screen and (max-width: 575px) {
          .nav-right {
            > ul {
              .search-form {
                .form-control-plaintext {
                  background-color: $dark-body-background;
                  border: 1px solid $dark-border-color;
                  color: $dark-all-font-color;
                }
              }
            }
          }
        }
      }
      .right-sidebar {
        background-color: $dark-body-background;
        box-shadow: 0 0 11px rgba(69, 110, 243, 0.13);
        .modal-header {
          border-bottom: 1px solid $dark-border-color;
        }
        .friend-list-search {
          background-color: $dark-card-background;
          input {
            background-color: $dark-body-background;
            border: 1px solid $dark-border-color;
          }
        }
      }
      /* Main Header ends */
      .page-body-wrapper {
        /* sidebar start */
        &.sidebar-icon {
          .page-sidebar {
            .sidebar-menu {
              .sidebar-header {
                > i {
                  color: $dark-all-font-color;
                }
              }
              li {
                background-color: $dark-card-background;
                &:hover {
                  .single-header,
                  .sidebar-submenu {
                    background-color: $dark-card-background;
                  }
                }
                a:hover + ul {
                  background-color: $dark-card-background;
                }
              }
            }
            .sidebar-user {
              box-shadow: 3px 2px 7px -1px rgb(41, 50, 64);
            }
          }
        }
        .page-sidebar {
          &.native-image-bg {
            background-blend-mode: overlay;
            background-color: #17191d;
          }
          &.navigation-bordered {
            .sidebar-header {
              border-bottom: 1px solid rgba(241, 241, 241, 0.15);
            }
          }
          background: $dark-card-background;
          .sidebar-user {
            background-color: $dark-card-background;
            box-shadow: 3px 2px 8px -1px rgba(255, 255, 255, 0.13);
            h6 {
              color: $dark-all-font-color;
            }
            p {
              color: $white;
            }
          }
          .sidebar-menu {
            .sidebar-title {
              color: $primary-color;
              border-bottom: 1px solid rgba(241, 241, 241, 0.35);
            }
            > li {
              > a {
                color: $dark-all-font-color;
                &.active {
                  color: $primary-color;
                }
                &:hover {
                  color: $primary-color;
                }
              }
              .label {
                margin-top: 3px;
                margin-right: 5px;
              }
              &:hover > a {
                @extend %common;
                color: $dark-all-font-color;
              }
            }
            li {
              &.sidebar-header {
                color: $warning-color;
              }
            }
            a {
              color: $light-color;
            }
          }
          .sidebar-widget {
            border-top: 1px solid $dark-border-color;
            .sidebar-widget-top {
              i {
                background-color: $dark-card-background;
              }
            }
            .sidebar-widget-bottom {
              background-color: $dark-body-background;
            }
          }
        }
        /* sidebar end */

        /* body start*/
        .page-body {
          .topper-lists {
            table {
              tbody {
                tr {
                  td {
                    p {
                      color: $dark-small-font-color;
                    }
                  }
                }
              }
            }
          }
          .header-small {
            color: $dark-small-font-color;
          }
          .statistics {
            p {
              color: $dark-small-font-color;
            }
          }
          .feather-main,
          .professor-table {
            .flex-grow-1,
            .professor-block {
              p {
                color: $dark-small-font-color;
              }
            }
          }
          .logs-element {
            span {
              + span {
                color: $dark-small-font-color;
              }
            }
          }
          .progress-block {
            .progress-title {
              span {
                + span {
                  color: $dark-small-font-color;
                }
              }
            }
          }
          .ecommerce-icons {
            div {
              span {
                color: $dark-small-font-color;
              }
            }
          }
          .new-users,
          .recent-notification {
            .d-flex {
              .flex-grow-1 {
                p {
                  color: $dark-small-font-color;
                }
              }
            }
          }
          .progress-media {
            .d-flex {
              .flex-grow-1 {
                span {
                  color: $dark-small-font-color;
                }
              }
            }
            .progress-change {
              span {
                color: $dark-small-font-color;
              }
            }
          }
          .topper-lists {
            table {
              tbody {
                tr {
                  td {
                    border-top: none !important;
                    border-bottom: 1px solid $dark-border-color;
                  }
                  &:last-child {
                    td {
                      border-bottom: none;
                    }
                  }
                }
              }
            }
          }
          .notifiaction-media {
            .d-flex {
              .flex-grow-1 {
                .circle-left {
                  border: 5px solid #334154;
                }
              }
            }
          }
          .upcoming-event {
            .upcoming-innner {
              border-bottom: 1px solid $dark-border-color;
              &:last-child {
                border-bottom: none;
              }
            }
          }
          .professor-table {
            table {
              tbody {
                tr {
                  td {
                    border-top: none !important;
                  }
                }
              }
            }
          }
          .number-widgets {
            .d-flex {
              .flex-grow-1 {
                h6 {
                  color: $dark-all-font-color;
                }
              }
            }
          }
          .activity {
            .d-flex {
              .gradient-round {
                &.gradient-line-1,
                &.small-line,
                &.medium-line {
                  &:after {
                    background-color: $dark-border-color;
                  }
                }
              }
              .flex-grow-1 {
                h6 {
                  span {
                    color: $dark-small-font-color;
                  }
                }
              }
            }
          }
          .card.custom-card {
            .card-header {
              border-bottom: none !important;
            }
          }
        }
        .custom-card .card-header img {
          background-color: $black;
          opacity: 0.8;
        }

        .page-header {
          .row {
            h3 {
              small {
                color: $dark-small-font-color;
              }
            }
          }
          .breadcrumb-item {
            &.active {
              color: $dark-small-font-color;
            }
          }
        }
        .page-body {
          background-color: $dark-body-background;
          .card {
            background-color: $dark-card-background;
            box-shadow: 0 0 1px 1px #374558;
            .card-header {
              background-color: $dark-card-background;
              border-bottom: 1px solid $dark-border-color;
              .buttons-right {
                .right-header-dropdown.onhover-show-div {
                  background-color: $dark-body-background;
                  box-shadow: 0 0 2px 2px $dark-body-background;
                  a {
                    color: $dark-all-font-color;
                  }
                }
              }
              &.card-header-border {
                .right-header {
                  span {
                    &:first-child {
                      color: $dark-small-font-color;
                    }
                  }
                  .right-header-dropdown.onhover-show-div {
                    background-color: $dark-body-background;
                    box-shadow: 0 0 2px 2px $dark-body-background;
                    a {
                      color: $dark-all-font-color;
                    }
                  }
                }
              }
              > span {
                color: $dark-all-font-color;
              }
              h1,
              h2,
              h3,
              h4,
              h5,
              h6 {
                color: $dark-all-font-color;
              }
              .card-header-right {
                i {
                  color: $dark-all-font-color;
                }
              }
            }
            .alert-dark {
              color: $dark-small-font-color;
              a {
                color: $dark-small-font-color;
              }
            }
            .alert-light.outline,
            .alert-light.outline-2x,
            .alert-light.inverse {
              color: $dark-all-font-color;
            }
            #animation-box {
              .animate-widget {
                p {
                  color: $dark-small-font-color !important;
                }
              }
            }
            .grid-item {
              img {
                background-color: $dark-card-background;
                border: 1px solid $dark-border-color;
              }
            }
            .line {
              color: $dark-all-font-color;
            }
            .table {
              th,
              td {
                color: $dark-all-font-color;
              }
              thead {
                th {
                  border-bottom: 2px solid $dark-border-color;
                }
                .border-bottom-primary {
                  th {
                    border-bottom: 1px solid $primary-color;
                  }
                }
              }
              .table-primary,
              .table-secondary,
              .table-success,
              .table-info,
              .table-warning,
              .table-light,
              .table-danger {
                th,
                td {
                  color: $sidebar-submenu-font-color;
                }
              }
              .bg-primary,
              .bg-secondary,
              .bg-success,
              .bg-info,
              .bg-warning,
              .bg-danger {
                color: $white;
              }
              .bg-light {
                color: $black;
              }
              .thead-light {
                th {
                  color: $black;
                }
              }
              .double,
              .dotted,
              .dashed {
                border-color: $dark-border-color;
              }
              tbody {
                .border-bottom-primary {
                  th,
                  td {
                    border-bottom: 1px solid $primary-color;
                  }
                }
              }
            }
            .table-responsive {
              .table {
                th,
                td {
                  border-top: 1px solid $dark-border-color;
                }
              }
            }
            .table[class*="bg-"] {
              th,
              td {
                color: $white;
              }
            }
            .table-striped {
              tbody {
                tr {
                  &:nth-of-type(odd) {
                    background-color: rgba(0, 0, 0, 0.05);
                    &:hover {
                      th,
                      td {
                        color: $white;
                      }
                    }
                  }
                }
              }
            }
            .table-bordered {
              td,
              th {
                border-color: $dark-border-color;
              }
            }
            .table-inverse {
              tbody {
                tr {
                  &:nth-of-type(odd) {
                    &:hover {
                      td {
                        color: $dark-all-font-color;
                      }
                    }
                  }
                }
              }
            }
            .table-border-vertical {
              tr,
              th,
              td {
                border-right: 1px solid $dark-border-color;
              }
            }
            .table-styling {
              thead,
              tbody {
                th,
                td {
                  color: $white;
                }
              }
            }
            .card-footer {
              background-color: $dark-card-background;
              border-top: 1px solid $dark-border-color;
            }
            .bg-white {
              background-color: $dark-card-background !important;
            }
            .b-l-light {
              border-left: 1px solid $dark-border-color !important;
            }
            .ct-grid {
              stroke: $dark-small-font-color;
            }
            .ct-label {
              color: $dark-small-font-color;
            }
            hr {
              border-top: 1px solid $dark-border-color;
            }
            .text-muted {
              color: $sidebar-submenu-font-color !important;
            }
            .calender-widget {
              .cal-date {
                background-color: $dark-card-background;
                border: 1px solid $dark-border-color;
              }
            }
            .contact-form {
              .theme-form {
                border: 1px solid $dark-border-color;
                .form-icon {
                  background-color: $dark-card-background;
                  border: 1px solid $dark-border-color;
                  color: $dark-all-font-color;
                }
              }
            }
            .btn-outline-light,
            .btn-outline-dark {
              color: $white !important;
            }
            .btn-outline-light {
              &:hover {
                color: $black !important;
              }
            }

            .border-right {
              border-right: 1px solid $dark-border-color !important;
            }
            .flot-chart-placeholder {
              .flot-text {
                color: $dark-all-font-color !important;
              }
              svg {
                text {
                  fill: $dark-all-font-color;
                }
              }
            }
            .chart-overflow {
              &#line-chart,
              &#column-chart1,
              &#column-chart2 {
                rect {
                  fill: $dark-card-background;
                }
              }
              svg {
                > rect {
                  fill: $dark-card-background;
                }
                > g {
                  > g {
                    > g {
                      text {
                        fill: rgb(177, 177, 178);
                      }
                    }
                  }
                  > text {
                    fill: $dark-all-font-color;
                  }
                }
              }
            }
            .radial-bar {
              &:after {
                background-color: $dark-card-background;
                color: $dark-all-font-color;
              }
            }
            .bar-chart-widget {
              .bottom-content {
                .num {
                  color: $dark-all-font-color;
                  .color-bottom {
                    color: $dark-all-font-color;
                  }
                }
              }
            }
            .b-r-light {
              border-right: 1px solid $dark-border-color !important;
            }
            .chart-container {
              .live-products,
              .turnover,
              .monthly,
              .uses {
                .ct-labels {
                  .ct-label {
                    color: $white;
                  }
                }
                .ct-grid {
                  stroke: $white;
                }
              }
              #browser-uses-chart,
              #website-visiter-chart {
                svg {
                  text {
                    fill: $dark-all-font-color;
                  }
                }
              }
            }
            .status-details {
              h4 {
                color: $dark-all-font-color;
                span {
                  color: $dark-all-font-color;
                }
              }
              span {
                color: $dark-small-font-color;
              }
            }
            .clipboaard-container {
              .form-control {
                background-color: $dark-body-background;
                color: $dark-small-font-color;
                border: 1px solid $dark-border-color;
              }
            }
            .img-thumbnail {
              background-color: $dark-body-background;
              border: 1px solid $dark-border-color;
            }
            .page-item.disabled {
              .page-link {
                background-color: $dark-card-background;
                border-color: $dark-border-color;
              }
            }
            .page-link {
              background-color: $dark-card-background;
              border: 1px solid $dark-border-color;
              &:hover {
                background-color: $dark-border-color;
              }
            }
            .page-item.active {
              .page-link {
                background-color: $primary-color;
              }
            }
            .ecommerce-widget {
              .icon {
                color: $dark-body-background;
              }
              .total-num {
                color: $dark-all-font-color;
                span {
                  color: $dark-all-font-color;
                }
              }
              span {
                color: $dark-small-font-color;
              }
            }
            .flot-chart-container-small {
              background-color: $dark-card-background;
              border-top: 1px solid $dark-border-color;
            }
            .product-table {
              #API-2_wrapper {
                #API-2 {
                  tbody {
                    td {
                      span,
                      p {
                        color: $dark-small-font-color;
                      }
                    }
                  }
                }
              }
              h6 {
                color: $dark-small-font-color;
              }
            }
            .scroll-demo {
              border: 1px solid $dark-border-color;
            }
            .search-form {
              input {
                border: 1px solid $dark-border-color;
                color: $dark-all-font-color;
                background-color: $dark-body-background;
              }
              .form-group {
                &:before {
                  background: $dark-card-background;
                }
              }
            }
            .cd-timeline-content {
              background-color: $dark-border-color;
              &::before {
                border-left: 7px solid $dark-border-color;
              }
            }
            .cd-timeline-block {
              &:nth-child(even) {
                .cd-timeline-content {
                  &::before {
                    border-right-color: $dark-border-color;
                    border-left: transparent;
                  }
                }
              }
            }
            .breadcrumb {
              background-color: $dark-body-background;
            }
            .user-status {
              table {
                td,
                th {
                  border-top: none !important;
                }
              }
            }
            #donut-color-chart-morris-daily,
            #donut-color-chart-morris,
            #browser-uses-chart,
            #website-visiter-chart {
              svg {
                opacity: 0.5;
              }
            }
          }
          .gallery-with-description {
            h4 {
              color: $dark-all-font-color;
            }
            a {
              > div {
                border: 1px solid $dark-border-color;
              }
            }
            p {
              color: $dark-small-font-color;
            }
          }
          .sub-title {
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          }
          .navs-icon {
            li {
              a {
                color: $dark-all-font-color;
                &:hover {
                  color: $primary-color;
                }
              }
            }
            .separator {
              border: 1px solid $dark-border-color;
            }
          }
          .default-according {
            li {
              .text-muted {
                color: $dark-all-font-color !important;
              }
            }
          }
          .navs-icon.default-according.style-1 {
            li {
              button {
                &:hover {
                  color: $primary-color !important;
                }
              }
            }
          }
          .nav-list {
            .nav-list-disc {
              li {
                a {
                  color: $dark-all-font-color;
                }
                &:hover {
                  color: $primary-color;
                  a {
                    color: $primary-color;
                  }
                }
              }
            }
          }
          .navs-dropdown {
            .onhover-show-div {
              background-color: $dark-body-background;
              box-shadow: 0 0 2px 2px $dark-border-color;
              .navs-icon {
                li {
                  a {
                    svg {
                      path,
                      line,
                      polyline,
                      polygon,
                      rect {
                        color: $dark-all-font-color !important;
                      }
                    }
                    &:hover {
                      svg {
                        path,
                        line,
                        polyline,
                        polygon,
                        rect {
                          color: $primary-color !important;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .onhover-dropdown {
            &:hover {
              .onhover-show-div {
                &:before {
                  border-bottom: 7px solid $dark-body-background;
                }
              }
            }
          }
          .default-according {
            .card {
              box-shadow: 0px 0px 1px 1px rgb(55, 66, 82);
              .btn-link {
                color: $dark-all-font-color;
              }
              .card-body {
                color: $dark-small-font-color;
              }
            }
          }
          .border {
            border: 1px solid $dark-border-color !important;
          }
          .blog-box {
            .blog-date {
              color: $dark-all-font-color;
            }
            .blog-details,
            .blog-details-main {
              .blog-social {
                li {
                  color: $dark-small-font-color;
                  border-right: 1px solid $dark-border-color;
                  &:first-child {
                    border-right: 1px solid $dark-border-color;
                  }
                  &:last-child {
                    border-right: none;
                  }
                }
              }
              p {
                color: $dark-all-font-color;
              }
              .single-blog-content-top {
                border-top: 1px solid $dark-border-color;
                p {
                  color: $dark-small-font-color;
                }
              }
            }
          }
          .comment-box {
            .d-flex {
              h6 {
                span {
                  color: $dark-small-font-color;
                }
              }
              img {
                border: 1px solid $dark-border-color;
              }
            }
            p {
              color: $dark-small-font-color;
            }
            .comment-social {
              li {
                color: $dark-small-font-color;
                &:first-child {
                  border-right: 1px solid $dark-border-color;
                }
              }
            }
            hr {
              border-top: 1px solid $dark-border-color;
            }
          }
          .table-hover {
            tbody {
              tr {
                &:hover {
                  background-color: $dark-body-background;
                  td,
                  th {
                    color: $white;
                  }
                }
              }
            }
          }
          ul.the-icons {
            li {
              border: 1px dotted $dark-border-color;
              color: $sidebar-submenu-font-color;
              &:hover {
                background: $black;
                box-shadow: 0 0 3px $dark-card-background;
              }
            }
          }
          .crm-activity {
            > li {
              + li {
                border-top: 1px solid $dark-border-color;
              }
              h6 {
                color: $dark-all-font-color;
              }
            }
            ul.dates {
              li {
                color: $dark-small-font-color;
              }
            }
          }
          #donut-color-chart-morris,
          #donut-color-chart-morris-daily {
            svg {
              text {
                fill: $dark-all-font-color;
              }
            }
          }
          .custom-card {
            .card-profile {
              img {
                background-color: $dark-card-background;
              }
            }
            .profile-details {
              h6 {
                color: $dark-small-font-color;
              }
            }
            .card-social {
              li {
                a {
                  color: $dark-small-font-color;
                  &:hover {
                    color: $primary-color;
                  }
                }
              }
            }
            .card-footer {
              > div {
                h6 {
                  color: $dark-small-font-color;
                }
                + div {
                  border-left: 1px solid $dark-border-color;
                }
              }
            }
          }
          .form-control {
            background-color: $dark-body-background;
            color: $dark-all-font-color;
            border: 1px solid $dark-border-color !important;
          }
          .select2-drpdwn-project {
            .form-control {
              background-color: $dark-card-background !important;
              border-bottom: 1px solid $primary-color !important;
            }
          }
          .checkbox,
          .radio {
            label {
              &::before {
                background-color: $dark-body-background;
                border: 1px solid $dark-border-color;
                color: $dark-all-font-color;
              }
            }
          }
          .dropdown-basic {
            .dropdown {
              .dropdown-content {
                background-color: $dark-body-background;
                a {
                  color: $dark-all-font-color;
                  &:hover {
                    background-color: $dark-card-background;
                  }
                }
                .dropdown-divider {
                  border: 1px solid $dark-border-color;
                }
              }
            }
          }
          .dropup-basic {
            .dropup {
              .dropup-content {
                background-color: $dark-body-background;
                a {
                  color: $dark-all-font-color;
                  &:hover,
                  &.active {
                    background-color: $dark-card-background;
                  }
                }
              }
            }
          }
          .dropzone.dz-clickable {
            .dz-message {
              h6 {
                color: $dark-all-font-color;
              }
              span {
                color: $dark-small-font-color;
              }
            }
          }
          .dropzone {
            .dz-preview {
              background-color: $dark-body-background;
              .dz-details {
                background-color: $dark-card-background;
              }
            }
          }
          .browser-widget {
            .flex-grow-1 {
              column-rule: 1px solid $dark-border-color;
              h4 {
                color: $dark-all-font-color;
                span {
                  color: $dark-all-font-color;
                }
              }
              span {
                color: $dark-small-font-color;
              }
            }
          }
          .email-wrap {
            .email-app-sidebar {
              .d-flex {
                img {
                  border: 2px solid $dark-body-background;
                }
              }
              .main-menu {
                > li {
                  a {
                    color: $dark-all-font-color;
                    &:hover {
                      background-color: $dark-body-background;
                    }
                  }
                }
              }
            }
            .email-right-aside {
              .email-body {
                .inbox {
                  .d-flex.active {
                    background-color: $dark-body-background;
                  }
                }
              }
            }
            .flex-grow-1 {
              h6 {
                color: $dark-all-font-color;
                small {
                  color: $dark-small-font-color;
                  span {
                    color: $dark-small-font-color;
                  }
                }
              }
              p {
                color: $dark-small-font-color;
              }
            }
            .email-top {
              border-bottom: 1px solid $dark-border-color;
            }
            p {
              color: $dark-small-font-color;
            }
            .email-content {
              .email-top {
                .user-emailid:after {
                  border: 1px solid $dark-border-color;
                }
              }
            }
          }
          .dropdown-menu {
            background-color: $dark-body-background;
            border: 1px solid $dark-border-color;
            a {
              color: $dark-all-font-color;
              &:hover {
                background-color: $dark-card-background;
              }
            }
          }
          .icon-lists {
            div {
              i {
                color: $dark-small-font-color;
              }
              &:hover {
                background-color: $dark-body-background;
              }
            }
          }
          .widget-joins {
            .d-flex {
              .flex-grow-1 {
                > span {
                  color: $dark-small-font-color;
                }
              }
              .details {
                border-left: 1px solid $dark-border-color;
              }
            }
            &:before,
            &:after {
              background-color: $dark-border-color;
            }
          }
          .redial-social-widget {
            i {
              background-color: $dark-card-background;
            }
          }
          .social-widget-card {
            h5,
            h4 {
              color: $dark-all-font-color;
            }
            span {
              color: $dark-small-font-color;
            }
          }
          .b-b-light {
            border-bottom: 1px solid $dark-border-color !important;
          }
          .b-r-dark {
            border-right: 1px solid $white !important;
          }
          .testimonial {
            i {
              color: $dark-border-color;
            }
            p {
              color: $dark-all-font-color;
            }
            h5 {
              color: $dark-all-font-color;
            }
            span {
              color: $dark-small-font-color;
            }
          }
          .grid-showcase {
            span {
              background-color: $dark-card-background;
              border: 1px solid $dark-border-color;
            }
          }
          .grid-align {
            .row {
              background-color: $dark-border-color;
              border: 1px solid $dark-border-color;
            }
          }
          .border-style {
            .card {
              border: 1px solid $dark-border-color;
            }
          }
          .offer-style {
            .card {
              border: 1px dashed $dark-border-color;
            }
          }
          .search-page {
            .info-block {
              + .info-block {
                border-top: 1px solid $dark-border-color;
              }
            }
          }
          .card-absolute {
            .bg-primary,
            .bg-secondary {
              h5 {
                color: $white;
              }
            }
          }
          .timeline-small {
            .d-flex {
              .timeline-round {
                &.timeline-line-1,
                &.small-line,
                &.medium-line {
                  &:after {
                    background-color: $dark-border-color;
                  }
                }
              }
            }
          }
          /* body end*/
        }
        footer {
          border-top: 1px solid $dark-body-background;
          background-color: $dark-card-background;
        }
        .custom-select,
        .custom-file-label {
          background: $dark-body-background;
          color: $dark-all-font-color;
        }
        .footer-fix {
          background-color: $dark-card-background;
          border-top: 1px solid $dark-border-color;
        }
      }
      .chat-box {
        .about {
          .name {
            color: $dark-all-font-color;
          }
        }
        .chat-menu {
          border-left: 1px solid $dark-border-color;
          .nav-tabs {
            border-bottom: 1px solid $dark-border-color;
            .nav-item {
              .nav-link {
                &.active {
                  color: $dark-all-font-color !important;
                }
              }
            }
          }

          .user-profile {
            .image {
              .icon-wrapper {
                background-color: $dark-card-background;
                box-shadow: 1px 1px 3px 1px $dark-body-background;
              }
              .avatar {
                img {
                  border: 5px solid $dark-border-color;
                }
              }
            }
            .social-media {
              a {
                color: $dark-small-font-color;
              }
            }
            .follow {
              span {
                color: $dark-small-font-color;
              }
              .follow-num {
                color: $dark-all-font-color;
              }
            }
          }
        }
        .status {
          color: $dark-small-font-color;
          p {
            color: $dark-all-font-color !important;
          }
        }
        .chat-right-aside {
          .chat {
            .chat-header {
              border-bottom: 1px solid $dark-border-color;
              .chat-menu-icons {
                li {
                  a {
                    i {
                      color: $dark-small-font-color;
                    }
                  }
                }
              }
              img {
                box-shadow: 1px 1px 4px 1px $dark-body-background;
              }
            }
            .chat-msg-box {
              .my-message {
                border: 1px solid $dark-border-color;
              }
              .message {
                color: $dark-all-font-color;
              }
              .other-message {
                background-color: $dark-body-background;
              }
            }
            .chat-message {
              background-color: $dark-card-background;
              border-top: 1px solid $dark-border-color;
              .input-group {
                .form-control {
                  background-color: $dark-card-background;
                }
              }
            }
          }
        }
        .chat-history {
          .call-icons {
            ul {
              li {
                border: 1px solid $dark-border-color;
                a {
                  color: $dark-small-font-color;
                }
              }
            }
          }
        }
        .bitcoin-chat {
          .chat {
            .chat-msg-box {
              .message {
                &.my-message {
                  background-color: #1c222b;
                  .chat-user-img {
                    border: 2px solid $dark-body-background;
                  }
                }
                &.other-message {
                  .chat-user-img {
                    border: 2px solid $dark-body-background;
                  }
                }
              }
            }
          }
        }
      }
      pre {
        background-color: $dark-border-color;
        color: $dark-all-font-color;
      }
      .scorlled {
        background-color: $dark-body-background;
      }
      .input-group-air {
        box-shadow: 0 3px 20px 0 $dark-border-color;
      }
      .input-group-solid {
        .input-group-text,
        .form-control {
          background: $dark-body-background;
          border: 1px solid $dark-border-color;
          color: $dark-all-font-color;
        }
      }
      .semilight-bg-color,
      .header-bg-color {
        background-color: $white;
      }
      .list-group-item {
        background-color: $dark-card-background;
        border: 1px solid rgba(255, 255, 255, 0.3);
        color: $dark-all-font-color;
        &.active {
          background-color: $primary-color;
          border-color: $primary-color;
          color: $white;
        }
        &:hover,
        &:focus {
          z-index: 1;
          text-decoration: none;
        }
      }
      .list-group-item-action {
        &:hover:not(.active),
        &:focus {
          background-color: $dark-body-background;
        }
      }
      .list-group-flush {
        .list-group-item {
          border-left: 0;
          border-right: 0;
          border-radius: 0;
        }
      }
      .list-group-item-primary {
        color: #004085;
        background-color: #b8daff;
      }
      .list-group-item-secondary {
        color: #383d41;
        background-color: #d6d8db;
      }
      .list-group-item-success {
        color: #155724;
        background-color: #c3e6cb;
      }
      .list-group-item-danger {
        color: #721c24;
        background-color: #f5c6cb;
      }
      .list-group-item-warning {
        color: #856404;
        background-color: #ffeeba;
      }
      .list-group-item-info {
        color: #0c5460;
        background-color: #bee5eb;
      }
      .list-group-item-light {
        color: #818182;
        background-color: #fdfdfe;
      }
      .list-group-item-dark {
        color: #1b1e21;
        background-color: #c6c8ca;
      }
      .auth-bg {
        background-blend-mode: overlay;
        background-color: rgba(54, 64, 74, 0.9);
        .authentication-box {
          .card {
            background-color: $dark-card-background;
            .theme-form {
              .form-group {
                input {
                  color: $dark-all-font-color;
                  background-color: $dark-body-background;
                  border: 1px solid $dark-border-color;
                }
                label {
                  color: $dark-all-font-color;
                }
              }
              .checkbox {
                label {
                  color: $dark-all-font-color;
                  &::before {
                    background-color: $dark-body-background;
                    border: 1px solid $dark-border-color;
                  }
                }
              }
            }
          }
          h3,
          h4,
          h6 {
            color: $white;
          }
        }
      }
      .auth-bg-video {
        background-blend-mode: overlay;
        background-color: rgba(54, 64, 74, 0.7);
        .authentication-box {
          h4,
          h3 {
            color: $white;
          }
          h6 {
            color: $dark-small-font-color;
          }
          .card {
            background-color: $dark-card-background;
            .theme-form {
              .form-group {
                input[type="text"],
                input[type="password"] {
                  background-color: $dark-body-background;
                  border: 1px solid $dark-border-color;
                  color: $dark-all-font-color;
                }
              }
              .checkbox {
                label {
                  &::before {
                    background-color: $dark-body-background;
                    border: 1px solid $dark-border-color;
                  }
                }
              }
            }
          }
        }
      }
      .error-wrapper {
        .maintenance-icons {
          li {
            i {
              color: $dark-border-color;
            }
            &:nth-child(3) {
              i {
                color: $dark-border-color;
              }
            }
          }
        }
      }
      .modal-content {
        background-color: $dark-card-background;
        .modal-header {
          border-bottom: 1px solid $dark-border-color;
          .close {
            color: $dark-small-font-color;
          }
        }
        .modal-footer {
          border-top: 1px solid $dark-border-color;
        }
      }
      .comingsoon {
        background-blend-mode: overlay;
        background-color: rgba(54, 64, 74, 0.9);
        .comingsoon-inner {
          h5 {
            color: $dark-all-font-color;
          }
          .countdown {
            border-top: 1px solid $dark-border-color;
            border-bottom: 1px solid $dark-border-color;
            .title {
              color: $dark-all-font-color;
            }
          }
          .coming-soon-bottom-link {
            a {
              color: $dark-all-font-color;
            }
          }
        }
      }
      .theme-form {
        .login-divider {
          border-top: 1px solid $dark-border-color;
          &:before {
            background: $dark-border-color;
            color: $dark-all-font-color;
          }
        }
      }
      .authentication-main {
        background-color: $dark-border-color;
        .auth-innerright {
          .reset-password-box {
            .card {
              background-color: $dark-card-background;
            }
            .reset-password-link {
              color: $dark-small-font-color;
            }
            .theme-form {
              .form-group {
                label {
                  color: $dark-small-font-color;
                }
                .form-control {
                  background-color: $dark-body-background;
                  color: $dark-all-font-color;
                  border-color: $dark-border-color;
                }
              }
              .opt-box {
                background-color: $dark-border-color;
              }
            }
          }
          .authentication-box {
            h4 {
              color: $dark-all-font-color;
            }
            h6 {
              color: $dark-small-font-color;
            }
            h3 {
              color: $dark-all-font-color;
            }
            .card {
              background-color: $dark-card-background;
              .theme-form {
                .form-group {
                  input[type="text"],
                  input[type="password"] {
                    background-color: $dark-body-background;
                    border: 1px solid $dark-border-color;
                    color: $dark-all-font-color;
                  }
                }
                .checkbox {
                  label {
                    &::before {
                      background-color: $dark-body-background;
                      border: 1px solid $dark-border-color;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .vertical-menu-main {
        background-color: $dark-card-background;
      }
      .pixelstrap {
        a {
          color: $dark-all-font-color;
          &:focus,
          &:active,
          &:hover {
            color: $dark-all-font-color;
          }
        }
        > {
          li {
            > a.highlighted {
              &:before {
                background-color: $dark-card-background;
              }
              &:after {
                background-color: $dark-border-color;
              }
            }
          }
        }
      }
      @media (min-width: 1200px) {
        .pixelstrap {
          ul {
            background: $dark-body-background;
            border: 1px solid $dark-border-color;
            a {
              &:hover,
              &:focus,
              &:active,
              &.highlighted {
                color: $primary-color;
              }
              &.current {
                background-color: $dark-body-background;
                color: $primary-color;
              }
            }
          }
        }
      }
      .mega-menu {
        .title {
          color: $dark-all-font-color;
          border-bottom: 1px solid $dark-border-color;
        }
        .galleria-list {
          .galleria {
            > div {
              .username {
                color: $dark-all-font-color;
                small {
                  color: $dark-small-font-color;
                }
              }
            }
          }
        }
        .list-unstyled {
          div {
            a {
              &:hover {
                color: $primary-color;
              }
            }
          }
        }
      }
      .default-according {
        .card {
          background-color: $dark-card-background;
          .btn-link {
            background-color: $dark-card-background;
            border: 1px solid $dark-card-background;
            color: $white;
          }
          .text-muted {
            color: $dark-small-font-color !important;
          }
        }
        .bg-primary {
          .btn-link {
            background-color: $primary-color;
            border: 1px solid $primary-color;
          }
        }
        .bg-secondary {
          .btn-link {
            background-color: $secondary-color;
            border: 1px solid $secondary-color;
          }
        }
      }
      .collapse {
        .card-body {
          background-color: $dark-card-background;
        }
      }
      @media screen and (max-width: 1660px) {
        .chat-box {
          .chat-history {
            .call-icons {
              ul {
                li {
                  border: 1px solid $dark-border-color;
                }
              }
            }
            .total-time {
              h2 {
                color: $dark-all-font-color;
              }
            }
          }
        }
        .caller-img {
          img {
            opacity: 0.7;
          }
        }
        .chat-box {
          .chat-history {
            .call-content {
              > div {
                background-color: rgba(0, 0, 0, 0.75);
                background-blend-mode: overlay;
              }
            }
          }
        }
      }
      @media only screen and (max-width: 1199px) {
        .sm {
          background-color: $dark-card-background;
          border: 1px solid $dark-border-color;
        }
        .pixelstrap {
          .mobile-back {
            border-bottom: 1px solid $dark-border-color;
          }
          ul {
            li {
              border-top: 1px solid $dark-border-color;
            }
            a {
              background: $dark-card-background;
              &:hover,
              &:focus,
              &:active {
                background: $dark-card-background;
              }
            }
          }
        }
        .chat-menu {
          border-top: 1px solid $dark-border-color;
          background-color: $dark-card-background;
        }
        .error-wrapper {
          .maintenance-icons {
            li {
              i {
                color: $dark-color;
              }
            }
          }
        }
      }
      .pixelstrap {
        ul {
          background: $dark-card-background;
        }
      }
      @media only screen and (max-width: 575px) {
        .user-profile {
          .hovercard {
            .info {
              .user-designation {
                border-top: 1px solid $dark-border-color;
                border-bottom: 1px solid $dark-border-color;
              }
            }
          }
        }
        .widget-joins {
          .border-after-xs {
            &:after {
              background-color: $dark-border-color;
            }
          }
        }
      }
    }
    .lg-backdrop {
      background-color: $dark-body-background;
    }
    .lg-outer {
      .lg-thumb-outer {
        background-color: $dark-card-background;
      }
    }
    .theme-form {
      .form-group {
        select.form-control:not([size]):not([multiple]) {
          background-color: $dark-body-background;
          color: $dark-all-font-color;
          border-color: $dark-border-color;
        }
        input[type="text"],
        input[type="email"],
        input[type="search"],
        input[type="password"],
        input[type="number"],
        input[type="tel"],
        input[type="date"],
        input[type="datetime-local"],
        input[type="time"],
        input[type="datetime-local"],
        input[type="month"],
        input[type="week"],
        input[type="url"],
        input[type="file"],
        select {
          border-color: $dark-border-color;
          background-color: $dark-body-background;
          color: $dark-all-font-color;
          &::-webkit-input-placeholder {
            color: $dark-small-font-color;
          }
        }
        .form-control {
          background-color: $dark-body-background;
          color: $dark-all-font-color;
        }
        textarea {
          border-color: $dark-border-color;
        }
      }
      .form-divider {
        border-top: 1px solid $dark-border-color;
        &::before {
          background: $dark-card-background;
          color: $dark-all-font-color;
        }
      }
    }
    .CodeMirror {
      background: $dark-card-background;
      border: 1px solid $dark-small-font-color;
      color: $dark-all-font-color;
      .CodeMirror-code {
        pre {
          background: $dark-card-background;
        }
      }
    }
    .editor-toolbar {
      border-top: 1px solid $dark-small-font-color;
      border-left: 1px solid $dark-small-font-color;
      border-right: 1px solid $dark-small-font-color;
      a {
        color: $dark-all-font-color !important;
        &:hover,
        &.active {
          background: $dark-border-color;
        }
      }
      i.separator {
        border-left: 1px solid $dark-small-font-color;
        border-right: 1px solid $dark-small-font-color;
      }
    }
    .editor-toolbar.disabled-for-preview {
      a {
        &:not(.no-disable) {
          background: $dark-border-color;
        }
      }
    }
    .editor-preview {
      background-color: $dark-card-background;
    }
    .editor-toolbar.fullscreen,
    .editor-preview-side {
      background: $dark-card-background;
      border: 1px solid $dark-small-font-color;
    }
    .u-step {
      background: $dark-border-color;
      &.active,
      &.current {
        background: $primary-color;
        color: $white;
      }
    }
    .u-step-title,
    .u-pearl-title {
      color: $dark-all-font-color;
    }
    .u-step-number {
      background-color: $dark-card-background;
    }
    .u-pearl {
      &:before {
        background-color: $dark-border-color;
      }
    }
    .u-pearl-number,
    .u-pearl-icon {
      background: $dark-body-background;
      border: 2px solid $dark-body-background;
    }
    .u-pearl.disabled {
      .u-pearl-icon,
      .u-pearl-number {
        background: $dark-border-color;
        border: 2px solid $dark-border-color;
      }
      &:after {
        background-color: #334053;
      }
    }
    .u-pearl.error {
      &:after {
        background-color: $dark-body-background;
      }
    }
    .note-editor.note-frame {
      border-color: $dark-border-color;
      .note-editing-area {
        .note-editable {
          background-color: $dark-card-background;
          color: $dark-all-font-color;
        }
      }
    }
    .swal-modal {
      background-color: $dark-card-background;
      .swal-title {
        color: $dark-all-font-color;
      }
      .swal-text {
        color: $dark-small-font-color;
      }
      .swal-content__input {
        background-color: $dark-body-background;
        color: $dark-all-font-color;
      }
    }
    .nav-tabs {
      border-bottom: 1px solid $dark-border-color;
      .nav-link {
        &.active {
          background-color: $dark-card-background;
          color: $dark-all-font-color;
          border-color: $dark-border-color $dark-border-color
            $dark-card-background;
        }
        &:hover,
        &:focus {
          border-color: $dark-border-color $dark-border-color $dark-border-color;
        }
      }
      .nav-item.show {
        .nav-link {
          background-color: $dark-card-background;
          color: $dark-all-font-color;
          border-color: $dark-border-color $dark-border-color
            $dark-card-background;
        }
      }
    }
    .nav-tabs.nav-bottom {
      .nav-item {
        .nav-link {
          &.active,
          &.focus,
          &.hover {
            border-color: $dark-card-background $dark-border-color
              $dark-border-color;
          }
        }
      }
    }
    .dropdown-divider {
      border-top: 1px solid $dark-border-color;
    }
    .icon-hover-bottom {
      background-color: $dark-card-background;
      box-shadow: 0px 0px 1px 1px $dark-border-color;
      .icon-title {
        color: $dark-all-font-color;
      }
      span {
        color: $dark-small-font-color;
      }
      .form-group {
        input {
          background-color: $dark-body-background;
          color: $dark-all-font-color;
          border: 1px solid $dark-border-color;
        }
      }
      .icon-first {
        i {
          color: $dark-small-font-color;
        }
      }
    }
    code {
      background-color: #d8ecff;
      border-radius: 2px;
    }
    #cd-timeline {
      &::before {
        background: $dark-border-color;
      }
    }
    .timeliny {
      border-top: 1px solid $dark-border-color;
      border-bottom: 1px solid $dark-border-color;
      &::before {
        background-color: $white;
      }
      .timeliny-dot {
        background-color: $dark-card-background;
        border: 1px solid $white;
        &::before {
          color: $dark-all-font-color;
        }
      }
      .timeliny-timeline {
        .timeliny-timeblock {
          &.active {
            .timeliny-dot {
              &::before {
                color: $dark-all-font-color;
              }
            }
          }
          &:not(.inactive):not(.active) {
            .timeliny-dot {
              &:hover {
                background-color: $white;
                &::before {
                  color: $dark-all-font-color;
                }
              }
            }
          }
          .timeliny-dot {
            &:hover {
              &::after {
                background-color: $dark-card-background;
                border: 1px solid $dark-border-color;
                color: $dark-all-font-color;
              }
            }
          }
        }
      }
    }
    .todo {
      .todo-list-wrapper {
        #todo-list {
          li {
            .task-container {
              background: $dark-card-background;
              border-bottom: 1px solid $dark-border-color;
              .task-label {
                color: $dark-all-font-color;
              }
              &:hover {
                h4 {
                  color: $white;
                }
              }
              .task-action-btn {
                .action-box {
                  &:hover:not(.active) {
                    background: $dark-card-background;
                    border: 1px solid $dark-border-color;
                  }
                }
              }
            }
            &.completed {
              .task-container {
                .task-label {
                  color: $primary-color;
                }
                .complete-btn {
                  &:hover {
                    border: 1px solid $success-color !important;
                  }
                }
              }
            }
          }
        }
        .todo-list-footer {
          .new-task-wrapper {
            textarea {
              background-color: $dark-card-background;
              border: 1px solid $dark-border-color;
              color: $dark-all-font-color;
            }
          }
        }
      }
    }
    .user-profile {
      .ttl-info {
        h6 {
          color: $dark-small-font-color;
        }
        span {
          color: $dark-all-font-color;
        }
      }
      .hovercard {
        .info {
          .title {
            a {
              color: $dark-all-font-color;
            }
          }
        }
        .user-image {
          .avatar {
            img {
              border: 10px solid $dark-card-background;
            }
          }
          .icon-wrapper {
            background-color: $dark-card-background;
          }
        }
        .tabs-scoial {
          border-bottom: none !important;
        }
      }
      .follow {
        .follow-num {
          color: $dark-all-font-color;
        }
      }
      .profile-img-style {
        .user-name {
          color: $dark-all-font-color;
        }
        p {
          color: $dark-small-font-color;
        }
      }
    }
    span.twitter-typeahead {
      .tt-menu {
        background-color: $dark-body-background;
        color: $dark-all-font-color;
      }
      .tt-suggestion {
        color: $dark-all-font-color;
        &:hover,
        &:focus {
          background-color: $dark-card-background;
        }
      }
    }
    .typography {
      small {
        color: $dark-all-font-color;
      }
    }
    .blockquote-footer {
      color: $dark-small-font-color;
    }
    .code-box-copy {
      pre {
        background-color: $dark-body-background;
        code {
          background-color: $dark-body-background;
        }
      }
      pre[class*="language-"] {
        border: 1px solid $dark-border-color;
      }
      .code-box-copy__btn {
        background-color: $dark-body-background;
        border: 1px solid $dark-border-color;
        color: $white;
      }
      code[class*="language-"],
      pre[class*="language-"] {
        text-shadow: 0px 1px $black;
        ::selection {
          text-shadow: none;
          background: $dark-card-background;
        }
      }
    }
    table.fixedHeader-floating {
      background-color: $dark-body-background;
    }
    .error-wrapper {
      background-color: rgba(54, 64, 74, 0.8);
      .sub-content {
        color: $dark-all-font-color;
      }
    }
    .b-light {
      border: 1px solid $dark-border-color !important;
    }
    .modal-content {
      background-color: $dark-card-background;
      .modal-header {
        border-bottom: 1px solid $dark-border-color;
      }
    }
    .modal {
      .theme-close {
        background-color: $dark-card-background !important;
      }
    }
    .loader-box {
      &.card-loader {
        background-color: $dark-card-background;
      }
    }
    .my-gallery {
      &.gallery-with-description {
        img {
          border: 1px solid $dark-border-color !important;
          border-bottom: none !important;
        }
      }
    }
    @each $var in $alert-name {
      $i: index($alert-name, $var);
      .alert-#{$var}.inverse {
        color: $dark-all-font-color;
      }
    }
    .alert-theme {
      span {
        + span {
          + span {
            border: 1px solid $primary-color;
            background-color: $dark-body-background;
            border-radius: 4px;
          }
        }
      }
      i {
        background-color: $primary-color;
        color: $white;
        padding: 20px;
        margin-right: 20px !important;
      }
    }
    .user-card {
      .user-deatils {
        h6 {
          color: $dark-small-font-color;
        }
      }
      .card-footer {
        > div {
          &:first-child {
            border-right: 1px solid $dark-border-color;
          }
        }
        .user-footer {
          h6 {
            color: $dark-small-font-color;
          }
          svg {
            path,
            rect {
              color: $dark-small-font-color;
            }
          }
        }
      }
    }
    .dashboard-chat {
      .chat {
        .d-flex {
          .flex-grow-1 {
            .message-main {
              p {
                background-color: $dark-body-background;
              }
              &.smiley-bg {
                background-color: $dark-body-background;
              }
            }
          }
        }
        .right-side-chat {
          .flex-grow-1 {
            p {
              &:before {
                border-left: 7px solid $dark-body-background;
              }
            }
          }
        }
        .left-side-chat {
          .flex-grow-1 {
            p {
              &:before {
                border-right: 7px solid $dark-body-background;
              }
            }
          }
        }
      }
    }
    .selling-update {
      border: 2px solid $dark-border-color;
    }
    .invest-dropdown {
      .right-header-dropdown {
        &.onhover-show-div {
          background-color: $dark-body-background;
          box-shadow: 0 0 2px 2px $dark-body-background;
          a {
            color: $dark-all-font-color;
          }
        }
      }
    }
    .server-card-bg {
      background-color: #181f27 !important;
    }
    .server-widgets {
      .bottom-server {
        h5 {
          .second-color {
            color: $dark-all-font-color;
          }
        }
      }
    }
    .tabs-project {
      .nav-item {
        .nav-link {
          color: $dark-all-font-color;
        }
      }
    }
    .current-progress,
    .order-history {
      table {
        tr {
          td,
          th {
            border-top: none !important;
          }
        }
      }
      .title-orders {
        background-color: $dark-body-background;
      }
    }
    .navigation-option {
      ul {
        li {
          a {
            color: $dark-all-font-color;
          }
          &:hover {
            background-color: $dark-body-background;
          }
        }
      }
    }
    .product-box {
      .product-details {
        h6 {
          color: $dark-all-font-color;
        }
        span {
          color: $dark-small-font-color;
        }
      }
      .product-img {
        .product-hover {
          ul {
            li {
              background-color: $dark-card-background;
              &:hover {
                background-color: $black;
                color: $white;
              }
            }
          }
        }
      }
    }
    .browse {
      .browse-articles {
        ul {
          li {
            a {
              color: $dark-all-font-color;
            }
            &:hover {
              background-color: $dark-body-background;
            }
          }
        }
      }
    }
    @each $btn-name, $btn-color in (primary, $primary-color),
      (secondary, $secondary-color), (success, $success-color),
      (danger, $danger-color), (info, $info-color), (light, $light-color),
      (dark, $dark-color), (warning, $warning-color)
    {
      .radio-#{$btn-name} {
        input[type="radio"] {
          & + label {
            &::before {
              border-color: $btn-color !important;
            }
            &::after {
              background-color: $btn-color;
            }
          }
          &:checked {
            & + label {
              &::before {
                border-color: $btn-color !important;
              }
              &::after {
                background-color: $btn-color;
              }
            }
          }
        }
      }
    }

    @each $btn-name, $btn-color in (primary, $primary-color),
      (secondary, $secondary-color), (success, $success-color),
      (danger, $danger-color), (info, $info-color), (light, $light-color),
      (dark, $dark-color), (warning, $warning-color)
    {
      .checkbox-#{$btn-name} {
        label {
          &::before {
            border-color: $btn-color !important;
          }
        }
      }
    }

    @each $btn-name, $btn-color in (primary, $primary-color),
      (secondary, $secondary-color), (success, $success-color),
      (danger, $danger-color), (info, $info-color), (light, $light-color),
      (dark, $dark-color), (warning, $warning-color)
    {
      .checkbox-solid-#{$btn-name} {
        label {
          &:before {
            background-color: $btn-color !important;
            border-color: $btn-color !important;
            color: $white !important;
          }
        }
        input[type="checkbox"] {
          &:checked {
            & + label {
              &::before {
                background-color: $btn-color !important;
                border-color: $btn-color !important;
                color: $white !important;
              }
              &::after {
                color: $white !important;
              }
            }
          }
        }
      }
    }
    .bootstrap-datetimepicker-widget {
      ul {
        li {
          color: $dark-all-font-color;
          border-bottom: 1px solid $dark-border-color;
        }
      }
      tr {
        th,
        td {
          &:hover {
            background-color: $dark-card-background;
          }
          span {
            &:hover {
              background-color: $dark-card-background;
            }
          }
          &.prev,
          &.next {
            &:hover {
              background-color: $dark-card-background;
            }
          }
        }
        &:first-child {
          th {
            &:hover {
              background-color: $dark-card-background;
            }
          }
        }
      }
    }
    .btn-transparent {
      color: $dark-all-font-color;
    }
    .categories {
      ul {
        li {
          a {
            color: $dark-small-font-color;
          }
          &:hover {
            background-color: $dark-body-background;
          }
        }
      }
    }
    .main {
      .langChoice {
        background: $dark-body-background;
        color: $white;
        border: 1px solid $dark-border-color;
      }
    }
    .socialprofile {
      .likes-profile {
        h5 {
          span {
            color: $dark-all-font-color;
          }
        }
      }
      span {
        color: $dark-small-font-color;
      }
    }
    .social-status {
      .d-flex {
        .flex-grow-1 {
          span {
            + span {
              color: $dark-small-font-color;
            }
          }
          p,
          .light-span {
            color: $dark-small-font-color;
          }
        }
      }
      form {
        .form-group {
          .form-control-plaintext {
            border: 1px solid $dark-border-color;
          }
        }
      }
    }
    .filter-cards-view,
    .timeline-content {
      p {
        color: $dark-small-font-color;
      }
      .comment-number {
        i {
          color: $dark-small-font-color;
        }
      }
      .comments-box {
        .input-group {
          .form-control {
            border: none !important;
          }
          .input-group-append {
            background-color: $dark-body-background;
          }
        }
      }
    }
    .social-chat {
      .flex-grow-1 {
        border: 1px solid $dark-border-color;
        &:after {
          border-right: 7px solid $dark-card-background;
        }
      }
      span {
        span {
          color: $dark-small-font-color;
        }
      }
    }
    .details-about {
      .your-details {
        p {
          color: $dark-small-font-color;
        }
      }
    }
    .activity-log {
      .my-activity {
        p {
          color: $dark-small-font-color;
        }
      }
    }
    &.rtl {
      .page-main-header {
        .main-header-right {
          .nav-right {
            > ul {
              > li {
                border-right: none;
                &:nth-child(2) {
                  border-right: 1px solid $dark-border-color;
                }
                &:nth-child(5) {
                  border-right: none;
                  border-left: 1px solid $dark-border-color;
                }
                &:nth-child(4) {
                  border-left: none;
                }
              }
            }
          }
        }
      }
    }
    .bookmark {
      ul {
        li {
          a {
            color: $dark-all-font-color;
          }
        }
      }
    }
  }
  &.dark-body-only {
    .page-wrapper {
      .page-body-wrapper {
        .page-sidebar {
          background-color: $white;
          .sidebar-user {
            h6 {
              color: $primary-color;
            }
            p {
              color: $theme-body-font-color;
            }
          }
          .sidebar-menu {
            > li {
              > a {
                color: $theme-body-font-color;
                &:hover {
                  color: $primary-color;
                }
              }
            }
            .sidebar-submenu {
              > li {
                > a {
                  color: $theme-body-font-color;
                  &:hover,
                  &.active {
                    color: $primary-color;
                  }
                }
                &.active {
                  > a {
                    &.active {
                      color: $primary-color;
                    }
                  }
                }
              }
            }
          }
        }
        .page-body {
          color: $dark-all-font-color;
          background-color: $dark-body-background;
          .topper-lists {
            table {
              tbody {
                tr {
                  td {
                    p {
                      color: $dark-small-font-color;
                    }
                  }
                }
              }
            }
          }
          .header-small {
            color: $dark-small-font-color;
          }
          .statistics {
            p {
              color: $dark-small-font-color;
            }
          }
          .feather-main,
          .professor-table {
            .flex-grow-1,
            .professor-block {
              p {
                color: $dark-small-font-color;
              }
            }
          }
          .logs-element {
            span {
              + span {
                color: $dark-small-font-color;
              }
            }
          }
          .progress-block {
            .progress-title {
              span {
                + span {
                  color: $dark-small-font-color;
                }
              }
            }
          }
          .ecommerce-icons {
            div {
              span {
                color: $dark-small-font-color;
              }
            }
          }
          .new-users,
          .recent-notification {
            .d-flex {
              .flex-grow-1 {
                p {
                  color: $dark-small-font-color;
                }
              }
            }
          }
          .progress-media {
            .d-flex {
              .flex-grow-1 {
                span {
                  color: $dark-small-font-color;
                }
              }
            }
            .progress-change {
              span {
                color: $dark-small-font-color;
              }
            }
          }
          .topper-lists {
            table {
              tbody {
                tr {
                  td {
                    border-top: none !important;
                    border-bottom: 1px solid $dark-border-color;
                  }
                  &:last-child {
                    td {
                      border-bottom: none;
                    }
                  }
                }
              }
            }
          }
          .notifiaction-media {
            .d-flex {
              .flex-grow-1 {
                .circle-left {
                  border: 5px solid #334154;
                }
              }
            }
          }
          .upcoming-event {
            .upcoming-innner {
              border-bottom: 1px solid $dark-border-color;
              &:last-child {
                border-bottom: none;
              }
            }
          }
          .professor-table {
            table {
              tbody {
                tr {
                  td {
                    border-top: none !important;
                  }
                }
              }
            }
          }
          .number-widgets {
            .d-flex {
              .flex-grow-1 {
                h6 {
                  color: $dark-all-font-color;
                }
              }
            }
          }
          .activity {
            .d-flex {
              .gradient-round {
                &.gradient-line-1,
                &.small-line,
                &.medium-line {
                  &:after {
                    background-color: $dark-border-color;
                  }
                }
              }
              .flex-grow-1 {
                h6 {
                  span {
                    color: $dark-small-font-color;
                  }
                }
              }
            }
          }
          .card.custom-card {
            .card-header {
              border-bottom: none !important;
            }
          }
        }
        .custom-card .card-header img {
          background-color: $black;
          opacity: 0.8;
        }
        .page-header {
          .row {
            h3 {
              small {
                color: $dark-small-font-color;
              }
            }
          }
          .breadcrumb-item {
            &.active {
              color: $dark-small-font-color;
            }
          }
        }
        .page-body {
          background-color: $dark-body-background;
          .card {
            background-color: $dark-card-background;
            box-shadow: 0 0 1px 1px #374558;
            .card-header {
              background-color: $dark-card-background;
              border-bottom: 1px solid $dark-border-color;
              .buttons-right {
                .right-header-dropdown.onhover-show-div {
                  background-color: $dark-body-background;
                  box-shadow: 0 0 2px 2px $dark-body-background;
                  a {
                    color: $dark-all-font-color;
                  }
                }
              }
              &.card-header-border {
                .right-header {
                  span {
                    &:first-child {
                      color: $dark-small-font-color;
                    }
                  }
                  .right-header-dropdown.onhover-show-div {
                    background-color: $dark-body-background;
                    box-shadow: 0 0 2px 2px $dark-body-background;
                    a {
                      color: $dark-all-font-color;
                    }
                  }
                }
              }
              > span {
                color: $dark-all-font-color;
              }
              h1,
              h2,
              h3,
              h4,
              h5,
              h6 {
                color: $dark-all-font-color;
              }
              .card-header-right {
                i {
                  color: $dark-all-font-color;
                }
              }
            }
            .alert-dark {
              color: $dark-small-font-color;
              a {
                color: $dark-small-font-color;
              }
            }
            .alert-light.outline,
            .alert-light.outline-2x,
            .alert-light.inverse {
              color: $dark-all-font-color;
            }
            #animation-box {
              .animate-widget {
                p {
                  color: $dark-small-font-color !important;
                }
              }
            }
            .grid-item {
              img {
                background-color: $dark-card-background;
                border: 1px solid $dark-border-color;
              }
            }
            .line {
              color: $dark-all-font-color;
            }
            .table {
              th,
              td {
                color: $dark-all-font-color;
              }
              thead {
                th {
                  border-bottom: 2px solid $dark-border-color;
                }
                .border-bottom-primary {
                  th {
                    border-bottom: 1px solid $primary-color;
                  }
                }
              }
              .table-primary,
              .table-secondary,
              .table-success,
              .table-info,
              .table-warning,
              .table-light,
              .table-danger {
                th,
                td {
                  color: $sidebar-submenu-font-color;
                }
              }
              .bg-primary,
              .bg-secondary,
              .bg-success,
              .bg-info,
              .bg-warning,
              .bg-danger {
                color: $white;
              }
              .bg-light {
                color: $black;
              }
              .thead-light {
                th {
                  color: $black;
                }
              }
              .double,
              .dotted,
              .dashed {
                border-color: $dark-border-color;
              }
              tbody {
                .border-bottom-primary {
                  th,
                  td {
                    border-bottom: 1px solid $primary-color;
                  }
                }
              }
            }
            .table-responsive {
              .table {
                th,
                td {
                  border-top: 1px solid $dark-border-color;
                }
              }
            }
            .table[class*="bg-"] {
              th,
              td {
                color: $white;
              }
            }
            .table-striped {
              tbody {
                tr {
                  &:nth-of-type(odd) {
                    background-color: rgba(0, 0, 0, 0.05);
                    &:hover {
                      th,
                      td {
                        color: $white;
                      }
                    }
                  }
                }
              }
            }
            .table-bordered {
              td,
              th {
                border-color: $dark-border-color;
              }
            }
            .table-inverse {
              tbody {
                tr {
                  &:nth-of-type(odd) {
                    &:hover {
                      td {
                        color: $dark-all-font-color;
                      }
                    }
                  }
                }
              }
            }
            .table-border-vertical {
              tr,
              th,
              td {
                border-right: 1px solid $dark-border-color;
              }
            }
            .table-styling {
              thead,
              tbody {
                th,
                td {
                  color: $white;
                }
              }
            }
            .card-footer {
              background-color: $dark-card-background;
              border-top: 1px solid $dark-border-color;
            }
            .bg-white {
              background-color: $dark-card-background !important;
            }
            .b-l-light {
              border-left: 1px solid $dark-border-color !important;
            }
            .ct-grid {
              stroke: $dark-small-font-color;
            }
            .ct-label {
              color: $dark-small-font-color;
            }
            hr {
              border-top: 1px solid $dark-border-color;
            }
            .text-muted {
              color: $sidebar-submenu-font-color !important;
            }
            .calender-widget {
              .cal-date {
                background-color: $dark-card-background;
                border: 1px solid $dark-border-color;
              }
            }
            .contact-form {
              .theme-form {
                border: 1px solid $dark-border-color;
                .form-icon {
                  background-color: $dark-card-background;
                  border: 1px solid $dark-border-color;
                  color: $dark-all-font-color;
                }
              }
            }
            .btn-outline-light,
            .btn-outline-dark {
              color: $white !important;
            }
            .btn-outline-light {
              &:hover {
                color: $black !important;
              }
            }

            .border-right {
              border-right: 1px solid $dark-border-color !important;
            }
            .flot-chart-placeholder {
              .flot-text {
                color: $dark-all-font-color !important;
              }
              svg {
                text {
                  fill: $dark-all-font-color;
                }
              }
            }
            .chart-overflow {
              &#line-chart,
              &#column-chart1,
              &#column-chart2 {
                rect {
                  fill: $dark-card-background;
                }
              }
              svg {
                > rect {
                  fill: $dark-card-background;
                }
                > g {
                  > g {
                    > g {
                      text {
                        fill: rgb(177, 177, 178);
                      }
                    }
                  }
                  > text {
                    fill: $dark-all-font-color;
                  }
                }
              }
            }
            .radial-bar {
              &:after {
                background-color: $dark-card-background;
                color: $dark-all-font-color;
              }
            }
            .bar-chart-widget {
              .bottom-content {
                .num {
                  color: $dark-all-font-color;
                  .color-bottom {
                    color: $dark-all-font-color;
                  }
                }
              }
            }
            .b-r-light {
              border-right: 1px solid $dark-border-color !important;
            }
            .chart-container {
              .live-products,
              .turnover,
              .monthly,
              .uses {
                .ct-labels {
                  .ct-label {
                    color: $white;
                  }
                }
                .ct-grid {
                  stroke: $white;
                }
              }
              #browser-uses-chart,
              #website-visiter-chart {
                svg {
                  text {
                    fill: $dark-all-font-color;
                  }
                }
              }
            }
            .status-details {
              h4 {
                color: $dark-all-font-color;
                span {
                  color: $dark-all-font-color;
                }
              }
              span {
                color: $dark-small-font-color;
              }
            }
            .clipboaard-container {
              .form-control {
                background-color: $dark-body-background;
                color: $dark-small-font-color;
                border: 1px solid $dark-border-color;
              }
            }
            .img-thumbnail {
              background-color: $dark-body-background;
              border: 1px solid $dark-border-color;
            }
            .page-item.disabled {
              .page-link {
                background-color: $dark-card-background;
                border-color: $dark-border-color;
              }
            }
            .page-link {
              background-color: $dark-card-background;
              border: 1px solid $dark-border-color;
              &:hover {
                background-color: $dark-border-color;
              }
            }
            .page-item.active {
              .page-link {
                background-color: $primary-color;
              }
            }
            .ecommerce-widget {
              .icon {
                color: $dark-body-background;
              }
              .total-num {
                color: $dark-all-font-color;
                span {
                  color: $dark-all-font-color;
                }
              }
              span {
                color: $dark-small-font-color;
              }
            }
            .flot-chart-container-small {
              background-color: $dark-card-background;
              border-top: 1px solid $dark-border-color;
            }
            .product-table {
              #API-2_wrapper {
                #API-2 {
                  tbody {
                    td {
                      span,
                      p {
                        color: $dark-small-font-color;
                      }
                    }
                  }
                }
              }
              h6 {
                color: $dark-small-font-color;
              }
            }
            .scroll-demo {
              border: 1px solid $dark-border-color;
            }
            .search-form {
              input {
                border: 1px solid $dark-border-color;
                color: $dark-all-font-color;
                background-color: $dark-body-background;
              }
              .form-group {
                &:before {
                  background: $dark-card-background;
                }
              }
            }
            .cd-timeline-content {
              background-color: $dark-border-color;
              &::before {
                border-left: 7px solid $dark-border-color;
              }
            }
            .cd-timeline-block {
              &:nth-child(even) {
                .cd-timeline-content {
                  &::before {
                    border-right-color: $dark-border-color;
                    border-left: transparent;
                  }
                }
              }
            }
            .breadcrumb {
              background-color: $dark-body-background;
            }
            .user-status {
              table {
                td,
                th {
                  border-top: none !important;
                }
              }
            }
            #donut-color-chart-morris-daily,
            #donut-color-chart-morris,
            #browser-uses-chart,
            #website-visiter-chart {
              svg {
                opacity: 0.5;
              }
            }
          }
          .gallery-with-description {
            h4 {
              color: $dark-all-font-color;
            }
            a {
              > div {
                border: 1px solid $dark-border-color;
              }
            }
            p {
              color: $dark-small-font-color;
            }
          }
          .sub-title {
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          }
          .navs-icon {
            li {
              a {
                color: $dark-all-font-color;
                &:hover {
                  color: $primary-color;
                }
              }
            }
            .separator {
              border: 1px solid $dark-border-color;
            }
          }
          .default-according {
            li {
              .text-muted {
                color: $dark-all-font-color !important;
              }
            }
          }
          .navs-icon.default-according.style-1 {
            li {
              button {
                &:hover {
                  color: $primary-color !important;
                }
              }
            }
          }
          .nav-list {
            .nav-list-disc {
              li {
                a {
                  color: $dark-all-font-color;
                }
                &:hover {
                  color: $primary-color;
                  a {
                    color: $primary-color;
                  }
                }
              }
            }
          }
          .navs-dropdown {
            .onhover-show-div {
              background-color: $dark-body-background;
              box-shadow: 0 0 2px 2px $dark-border-color;
              .navs-icon {
                li {
                  a {
                    svg {
                      path,
                      line,
                      polyline,
                      polygon,
                      rect {
                        color: $dark-all-font-color !important;
                      }
                    }
                    &:hover {
                      svg {
                        path,
                        line,
                        polyline,
                        polygon,
                        rect {
                          color: $primary-color !important;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .onhover-dropdown {
            &:hover {
              .onhover-show-div {
                &:before {
                  border-bottom: 7px solid $dark-body-background;
                }
              }
            }
          }
          .default-according {
            .card {
              box-shadow: 0px 0px 1px 1px rgb(55, 66, 82);
              .btn-link {
                color: $dark-all-font-color;
              }
              .card-body {
                color: $dark-small-font-color;
              }
            }
          }
          .border {
            border: 1px solid $dark-border-color !important;
          }
          .blog-box {
            .blog-date {
              color: $dark-all-font-color;
            }
            .blog-details,
            .blog-details-main {
              .blog-social {
                li {
                  color: $dark-small-font-color;
                  border-right: 1px solid $dark-border-color;
                  &:first-child {
                    border-right: 1px solid $dark-border-color;
                  }
                  &:last-child {
                    border-right: none;
                  }
                }
              }
              p {
                color: $dark-all-font-color;
              }
              .single-blog-content-top {
                border-top: 1px solid $dark-border-color;
                p {
                  color: $dark-small-font-color;
                }
              }
            }
          }
          .comment-box {
            .d-flex {
              h6 {
                span {
                  color: $dark-small-font-color;
                }
              }
              img {
                border: 1px solid $dark-border-color;
              }
            }
            p {
              color: $dark-small-font-color;
            }
            .comment-social {
              li {
                color: $dark-small-font-color;
                &:first-child {
                  border-right: 1px solid $dark-border-color;
                }
              }
            }
            hr {
              border-top: 1px solid $dark-border-color;
            }
          }
          .table-hover {
            tbody {
              tr {
                &:hover {
                  background-color: $dark-body-background;
                  td,
                  th {
                    color: $white;
                  }
                }
              }
            }
          }
          ul.the-icons {
            li {
              border: 1px dotted $dark-border-color;
              color: $sidebar-submenu-font-color;
              &:hover {
                background: $black;
                box-shadow: 0 0 3px $dark-card-background;
              }
            }
          }
          .crm-activity {
            > li {
              + li {
                border-top: 1px solid $dark-border-color;
              }
              h6 {
                color: $dark-all-font-color;
              }
            }
            ul.dates {
              li {
                color: $dark-small-font-color;
              }
            }
          }
          #donut-color-chart-morris,
          #donut-color-chart-morris-daily {
            svg {
              text {
                fill: $dark-all-font-color;
              }
            }
          }
          .custom-card {
            .card-profile {
              img {
                background-color: $dark-card-background;
              }
            }
            .profile-details {
              h6 {
                color: $dark-small-font-color;
              }
            }
            .card-social {
              li {
                a {
                  color: $dark-small-font-color;
                }
              }
            }
            .card-footer {
              > div {
                h6 {
                  color: $dark-small-font-color;
                }
                + div {
                  border-left: 1px solid $dark-border-color;
                }
              }
            }
          }
          .form-control {
            background-color: $dark-body-background;
            color: $dark-all-font-color;
            border: 1px solid $dark-border-color !important;
          }
          .select2-drpdwn-project {
            .form-control {
              background-color: $dark-card-background !important;
              border-bottom: 1px solid $primary-color !important;
            }
          }
          .checkbox,
          .radio {
            label {
              &::before {
                background-color: $dark-body-background;
                border: 1px solid $dark-border-color;
                color: $dark-all-font-color;
              }
            }
          }
          .dropdown-basic {
            .dropdown {
              .dropdown-content {
                background-color: $dark-body-background;
                a {
                  color: $dark-all-font-color;
                  &:hover {
                    background-color: $dark-card-background;
                  }
                }
                .dropdown-divider {
                  border: 1px solid $dark-border-color;
                }
              }
            }
          }
          .dropup-basic {
            .dropup {
              .dropup-content {
                background-color: $dark-body-background;
                a {
                  color: $dark-all-font-color;
                  &:hover,
                  &.active {
                    background-color: $dark-card-background;
                  }
                }
              }
            }
          }
          .dropzone.dz-clickable {
            .dz-message {
              h6 {
                color: $dark-all-font-color;
              }
              span {
                color: $dark-small-font-color;
              }
            }
          }
          .dropzone {
            .dz-preview {
              background-color: $dark-body-background;
              .dz-details {
                background-color: $dark-card-background;
              }
            }
          }
          .browser-widget {
            .flex-grow-1 {
              column-rule: 1px solid $dark-border-color;
              h4 {
                color: $dark-all-font-color;
                span {
                  color: $dark-all-font-color;
                }
              }
              span {
                color: $dark-small-font-color;
              }
            }
          }
          .email-wrap {
            .email-app-sidebar {
              .d-flex {
                img {
                  border: 2px solid $dark-body-background;
                }
              }
              .main-menu {
                > li {
                  a {
                    color: $dark-all-font-color;
                    &:hover {
                      background-color: $dark-body-background;
                    }
                  }
                }
              }
            }
            .email-right-aside {
              .email-body {
                .inbox {
                  .d-flex.active {
                    background-color: $dark-body-background;
                  }
                }
              }
            }
            .flex-grow-1 {
              h6 {
                color: $dark-all-font-color;
                small {
                  color: $dark-small-font-color;
                  span {
                    color: $dark-small-font-color;
                  }
                }
              }
              p {
                color: $dark-small-font-color;
              }
            }
            .email-top {
              border-bottom: 1px solid $dark-border-color;
            }
            p {
              color: $dark-small-font-color;
            }
            .email-content {
              .email-top {
                .user-emailid:after {
                  border: 1px solid $dark-border-color;
                }
              }
            }
          }
          .dropdown-menu {
            background-color: $dark-body-background;
            border: 1px solid $dark-border-color;
            a {
              color: $dark-all-font-color;
              &:hover {
                background-color: $dark-card-background;
              }
            }
          }
          .icon-lists {
            div {
              i {
                color: $dark-small-font-color;
              }
              &:hover {
                background-color: $dark-body-background;
              }
            }
          }
          .widget-joins {
            .d-flex {
              .flex-grow-1 {
                > span {
                  color: $dark-small-font-color;
                }
              }
              .details {
                border-left: 1px solid $dark-border-color;
              }
            }
            &:before,
            &:after {
              background-color: $dark-border-color;
            }
          }
          .redial-social-widget {
            i {
              background-color: $dark-card-background;
            }
          }
          .social-widget-card {
            h5,
            h4 {
              color: $dark-all-font-color;
            }
            span {
              color: $dark-small-font-color;
            }
          }
          .b-b-light {
            border-bottom: 1px solid $dark-border-color !important;
          }
          .b-r-dark {
            border-right: 1px solid $white !important;
          }
          .testimonial {
            i {
              color: $dark-border-color;
            }
            p {
              color: $dark-all-font-color;
            }
            h5 {
              color: $dark-all-font-color;
            }
            span {
              color: $dark-small-font-color;
            }
          }
          .grid-showcase {
            span {
              background-color: $dark-card-background;
              border: 1px solid $dark-border-color;
            }
          }
          .grid-align {
            .row {
              background-color: $dark-border-color;
              border: 1px solid $dark-border-color;
            }
          }
          .border-style {
            .card {
              border: 1px solid $dark-border-color;
            }
          }
          .offer-style {
            .card {
              border: 1px dashed $dark-border-color;
            }
          }
          .search-page {
            .info-block {
              + .info-block {
                border-top: 1px solid $dark-border-color;
              }
            }
          }
          .card-absolute {
            .bg-primary,
            .bg-secondary {
              h5 {
                color: $white;
              }
            }
          }
          .timeline-small {
            .d-flex {
              .timeline-round {
                &.timeline-line-1,
                &.small-line,
                &.medium-line {
                  &:after {
                    background-color: $dark-border-color;
                  }
                }
              }
            }
          }
          /* body end*/
        }
        footer {
          border-top: 1px solid $dark-body-background;
          background-color: $dark-card-background;
        }
        .custom-select,
        .custom-file-label {
          background: $dark-body-background;
          color: $dark-all-font-color;
        }
        .footer-fix {
          background-color: $dark-card-background;
          border-top: 1px solid $dark-border-color;
        }
      }
      .chat-box {
        .about {
          .name {
            color: $dark-all-font-color;
          }
        }
        .chat-menu {
          border-left: 1px solid $dark-border-color;
          .nav-tabs {
            border-bottom: 1px solid $dark-border-color;
            .nav-item {
              .nav-link {
                &.active {
                  color: $dark-all-font-color !important;
                }
              }
            }
          }

          .user-profile {
            .image {
              .icon-wrapper {
                background-color: $dark-card-background;
                box-shadow: 1px 1px 3px 1px $dark-body-background;
              }
              .avatar {
                img {
                  border: 5px solid $dark-border-color;
                }
              }
            }
            .social-media {
              a {
                color: $dark-small-font-color;
              }
            }
            .follow {
              span {
                color: $dark-small-font-color;
              }
              .follow-num {
                color: $dark-all-font-color;
              }
            }
          }
        }
        .status {
          color: $dark-small-font-color;
          p {
            color: $dark-all-font-color !important;
          }
        }
        .chat-right-aside {
          .chat {
            .chat-header {
              border-bottom: 1px solid $dark-border-color;
              .chat-menu-icons {
                li {
                  a {
                    i {
                      color: $dark-small-font-color;
                    }
                  }
                }
              }
              img {
                box-shadow: 1px 1px 4px 1px $dark-body-background;
              }
            }
            .chat-msg-box {
              .my-message {
                border: 1px solid $dark-border-color;
              }
              .message {
                color: $dark-all-font-color;
              }
              .other-message {
                background-color: $dark-body-background;
              }
            }
            .chat-message {
              background-color: $dark-card-background;
              border-top: 1px solid $dark-border-color;
              .input-group {
                .form-control {
                  background-color: $dark-card-background;
                }
              }
            }
          }
        }
        .chat-history {
          .call-icons {
            ul {
              li {
                border: 1px solid $dark-border-color;
                a {
                  color: $dark-small-font-color;
                }
              }
            }
          }
        }
        .bitcoin-chat {
          .chat {
            .chat-msg-box {
              .message {
                &.my-message {
                  background-color: #1c222b;
                  .chat-user-img {
                    border: 2px solid $dark-body-background;
                  }
                }
                &.other-message {
                  .chat-user-img {
                    border: 2px solid $dark-body-background;
                  }
                }
              }
            }
          }
        }
      }
      pre {
        background-color: $dark-border-color;
        color: $dark-all-font-color;
      }
      .scorlled {
        background-color: $dark-body-background;
      }
      .input-group-air {
        box-shadow: 0 3px 20px 0 $dark-border-color;
      }
      .input-group-solid {
        .input-group-text,
        .form-control {
          background: $dark-body-background;
          border: 1px solid $dark-border-color;
          color: $dark-all-font-color;
        }
      }
      .semilight-bg-color,
      .header-bg-color {
        background-color: $white;
      }
      .list-group-item {
        background-color: $dark-card-background;
        border: 1px solid rgba(255, 255, 255, 0.3);
        color: $dark-all-font-color;
        &.active {
          background-color: $primary-color;
          border-color: $primary-color;
          color: $white;
        }
        &:hover,
        &:focus {
          z-index: 1;
          text-decoration: none;
        }
      }
      .list-group-item-action {
        &:hover:not(.active),
        &:focus {
          background-color: $dark-body-background;
        }
      }
      .list-group-flush {
        .list-group-item {
          border-left: 0;
          border-right: 0;
          border-radius: 0;
        }
      }
      .list-group-item-primary {
        color: #004085;
        background-color: #b8daff;
      }
      .list-group-item-secondary {
        color: #383d41;
        background-color: #d6d8db;
      }
      .list-group-item-success {
        color: #155724;
        background-color: #c3e6cb;
      }
      .list-group-item-danger {
        color: #721c24;
        background-color: #f5c6cb;
      }
      .list-group-item-warning {
        color: #856404;
        background-color: #ffeeba;
      }
      .list-group-item-info {
        color: #0c5460;
        background-color: #bee5eb;
      }
      .list-group-item-light {
        color: #818182;
        background-color: #fdfdfe;
      }
      .list-group-item-dark {
        color: #1b1e21;
        background-color: #c6c8ca;
      }
      .auth-bg {
        background-blend-mode: overlay;
        background-color: rgba(54, 64, 74, 0.9);
        .authentication-box {
          .card {
            background-color: $dark-card-background;
            .theme-form {
              .form-group {
                input {
                  color: $dark-all-font-color;
                  background-color: $dark-body-background;
                  border: 1px solid $dark-border-color;
                }
                label {
                  color: $dark-all-font-color;
                }
              }
              .checkbox {
                label {
                  color: $dark-all-font-color;
                  &::before {
                    background-color: $dark-body-background;
                    border: 1px solid $dark-border-color;
                  }
                }
              }
            }
          }
          h3,
          h4,
          h6 {
            color: $white;
          }
        }
      }
      .auth-bg-video {
        background-blend-mode: overlay;
        background-color: rgba(54, 64, 74, 0.7);
        .authentication-box {
          h4,
          h3 {
            color: $white;
          }
          h6 {
            color: $dark-small-font-color;
          }
          .card {
            background-color: $dark-card-background;
            .theme-form {
              .form-group {
                input[type="text"],
                input[type="password"] {
                  background-color: $dark-body-background;
                  border: 1px solid $dark-border-color;
                  color: $dark-all-font-color;
                }
              }
              .checkbox {
                label {
                  &::before {
                    background-color: $dark-body-background;
                    border: 1px solid $dark-border-color;
                  }
                }
              }
            }
          }
        }
      }
      .error-wrapper {
        .maintenance-icons {
          li {
            i {
              color: $dark-border-color;
            }
            &:nth-child(3) {
              i {
                color: $dark-border-color;
              }
            }
          }
        }
      }
      .modal-content {
        background-color: $dark-card-background;
        .modal-header {
          border-bottom: 1px solid $dark-border-color;
          .close {
            color: $dark-small-font-color;
          }
        }
        .modal-footer {
          border-top: 1px solid $dark-border-color;
        }
      }
      .comingsoon {
        background-blend-mode: overlay;
        background-color: rgba(54, 64, 74, 0.9);
        .comingsoon-inner {
          h5 {
            color: $dark-all-font-color;
          }
          .countdown {
            border-top: 1px solid $dark-border-color;
            border-bottom: 1px solid $dark-border-color;
            .title {
              color: $dark-all-font-color;
            }
          }
          .coming-soon-bottom-link {
            a {
              color: $dark-all-font-color;
            }
          }
        }
      }
      .theme-form {
        .login-divider {
          border-top: 1px solid $dark-border-color;
          &:before {
            background: $dark-border-color;
            color: $dark-all-font-color;
          }
        }
      }
      .authentication-main {
        background-color: $dark-border-color;
        .auth-innerright {
          .reset-password-box {
            .card {
              background-color: $dark-card-background;
            }
            .reset-password-link {
              color: $dark-small-font-color;
            }
            .theme-form {
              .form-group {
                label {
                  color: $dark-small-font-color;
                }
                .form-control {
                  background-color: $dark-body-background;
                  color: $dark-all-font-color;
                  border-color: $dark-border-color;
                }
              }
              .opt-box {
                background-color: $dark-border-color;
              }
            }
          }
          .authentication-box {
            h4 {
              color: $dark-all-font-color;
            }
            h6 {
              color: $dark-small-font-color;
            }
            h3 {
              color: $dark-all-font-color;
            }
            .card {
              background-color: $dark-card-background;
              .theme-form {
                .form-group {
                  input[type="text"],
                  input[type="password"] {
                    background-color: $dark-body-background;
                    border: 1px solid $dark-border-color;
                    color: $dark-all-font-color;
                  }
                }
                .checkbox {
                  label {
                    &::before {
                      background-color: $dark-body-background;
                      border: 1px solid $dark-border-color;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .vertical-menu-main {
        background-color: $dark-card-background;
      }
      .pixelstrap {
        a {
          color: $dark-all-font-color;
          &:focus,
          &:active,
          &:hover {
            color: $dark-all-font-color;
          }
        }
        > {
          li {
            > a.highlighted {
              &:before {
                background-color: $dark-card-background;
              }
              &:after {
                background-color: $dark-border-color;
              }
            }
          }
        }
      }
      @media (min-width: 1200px) {
        .pixelstrap {
          ul {
            background: $dark-body-background;
            border: 1px solid $dark-border-color;
            a {
              &:hover,
              &:focus,
              &:active,
              &.highlighted {
                color: $primary-color;
              }
              &.current {
                background-color: $dark-body-background;
                color: $primary-color;
              }
            }
          }
        }
      }
      .mega-menu {
        .title {
          color: $dark-all-font-color;
          border-bottom: 1px solid $dark-border-color;
        }
        .galleria-list {
          .galleria {
            > div {
              .username {
                color: $dark-all-font-color;
                small {
                  color: $dark-small-font-color;
                }
              }
            }
          }
        }
        .list-unstyled {
          div {
            a {
              &:hover {
                color: $primary-color;
              }
            }
          }
        }
      }
      .default-according {
        .card {
          background-color: $dark-card-background;
          .btn-link {
            background-color: $dark-card-background;
            border: 1px solid $dark-card-background;
            color: $white;
          }
          .text-muted {
            color: $dark-small-font-color !important;
          }
        }
        .bg-primary {
          .btn-link {
            background-color: $primary-color;
            border: 1px solid $primary-color;
          }
        }
        .bg-secondary {
          .btn-link {
            background-color: $secondary-color;
            border: 1px solid $secondary-color;
          }
        }
      }
      .collapse {
        .card-body {
          background-color: $dark-card-background;
        }
      }
      @media screen and (max-width: 1660px) {
        .chat-box {
          .chat-history {
            .call-icons {
              ul {
                li {
                  border: 1px solid $dark-border-color;
                }
              }
            }
            .total-time {
              h2 {
                color: $dark-all-font-color;
              }
            }
          }
        }
        .caller-img {
          img {
            opacity: 0.7;
          }
        }
        .chat-box {
          .chat-history {
            .call-content {
              > div {
                background-color: rgba(0, 0, 0, 0.75);
                background-blend-mode: overlay;
              }
            }
          }
        }
      }
      @media only screen and (max-width: 1199px) {
        .sm {
          background-color: $dark-card-background;
          border: 1px solid $dark-border-color;
        }
        .pixelstrap {
          .mobile-back {
            border-bottom: 1px solid $dark-border-color;
          }
          ul {
            li {
              border-top: 1px solid $dark-border-color;
            }
            a {
              background: $dark-card-background;
              &:hover,
              &:focus,
              &:active {
                background: $dark-card-background;
              }
            }
          }
        }
        .chat-menu {
          border-top: 1px solid $dark-border-color;
          background-color: $dark-card-background;
        }
        .error-wrapper {
          .maintenance-icons {
            li {
              i {
                color: $dark-color;
              }
            }
          }
        }
      }
      .pixelstrap {
        ul {
          background: $dark-card-background;
        }
      }
      @media only screen and (max-width: 575px) {
        .user-profile {
          .hovercard {
            .info {
              .user-designation {
                border-top: 1px solid $dark-border-color;
                border-bottom: 1px solid $dark-border-color;
              }
            }
          }
        }
        .widget-joins {
          .border-after-xs {
            &:after {
              background-color: $dark-border-color;
            }
          }
        }
      }
    }
    .offcanvas-bookmark {
      &.page-wrapper {
        .page-body-wrapper {
          .page-body {
            .bookmark {
              ul {
                li {
                  .Typeahead-menu {
                    a {
                      color: $dark-small-font-color;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .ProfileCard {
      &:hover {
        background: #f8f8f8;
      }
    }
    .kUKEuI {
      background-color: $dark-body-background;
    }
    .bqNMrp {
      border-bottom: 1px solid $dark-border-color;
    }
    .vertical-timeline-element-content {
      background-color: $dark-border-color !important;
      .vertical-timeline-element-content-arrow {
        border-right: 7px solid $dark-border-color !important;
      }
    }
    .vertical-timeline-element-icon {
      box-shadow: 0 0 0 4px $dark-border-color, inset 0 2px 0 rgba($black, 0.08),
        0 3px 0 4px rgba($black, 0.05);
    }
    .vertical-timeline {
      &::before {
        background: $dark-border-color !important;
      }
    }
    .sweet-alert,
    .reactour__helper,
    .fileContainer,
    .ceCBVJ {
      background-color: $dark-card-background !important;
    }
    .rbc-month-view,
    .rbc-time-view {
      border: 1px solid $dark-border-color;
    }
    .rbc-toolbar {
      button {
        color: $dark-small-font-color;
        &:hover,
        &:focus {
          background-color: $primary-color;
          color: $white;
        }
      }
    }
    .rbc-month-row {
      + .rbc-month-row {
        border-top: 1px solid $dark-border-color;
      }
    }
    .rbc-off-range-bg {
      background: $dark-body-background;
    }
    .rbc-day-bg {
      + .rbc-day-bg {
        border-left: 1px solid $dark-border-color;
      }
    }
    .rbc-header,
    .rbc-timeslot-group {
      border-bottom: 1px solid $dark-border-color;
      + .rbc-header {
        border-left: 1px solid $dark-border-color;
      }
    }
    .rbc-time-content {
      border-top: 2px solid $dark-border-color;
    }
    .rbc-agenda-view {
      table {
        &.rbc-agenda-table {
          border: 1px solid $dark-border-color;
          tbody {
            > tr {
              > td {
                + td {
                  border-left: 1px solid $dark-border-color;
                }
              }
            }
          }
          thead {
            > tr {
              > th {
                border-bottom: 1px solid $dark-border-color;
              }
            }
          }
        }
      }
    }
    .rbc-day-slot {
      .rbc-time-slot {
        border-top: 1px solid $dark-border-color;
      }
    }
    .rbc-time-header-content,
    .rbc-time-content > * + * > * {
      border-left: $dark-border-color;
    }
    #external-events {
      border: 1px solid $dark-border-color;
    }
    .fc-unthemed {
      th,
      td,
      thead,
      tbody,
      .fc-divider,
      .fc-row,
      .fc-content,
      .fc-popover,
      .fc-list-view,
      .fc-list-heading td {
        border-color: $dark-border-color;
      }
      td {
        &.fc-today {
          background: $dark-body-background;
        }
      }
      .fc-divider,
      .fc-popover,
      .fc-list-heading {
        background-color: $dark-border-color;
        .fc-header,
        td {
          background-color: $dark-border-color;
        }
      }
    }
    .email-wrap {
      .email-app-sidebar {
        .main-menu {
          > li {
            a {
              &.active,
              &:hover {
                background: $dark-body-background;
              }
            }
          }
        }
      }
    }
    .list-persons {
      .profile-mail {
        .d-flex {
          .flex-grow-1 {
            ul {
              li {
                + li {
                  border-left: 2px solid $dark-border-color;
                }
              }
            }
          }
        }
      }
    }
    .contacts-tabs {
      .nav-pills {
        border-right: 1px solid $dark-border-color;
        .nav-link {
          + .nav-link {
            border-top: 1px solid $dark-border-color;
          }
        }
      }
    }
    .contact-profile {
      .icon-wrapper {
        background-color: $dark-body-background;
      }
    }
    .social-app-profile {
      .market-tabs {
        border-top: none;
      }
    }
    .browse {
      .browse-articles {
        h6 {
          border-bottom: 1px solid $dark-border-color;
        }
      }
    }
    .my-gallery {
      &.gallery-with-description {
        .caption {
          border: 1px solid $dark-border-color;
        }
        figure {
          .img-thumbnail {
            background-color: $dark-card-background !important;
          }
        }
      }
    }
    .masonry-with-dec {
      .my-masonry-grid_column {
        li {
          img,
          .caption {
            border: 1px solid $dark-border-color;
          }
        }
      }
    }
    .form-group {
      .form-control {
        background-color: $dark-body-background;
      }
    }
    .table-primary,
    .table-secondary,
    .table-success,
    .table-info,
    .table-warning,
    .table-danger,
    .table-light {
      th,
      td {
        color: $black !important;
      }
    }
    @each $border-tab-name, $border-tab-color in (primary, $primary-color),
      (secondary, $secondary-color), (success, $success-color),
      (danger, $danger-color), (info, $info-color), (light, $light-color),
      (dark, $dark-color), (warning, $warning-color)
    {
      .borderb-tab-#{$border-tab-name} {
        .nav-tabs {
          &.nav-item {
            .nav-link {
              color: $dark-all-font-color;
            }
          }
          .nav-link {
            &.active {
              color: $border-tab-color;
              border-bottom: 2px solid $border-tab-color !important;
            }
          }
        }
      }
    }
    ol {
      &.progtrckr {
        li {
          &.progtrckr-doing,
          &.progtrckr-done {
            color: $dark-small-font-color;
          }
          &.progtrckr-todo {
            &:before {
              background-color: $dark-card-background;
            }
          }
        }
      }
    }
    .search-page {
      ul {
        &.search-info {
          li {
            + li {
              border-left: 1px solid $dark-border-color;
            }
          }
        }
      }
    }
    .styles_modal__gNwvD {
      background-color: $dark-card-background;
    }
    .product-size {
      ul {
        li {
          button {
            color: $dark-small-font-color;
          }
        }
      }
    }
    .product-wrapper {
      &.sidebaron {
        .product-sidebar {
          .filter-section {
            .card {
              .left-filter {
                background-color: $dark-card-background;
              }
            }
          }
        }
      }
    }
    .mega-inline,
    .mega-horizontal {
      .card {
        border: 1px solid $dark-border-color;
      }
    }

    // draggable card
    .react-kanban-column {
      background-color: $dark-body-background;
    }
    .react-kanban-card,
    .react-kanban-card-adder-form,
    .react-kanban-card-skeleton {
      background-color: $dark-card-background;
    }
    .react-kanban-card__title {
      border-bottom: 1px solid $dark-border-color;
    }
    .draggable-card {
      .react-kanban-card__title {
        border-bottom: none;
      }
    }

    .input-cropper {
      border: 1px solid $dark-border-color;
    }
    .dzu-previewContainer {
      border-bottom: 1px solid #665f5f;
    }
    .dzu-previewButton {
      filter: brightness(0.5) invert(1);
    }

    // react datatable
    .rdt_Table {
      border: 1px solid $dark-border-color;
    }
    .rdt_TableHeadRow {
      background-color: $dark-card-background;
    }
    .rdt_TableCol,
    .rdt_TableCol_Sortable,
    .___react-data-table-allow-propagation___ {
      color: $dark-all-font-color;
    }
    .rdt_TableRow {
      &:nth-of-type(odd) {
        color: $dark-all-font-color;
        background-color: $dark-body-background;
      }
      &:nth-of-type(even) {
        color: $dark-all-font-color;
        background-color: $dark-card-background;
      }
    }
    .fpZHHx {
      fill: $dark-all-font-color;
      color: $dark-all-font-color;
    }
    .rdt_Pagination {
      background-color: $dark-card-background;
      border-top-color: $dark-border-color;
      color: $dark-all-font-color;
      button {
        color: $dark-all-font-color;
        fill: $dark-all-font-color;
        &:disabled {
          color: $dark-small-font-color;
          fill: $dark-small-font-color;
        }
      }
    }
    .rdt_TableCol_Sortable {
      &:hover {
        color: $dark-all-font-color;
      }
    }
    .rdt_TableHeader {
      background-color: $dark-body-background !important;
      > div {
        &:last-child {
          background-color: $dark-body-background !important;
          color: $dark-all-font-color;
        }
      }
    }

    .popover-header {
      color: $theme-body-font-color;
    }
    .grid-showcase {
      span {
        background-color: $dark-card-background;
        border: 1px solid $dark-border-color;
      }
    }
    .form-control {
      background-color: $dark-body-background;
      border: 1px solid $dark-border-color;
      color: $dark-all-font-color;
    }
    .dropdown-menu {
      background-color: $dark-body-background !important;
    }
    .dropdown-item,
    span.twitter-typeahead .tt-suggestion {
      color: $dark-all-font-color;
      &:hover,
      &:focus,
      &.active {
        background-color: $dark-card-background !important;
        color: $dark-all-font-color !important;
      }
    }

    // kanban board
    .kanban-container {
      .kanban-item {
        .kanban-box {
          background-color: $dark-card-background;
        }
      }
    }
    .jkanban-container {
      .kanban-container {
        .kanban-item {
          .kanban-box {
            border: 1px solid $dark-border-color;
          }
        }
      }
    }

    .modal {
      .modal-header,
      .modal-body {
        color: $dark-all-font-color;
      }
    }
    .modal-header {
      .close {
        color: $dark-small-font-color;
      }
    }
    .modal-footer {
      border-top: 1px solid $dark-border-color;
    }
    .default-according {
      .list-group-item {
        border: 1px solid $dark-border-color !important;
      }
    }
    .tab-vertical {
      .nav-tabs {
        border: 1px solid hsla(0, 0%, 100%, 0.3);
        .nav-link {
          + .nav-link {
            border-top: 1px solid hsla(0, 0%, 100%, 0.3);
          }
          &:hover,
          &:focus {
            background-color: $dark-body-background;
          }
        }
      }
    }
    .bar-chart-widget {
      .num {
        .color-bottom {
          span {
            color: $dark-all-font-color;
          }
        }
      }
    }
    .ecommerce-widget {
      border: 1px solid $dark-border-color;
    }
    .cart {
      .qty-box {
        .input-group {
          .btn {
            background-color: $dark-border-color !important;
            border-color: $dark-border-color !important;
          }
        }
      }
    }
    .checkout {
      .checkout-details {
        background-color: $dark-body-background;
        border: 1px solid $dark-border-color;
        padding: 40px;
      }
    }
    .shopping-checkout-option {
      .checkbox_animated {
        &:after {
          border: 2px solid $dark-border-color;
        }
      }
    }
    .animate-chk {
      .radio_animated {
        &:after {
          border: 2px solid $dark-border-color;
        }
      }
    }
    .order-box {
      .title-box {
        color: #bfc2c6;
        border-bottom: 1px solid $dark-border-color;
      }
      .qty {
        border-bottom: 1px solid $dark-border-color;
        li {
          color: #bfc2c6;
          span {
            color: #bfc2c6;
          }
        }
      }
      .sub-total {
        li {
          color: #bfc2c6;
        }
      }
      .total {
        li {
          color: #bfc2c6;
        }
      }
    }
    .custom-scrollbar::-webkit-scrollbar-thumb {
      background-color: rgba(41, 50, 64, 0.52);
    }
    .card {
      .card-header {
        .card-header-right {
          background-color: $dark-card-background;
        }
      }
    }
    .product-box {
      .product-img {
        .product-hover {
          ul {
            li {
              .btn {
                color: $dark-small-font-color;
              }
              &:hover {
                .btn {
                  color: $white;
                }
              }
            }
          }
        }
      }
    }
    .radio_animated,
    .checkbox_animated {
      &:after {
        background: $dark-body-background;
        border: 2px solid $dark-body-background;
      }
    }
    .slider-product {
      border-top: 1px solid $dark-border-color;
      border-bottom: 1px solid $dark-border-color;
    }
    .square-product-setting {
      .icon-grid {
        background-color: $dark-card-background;
        svg {
          color: $dark-all-font-color;
        }
      }
    }
    .bitcoin-form {
      .bitcoin-form-dropdown {
        .onhover-dropdown {
          .btn {
            background-color: $dark-border-color;
            color: $dark-all-font-color;
          }
          .onhover-show-div {
            background-color: $dark-body-background;
            box-shadow: 0 0 2px 2px $dark-body-background;
          }
        }
      }
      .form-row {
        .input-group {
          .input-group-prepend {
            .input-group-text {
              border: 1px solid $dark-border-color;
            }
          }
        }
      }
    }
    .active-order-table,
    .market-table {
      table {
        thead {
          tr {
            th {
              border-top: none !important;
            }
          }
        }
        tbody {
          tr {
            td {
              border-bottom: 1px solid $dark-border-color;
            }
            &:last-child {
              td {
                border-bottom: none;
              }
            }
          }
        }
      }
    }
    .pricing-card-design-2 {
      .pricing-block {
        .pricing-inner {
          ul {
            li {
              border-bottom: 1px solid $dark-border-color;
            }
          }
        }
      }
    }
    .flot-chart-container {
      .legend {
        .table {
          tbody {
            background-color: $dark-card-background;
            color: $white;
            .legendLabel {
              padding-left: 5px;
            }
          }
        }
      }
    }
    .google-visualization-tooltip {
      text {
        fill: rgba(0, 0, 0, 0.7) !important;
      }
    }
    .nav-dark,
    .nav-pills.nav-dark {
      .nav-link {
        color: $white;
      }
    }
    .loader-wrapper {
      background-color: $dark-body-background;
      .loader {
        background-color: $dark-body-background !important;
      }
    }
    .lg-backdrop {
      background-color: $dark-body-background;
    }
    .lg-outer {
      .lg-thumb-outer {
        background-color: $dark-card-background;
      }
    }
    .theme-form {
      .form-group {
        select.form-control:not([size]):not([multiple]) {
          background-color: $dark-body-background;
          color: $dark-all-font-color;
          border-color: $dark-border-color;
        }
        input[type="text"],
        input[type="email"],
        input[type="search"],
        input[type="password"],
        input[type="number"],
        input[type="tel"],
        input[type="date"],
        input[type="datetime-local"],
        input[type="time"],
        input[type="datetime-local"],
        input[type="month"],
        input[type="week"],
        input[type="url"],
        input[type="file"],
        select {
          border-color: $dark-border-color;
          background-color: $dark-body-background;
          color: $dark-all-font-color;
          &::-webkit-input-placeholder {
            color: $dark-small-font-color;
          }
        }
        .form-control {
          background-color: $dark-body-background;
          color: $dark-all-font-color;
        }
        textarea {
          border-color: $dark-border-color;
        }
      }
      .form-divider {
        border-top: 1px solid $dark-border-color;
        &::before {
          background: $dark-card-background;
          color: $dark-all-font-color;
        }
      }
    }
    .CodeMirror {
      background: $dark-card-background;
      border: 1px solid $dark-small-font-color;
      color: $dark-all-font-color;
      .CodeMirror-code {
        pre {
          background: $dark-card-background;
        }
      }
    }
    .editor-toolbar {
      border-top: 1px solid $dark-small-font-color;
      border-left: 1px solid $dark-small-font-color;
      border-right: 1px solid $dark-small-font-color;
      a {
        color: $dark-all-font-color !important;
        &:hover,
        &.active {
          background: $dark-border-color;
        }
      }
      i.separator {
        border-left: 1px solid $dark-small-font-color;
        border-right: 1px solid $dark-small-font-color;
      }
    }
    .editor-toolbar.disabled-for-preview {
      a {
        &:not(.no-disable) {
          background: $dark-border-color;
        }
      }
    }
    .editor-preview {
      background-color: $dark-card-background;
    }
    .editor-toolbar.fullscreen,
    .editor-preview-side {
      background: $dark-card-background;
      border: 1px solid $dark-small-font-color;
    }
    .u-step {
      background: $dark-border-color;
      &.active,
      &.current {
        background: $primary-color;
        color: $white;
      }
    }
    .u-step-title,
    .u-pearl-title {
      color: $dark-all-font-color;
    }
    .u-step-number {
      background-color: $dark-card-background;
    }
    .u-pearl {
      &:before {
        background-color: $dark-border-color;
      }
    }
    .u-pearl-number,
    .u-pearl-icon {
      background: $dark-body-background;
      border: 2px solid $dark-body-background;
    }
    .u-pearl.disabled {
      .u-pearl-icon,
      .u-pearl-number {
        background: $dark-border-color;
        border: 2px solid $dark-border-color;
      }
      &:after {
        background-color: #334053;
      }
    }
    .u-pearl.error {
      &:after {
        background-color: $dark-body-background;
      }
    }
    .note-editor.note-frame {
      border-color: $dark-border-color;
      .note-editing-area {
        .note-editable {
          background-color: $dark-card-background;
          color: $dark-all-font-color;
        }
      }
    }
    .swal-modal {
      background-color: $dark-card-background;
      .swal-title {
        color: $dark-all-font-color;
      }
      .swal-text {
        color: $dark-small-font-color;
      }
      .swal-content__input {
        background-color: $dark-body-background;
        color: $dark-all-font-color;
      }
    }
    .nav-tabs {
      border-bottom: 1px solid $dark-border-color;
      .nav-link {
        &.active {
          background-color: $dark-card-background;
          color: $dark-all-font-color;
          border-color: $dark-border-color $dark-border-color
            $dark-card-background;
        }
        &:hover,
        &:focus {
          border-color: $dark-border-color $dark-border-color $dark-border-color;
        }
      }
      .nav-item.show {
        .nav-link {
          background-color: $dark-card-background;
          color: $dark-all-font-color;
          border-color: $dark-border-color $dark-border-color
            $dark-card-background;
        }
      }
    }
    .nav-tabs.nav-bottom {
      .nav-item {
        .nav-link {
          &.active,
          &.focus,
          &.hover {
            border-color: $dark-card-background $dark-border-color
              $dark-border-color;
          }
        }
      }
    }
    .dropdown-divider {
      border-top: 1px solid $dark-border-color;
    }
    .icon-hover-bottom {
      background-color: $dark-card-background;
      box-shadow: 0px 0px 1px 1px $dark-border-color;
      .icon-title {
        color: $dark-all-font-color;
      }
      span {
        color: $dark-small-font-color;
      }
      .form-group {
        input {
          background-color: $dark-body-background;
          color: $dark-all-font-color;
          border: 1px solid $dark-border-color;
        }
      }
      .icon-first {
        i {
          color: $dark-small-font-color;
        }
      }
    }
    code {
      background-color: #d8ecff;
      border-radius: 2px;
    }
    #cd-timeline {
      &::before {
        background: $dark-border-color;
      }
    }
    .timeliny {
      border-top: 1px solid $dark-border-color;
      border-bottom: 1px solid $dark-border-color;
      &::before {
        background-color: $white;
      }
      .timeliny-dot {
        background-color: $dark-card-background;
        border: 1px solid $white;
        &::before {
          color: $dark-all-font-color;
        }
      }
      .timeliny-timeline {
        .timeliny-timeblock {
          &.active {
            .timeliny-dot {
              &::before {
                color: $dark-all-font-color;
              }
            }
          }
          &:not(.inactive):not(.active) {
            .timeliny-dot {
              &:hover {
                background-color: $white;
                &::before {
                  color: $dark-all-font-color;
                }
              }
            }
          }
          .timeliny-dot {
            &:hover {
              &::after {
                background-color: $dark-card-background;
                border: 1px solid $dark-border-color;
                color: $dark-all-font-color;
              }
            }
          }
        }
      }
    }
    .todo {
      .todo-list-wrapper {
        #todo-list {
          li {
            .task-container {
              background: $dark-card-background;
              border-bottom: 1px solid $dark-border-color;
              .task-label {
                color: $dark-all-font-color;
              }
              &:hover {
                h4 {
                  color: $white;
                }
              }
              .task-action-btn {
                .action-box {
                  &:hover:not(.active) {
                    background: $dark-card-background;
                    border: 1px solid $dark-border-color;
                  }
                }
              }
            }
            &.completed {
              .task-container {
                .task-label {
                  color: $primary-color;
                }
                .complete-btn {
                  &:hover {
                    border: 1px solid $success-color !important;
                  }
                }
              }
            }
          }
        }
        .todo-list-footer {
          .new-task-wrapper {
            textarea {
              background-color: $dark-card-background;
              border: 1px solid $dark-border-color;
              color: $dark-all-font-color;
            }
          }
        }
      }
    }
    .user-profile {
      .ttl-info {
        h6 {
          color: $dark-small-font-color;
        }
        span {
          color: $dark-all-font-color;
        }
      }
      .hovercard {
        .info {
          .title {
            a {
              color: $dark-all-font-color;
            }
          }
        }
        .user-image {
          .avatar {
            img {
              border: 10px solid $dark-card-background;
            }
          }
          .icon-wrapper {
            background-color: $dark-card-background;
          }
        }
        .tabs-scoial {
          border-bottom: none !important;
        }
      }
      .follow {
        .follow-num {
          color: $dark-all-font-color;
        }
      }
      .profile-img-style {
        .user-name {
          color: $dark-all-font-color;
        }
        p {
          color: $dark-small-font-color;
        }
      }
    }
    span.twitter-typeahead {
      .tt-menu {
        background-color: $dark-body-background;
        color: $dark-all-font-color;
      }
      .tt-suggestion {
        color: $dark-all-font-color;
        &:hover,
        &:focus {
          background-color: $dark-card-background;
        }
      }
    }
    .typography {
      small {
        color: $dark-all-font-color;
      }
    }
    .blockquote-footer {
      color: $dark-small-font-color;
    }
    .code-box-copy {
      pre {
        background-color: $dark-body-background;
        code {
          background-color: $dark-body-background;
        }
      }
      pre[class*="language-"] {
        border: 1px solid $dark-border-color;
      }
      .code-box-copy__btn {
        background-color: $dark-body-background;
        border: 1px solid $dark-border-color;
        color: $white;
      }
      code[class*="language-"],
      pre[class*="language-"] {
        text-shadow: 0px 1px $black;
        ::selection {
          text-shadow: none;
          background: $dark-card-background;
        }
      }
    }
    table.fixedHeader-floating {
      background-color: $dark-body-background;
    }
    .error-wrapper {
      background-color: rgba(54, 64, 74, 0.8);
      .sub-content {
        color: $dark-all-font-color;
      }
    }
    .b-light {
      border: 1px solid $dark-border-color !important;
    }
    .modal-content {
      background-color: $dark-card-background;
      .modal-header {
        border-bottom: 1px solid $dark-border-color;
      }
    }
    .modal {
      .theme-close {
        background-color: $dark-card-background !important;
      }
    }
    .loader-box {
      &.card-loader {
        background-color: $dark-card-background;
      }
    }
    .my-gallery {
      &.gallery-with-description {
        img {
          border: 1px solid $dark-border-color !important;
          border-bottom: none !important;
        }
      }
    }
    @each $var in $alert-name {
      $i: index($alert-name, $var);
      .alert-#{$var}.inverse {
        color: $dark-all-font-color;
      }
    }
    .alert-theme {
      span {
        + span {
          + span {
            border: 1px solid $primary-color;
            background-color: $dark-body-background;
            border-radius: 4px;
          }
        }
      }
      i {
        background-color: $primary-color;
        color: $white;
        padding: 20px;
        margin-right: 20px !important;
      }
    }
    .user-card {
      .user-deatils {
        h6 {
          color: $dark-small-font-color;
        }
      }
      .card-footer {
        > div {
          &:first-child {
            border-right: 1px solid $dark-border-color;
          }
        }
        .user-footer {
          h6 {
            color: $dark-small-font-color;
          }
          svg {
            path,
            rect {
              color: $dark-small-font-color;
            }
          }
        }
      }
    }
    .dashboard-chat {
      .chat {
        .d-flex {
          .flex-grow-1 {
            .message-main {
              p {
                background-color: $dark-body-background;
              }
              &.smiley-bg {
                background-color: $dark-body-background;
              }
            }
          }
        }
        .right-side-chat {
          .flex-grow-1 {
            p {
              &:before {
                border-left: 7px solid $dark-body-background;
              }
            }
          }
        }
        .left-side-chat {
          .flex-grow-1 {
            p {
              &:before {
                border-right: 7px solid $dark-body-background;
              }
            }
          }
        }
      }
    }
    .selling-update {
      border: 2px solid $dark-border-color;
    }
    .invest-dropdown {
      .right-header-dropdown {
        &.onhover-show-div {
          background-color: $dark-body-background;
          box-shadow: 0 0 2px 2px $dark-body-background;
          a {
            color: $dark-all-font-color;
          }
        }
      }
    }
    .server-card-bg {
      background-color: #181f27 !important;
    }
    .server-widgets {
      .bottom-server {
        h5 {
          .second-color {
            color: $dark-all-font-color;
          }
        }
      }
    }
    .tabs-project {
      .nav-item {
        .nav-link {
          color: $dark-all-font-color;
        }
      }
    }
    .current-progress,
    .order-history {
      table {
        tr {
          td,
          th {
            border-top: none !important;
          }
        }
      }
      .title-orders {
        background-color: $dark-body-background;
      }
    }
    .navigation-option {
      ul {
        li {
          a {
            color: $dark-all-font-color;
          }
          &:hover {
            background-color: $dark-body-background;
          }
        }
      }
    }
    .product-box {
      .product-details {
        h6 {
          color: $dark-all-font-color;
        }
        span {
          color: $dark-small-font-color;
        }
      }
      .product-img {
        .product-hover {
          ul {
            li {
              background-color: $dark-card-background;
              &:hover {
                background-color: $black;
                color: $white;
              }
            }
          }
        }
      }
    }
    .browse {
      .browse-articles {
        ul {
          li {
            a {
              color: $dark-all-font-color;
            }
            &:hover {
              background-color: $dark-body-background;
            }
          }
        }
      }
    }
    // project
    .theme-tab {
      .tab-title {
        border-bottom: 1px solid $dark-border-color;
        li {
          &.react-tabs__tab--selected {
            background-color: $dark-card-background;
          }
        }
      }
    }
    .schedule {
      .d-flex {
        .schedule-dropdown {
          .dropdown {
            background-color: $dark-body-background;
          }
        }
      }
    }
    // datatable
    .ReactTable {
      border: 1px solid $dark-border-color;
      .rt-table {
        .rt-thead {
          border-bottom: 1px solid $dark-border-color;
        }
        .rt-tbody {
          .rt-tr-group {
            .rt-tr {
              &.-odd {
                background-color: $dark-body-background;
              }
              &:hover {
                background-color: #28303c;
              }
            }
          }
        }
      }
      .-pagination {
        border-top: 1px solid $dark-border-color !important;
        .-btn {
          color: $dark-small-font-color;
        }
        input,
        select {
          background: $dark-body-background;
          color: $white;
        }
      }
    }
    @each $btn-name, $btn-color in (primary, $primary-color),
      (secondary, $secondary-color), (success, $success-color),
      (danger, $danger-color), (info, $info-color), (light, $light-color),
      (dark, $dark-color), (warning, $warning-color)
    {
      .radio-#{$btn-name} {
        input[type="radio"] {
          & + label {
            &::before {
              border-color: $btn-color !important;
            }
            &::after {
              background-color: $btn-color;
            }
          }
          &:checked {
            & + label {
              &::before {
                border-color: $btn-color !important;
              }
              &::after {
                background-color: $btn-color;
              }
            }
          }
        }
      }
    }

    @each $btn-name, $btn-color in (primary, $primary-color),
      (secondary, $secondary-color), (success, $success-color),
      (danger, $danger-color), (info, $info-color), (light, $light-color),
      (dark, $dark-color), (warning, $warning-color)
    {
      .checkbox-#{$btn-name} {
        label {
          &::before {
            border-color: $btn-color !important;
          }
        }
      }
    }

    @each $btn-name, $btn-color in (primary, $primary-color),
      (secondary, $secondary-color), (success, $success-color),
      (danger, $danger-color), (info, $info-color), (light, $light-color),
      (dark, $dark-color), (warning, $warning-color)
    {
      .checkbox-solid-#{$btn-name} {
        label {
          &:before {
            background-color: $btn-color !important;
            border-color: $btn-color !important;
            color: $white !important;
          }
        }
        input[type="checkbox"] {
          &:checked {
            & + label {
              &::before {
                background-color: $btn-color !important;
                border-color: $btn-color !important;
                color: $white !important;
              }
              &::after {
                color: $white !important;
              }
            }
          }
        }
      }
    }
    .bootstrap-datetimepicker-widget {
      ul {
        li {
          color: $dark-all-font-color;
          border-bottom: 1px solid $dark-border-color;
        }
      }
      tr {
        th,
        td {
          &:hover {
            background-color: $dark-card-background;
          }
          span {
            &:hover {
              background-color: $dark-card-background;
            }
          }
          &.prev,
          &.next {
            &:hover {
              background-color: $dark-card-background;
            }
          }
        }
        &:first-child {
          th {
            &:hover {
              background-color: $dark-card-background;
            }
          }
        }
      }
    }
    .btn-transparent {
      color: $dark-all-font-color;
    }
    .categories {
      ul {
        li {
          a {
            color: $dark-small-font-color;
          }
          &:hover {
            background-color: $dark-body-background;
          }
        }
      }
    }
    .main {
      .langChoice {
        background: $dark-body-background;
        color: $white;
        border: 1px solid $dark-border-color;
      }
    }
    .socialprofile {
      .likes-profile {
        h5 {
          span {
            color: $dark-all-font-color;
          }
        }
      }
      span {
        color: $dark-small-font-color;
      }
    }
    .social-status {
      .d-flex {
        .flex-grow-1 {
          span {
            + span {
              color: $dark-small-font-color;
            }
          }
          p,
          .light-span {
            color: $dark-small-font-color;
          }
        }
      }
      form {
        .form-group {
          .form-control-plaintext {
            border: 1px solid $dark-border-color;
          }
        }
      }
    }
    .filter-cards-view,
    .timeline-content {
      p {
        color: $dark-small-font-color;
      }
      .comment-number {
        i {
          color: $dark-small-font-color;
        }
      }
      .comments-box {
        .input-group {
          .form-control {
            border: none !important;
          }
          .input-group-append {
            background-color: $dark-body-background;
          }
        }
      }
    }
    .social-chat {
      .flex-grow-1 {
        border: 1px solid $dark-border-color;
        &:after {
          border-right: 7px solid $dark-card-background;
        }
      }
      span {
        span {
          color: $dark-small-font-color;
        }
      }
    }
    .details-about {
      .your-details {
        p {
          color: $dark-small-font-color;
        }
      }
    }
    .activity-log {
      .my-activity {
        p {
          color: $dark-small-font-color;
        }
      }
    }
    &.rtl {
      .page-main-header {
        .main-header-right {
          .nav-right {
            > ul {
              > li {
                border-right: none;
                &:nth-child(2) {
                  border-right: 1px solid $dark-border-color;
                }
                &:nth-child(5) {
                  border-right: none;
                  border-left: 1px solid $dark-border-color;
                }
                &:nth-child(4) {
                  border-left: none;
                }
              }
            }
          }
        }
      }
    }
    .bookmark {
      ul {
        li {
          a {
            color: $dark-all-font-color;
          }
        }
      }
    }
  }
  &.dark-header-sidebar-mix {
    color: $dark-all-font-color;
    background-color: $dark-body-background;
    .page-wrapper {
      /* Main Header start */
      .page-main-header {
        background-color: $dark-card-background;
        .main-header-right {
          background-color: $dark-card-background;
          li {
            i {
              color: $light-color;
            }
          }
          .nav-right {
            > ul {
              > li {
                border-left: 1px solid $dark-border-color;
                &:last-child,
                &:nth-child(5),
                &:nth-child(1) {
                  border-left: none;
                }
                &:nth-child(5) {
                  border-right: 1px solid $dark-border-color;
                }
                &:first-child {
                  .search-form {
                    .form-group {
                      &:before {
                        background: $dark-border-color;
                      }
                    }
                  }
                }
              }
            }
            .nav-menus {
              li {
                .d-flex {
                  .flex-grow-1 {
                    .txt-dark {
                      color: $dark-all-font-color !important;
                    }
                  }
                }
                .onhover-show-div {
                  background-color: $dark-body-background;
                  box-shadow: 0 0 2px 2px $dark-card-background;
                  &:before {
                    border-bottom: 7px solid $dark-body-background;
                  }
                  &:after {
                    border-bottom: 7px solid $dark-body-background;
                  }
                }
                .notification-dropdown.onhover-show-div {
                  li {
                    border-bottom: 1px solid $dark-border-color;
                    span {
                      color: $dark-small-font-color;
                    }
                    &:hover {
                      background-color: #282e38;
                    }
                  }
                  .bg-light {
                    background-color: #282e38 !important;
                    color: $dark-all-font-color !important;
                  }
                }
                .language-dropdown {
                  li {
                    a {
                      color: $dark-all-font-color;
                    }
                  }
                }
              }
            }
            .profile-dropdown {
              li {
                &:nth-child(4) {
                  border-top: 1px solid $dark-border-color;
                }
                a {
                  color: $dark-all-font-color;
                  svg {
                    stroke: $dark-all-font-color;
                    path,
                    line,
                    circle {
                      color: $dark-all-font-color !important;
                    }
                  }
                  &:hover {
                    color: $primary-color;
                    svg {
                      line,
                      path,
                      circle {
                        color: $primary-color !important;
                      }
                    }
                  }
                }
              }
            }
            .search-form {
              input {
                background-color: $dark-body-background;
                border: 1px solid $dark-border-color;
                color: $dark-all-font-color;
              }
            }
            > ul {
              > li {
                .d-flex {
                  .flex-grow-1 {
                    .txt-dark {
                      color: $dark-all-font-color !important;
                    }
                  }
                }
                .onhover-show-div {
                  background-color: $dark-body-background;
                  box-shadow: 0 0 2px 2px $dark-card-background;
                  &:before {
                    border-bottom: 7px solid $dark-body-background;
                  }
                  &:after {
                    border-bottom: 7px solid $dark-body-background;
                  }
                }
                .notification-dropdown.onhover-show-div {
                  li {
                    border-bottom: 1px solid $dark-border-color;
                    span {
                      color: $dark-small-font-color;
                    }
                  }
                }
                .language-dropdown {
                  li {
                    a {
                      color: $dark-all-font-color;
                    }
                  }
                }
              }
            }
          }
          .mega-menu-header {
            .vertical-menu-main {
              .main-nav {
                .main-menu {
                  li {
                    background-color: $dark-card-background;
                  }
                }
              }
            }
          }
        }
        .main-header-left {
          background-color: $dark-card-background;
          .logo-wrapper {
            a {
              .image-dark {
                display: none;
              }
              .image-light {
                display: block;
              }
            }
          }
        }
        @media only screen and (max-width: 991px) {
          .main-header-right {
            .nav-right {
              > ul {
                background-color: $dark-card-background;
                box-shadow: 0 2px 2px 2px $dark-border-color;
              }
            }
          }
        }
        @media only screen and (max-width: 575px) {
          .nav-right {
            > ul {
              .search-form {
                .form-control-plaintext {
                  background-color: $dark-body-background;
                  border: 1px solid $dark-border-color;
                  color: $dark-all-font-color;
                }
              }
            }
          }
        }
      }
      .right-sidebar {
        background-color: $dark-body-background;
        box-shadow: 0 0 11px rgba(69, 110, 243, 0.13);
        .modal-header {
          border-bottom: 1px solid $dark-border-color;
        }
        .friend-list-search {
          background-color: $dark-card-background;
          input {
            background-color: $dark-body-background;
            border: 1px solid $dark-border-color;
          }
        }
      }
      /* Main Header ends */
    }
    .txt-dark {
      color: $dark-all-font-color;
    }
    .page-body-wrapper {
      .page-body {
        color: #313131;
        background-color: rgb(252, 251, 251);
      }
    }
  }
  &.dark-body-only,
  &.dark-sidebar-body-mix {
    .react-calendar__month-view__days,
    .react-calendar__tile,
    .react-calendar__navigation__label,
    .react-calendar__navigation__arrow {
      color: $white;
    }
    .file-sidebar {
      .pricing-plan {
        border: 1px solid $dark-border-color;
      }
      ul {
        li {
          .btn-light {
            background-color: $dark-body-background !important;
            color: $dark-all-font-color !important;
            border: 1px solid $dark-border-color !important;
          }
        }
      }
    }
    .jkanban-container {
      .custom-board {
        .kanban-drag {
          div.react-kanban-column {
            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
              div {
                &:nth-child(2) {
                  div {
                    background: $dark-card-background;
                  }
                }
              }
            }
          }
        }
      }
    }
    .default-according {
      .card {
        .card-header,
        .card-body {
          border: 1px solid $dark-border-color;
        }
      }
    }
    .react_times_button {
      background-color: $dark-body-background;
      color: $dark-all-font-color;
      svg {
        stroke: $dark-all-font-color;
        fill: $dark-all-font-color;
      }
    }
    .table-responsive {
      table,
      .table-inverse {
        .thead-light {
          th {
            color: $white !important;
          }
        }
        tbody {
          tr {
            border-bottom: 1px solid $dark-border-color;
          }
        }
      }
    }
    .file-content {
      .files,
      .folder {
        .file-box,
        .folder-box {
          background-color: $dark-body-background;
          border: 1px solid $dark-border-color;
          .file-top {
            background-color: $dark-card-background;
            border: 1px solid $dark-border-color;
          }
        }
      }
      .form-inline {
        border: 1px solid $dark-border-color;
        background-color: $dark-body-background;
      }
      .form-control {
        border: unset !important;
      }
    }
    .react-datepicker {
      background-color: $dark-body-background;
      .react-calendar__tile,
      .react-datepicker__day,
      .react-datepicker__day-name {
        color: $dark-all-font-color;
      }
      .react-datepicker__header {
        border-bottom: 1px solid $dark-border-color;
      }
    }
    .project-box {
      background-color: $dark-body-background;
      border: 1px solid $dark-border-color;
    }
    .border-tab.nav-tabs {
      .nav-item {
        .nav-link {
          color: $dark-all-font-color;
        }
      }
    }
    .crm-activity {
      ul.dates {
        li {
          + li {
            border-left: 1px solid $dark-border-color;
          }
        }
      }
    }
    .server-widgets {
      .bottom-server {
        h5 {
          .second-color {
            span {
              color: $dark-all-font-color;
            }
          }
        }
      }
    }
    .progress {
      background-color: $dark-body-background;
    }
    .crypto-accordion {
      .accordion {
        .accordion-item {
          border: 1px solid $dark-border-color;
          .panel {
            .media-accordion {
              .d-flex {
                background-color: $dark-card-background;
                + .d-flex {
                  border-top: 1px solid $dark-border-color;
                }
                .flex-grow-1 {
                  p {
                    color: $dark-all-font-color;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &.dark-sidebar-body-mix {
    color: $dark-all-font-color;
    background-color: $dark-body-background;
    .offcanvas-bookmark {
      &.page-wrapper {
        .page-body-wrapper {
          .page-body {
            .bookmark {
              ul {
                li {
                  .Typeahead-menu {
                    a {
                      color: $dark-small-font-color;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .ProfileCard {
      &:hover {
        background: #f8f8f8;
      }
    }
    .kUKEuI {
      background-color: $dark-body-background;
    }
    .bqNMrp {
      border-bottom: 1px solid $dark-border-color;
    }
    .vertical-timeline-element-content {
      background-color: $dark-border-color !important;
      .vertical-timeline-element-content-arrow {
        border-right: 7px solid $dark-border-color !important;
      }
    }
    .vertical-timeline-element-icon {
      box-shadow: 0 0 0 4px $dark-border-color, inset 0 2px 0 rgba($black, 0.08),
        0 3px 0 4px rgba($black, 0.05);
    }
    .vertical-timeline {
      &::before {
        background: $dark-border-color !important;
      }
    }
    .sweet-alert,
    .reactour__helper,
    .fileContainer,
    .ceCBVJ {
      background-color: $dark-card-background !important;
    }
    .rbc-month-view,
    .rbc-time-view {
      border: 1px solid $dark-border-color;
    }
    .rbc-toolbar {
      button {
        color: $dark-small-font-color;
        &:hover,
        &:focus {
          background-color: $primary-color;
          color: $white;
        }
      }
    }
    .rbc-month-row {
      + .rbc-month-row {
        border-top: 1px solid $dark-border-color;
      }
    }
    .rbc-off-range-bg {
      background: $dark-body-background;
    }
    .rbc-day-bg {
      + .rbc-day-bg {
        border-left: 1px solid $dark-border-color;
      }
    }
    .rbc-header,
    .rbc-timeslot-group {
      border-bottom: 1px solid $dark-border-color;
      + .rbc-header {
        border-left: 1px solid $dark-border-color;
      }
    }
    .rbc-time-content {
      border-top: 2px solid $dark-border-color;
    }
    .rbc-agenda-view {
      table {
        &.rbc-agenda-table {
          border: 1px solid $dark-border-color;
          tbody {
            > tr {
              > td {
                + td {
                  border-left: 1px solid $dark-border-color;
                }
              }
            }
          }
          thead {
            > tr {
              > th {
                border-bottom: 1px solid $dark-border-color;
              }
            }
          }
        }
      }
    }
    .rbc-day-slot {
      .rbc-time-slot {
        border-top: 1px solid $dark-border-color;
      }
    }
    .rbc-time-header-content,
    .rbc-time-content > * + * > * {
      border-left: $dark-border-color;
    }
    #external-events {
      border: 1px solid $dark-border-color;
    }
    .fc-unthemed {
      th,
      td,
      thead,
      tbody,
      .fc-divider,
      .fc-row,
      .fc-content,
      .fc-popover,
      .fc-list-view,
      .fc-list-heading td {
        border-color: $dark-border-color;
      }
      td {
        &.fc-today {
          background: $dark-body-background;
        }
      }
      .fc-divider,
      .fc-popover,
      .fc-list-heading {
        background-color: $dark-border-color;
        .fc-header,
        td {
          background-color: $dark-border-color;
        }
      }
    }
    .email-wrap {
      .email-app-sidebar {
        .main-menu {
          > li {
            a {
              &.active,
              &:hover {
                background: $dark-body-background;
              }
            }
          }
        }
      }
    }
    .list-persons {
      .profile-mail {
        .d-flex {
          .flex-grow-1 {
            ul {
              li {
                + li {
                  border-left: 2px solid $dark-border-color;
                }
              }
            }
          }
        }
      }
    }
    .contacts-tabs {
      .nav-pills {
        border-right: 1px solid $dark-border-color;
        .nav-link {
          + .nav-link {
            border-top: 1px solid $dark-border-color;
          }
        }
      }
    }
    .contact-profile {
      .icon-wrapper {
        background-color: $dark-body-background;
      }
    }
    .social-app-profile {
      .market-tabs {
        border-top: none;
      }
    }
    .browse {
      .browse-articles {
        h6 {
          border-bottom: 1px solid $dark-border-color;
        }
      }
    }
    .my-gallery {
      &.gallery-with-description {
        .caption {
          border: 1px solid $dark-border-color;
        }
        figure {
          .img-thumbnail {
            background-color: $dark-card-background !important;
          }
        }
      }
    }
    .masonry-with-dec {
      .my-masonry-grid_column {
        li {
          img,
          .caption {
            border: 1px solid $dark-border-color;
          }
        }
      }
    }
    .form-group {
      .form-control {
        background-color: $dark-body-background;
      }
    }
    .table-primary,
    .table-secondary,
    .table-success,
    .table-info,
    .table-warning,
    .table-danger,
    .table-light {
      th,
      td {
        color: $black !important;
      }
    }
    @each $border-tab-name, $border-tab-color in (primary, $primary-color),
      (secondary, $secondary-color), (success, $success-color),
      (danger, $danger-color), (info, $info-color), (light, $light-color),
      (dark, $dark-color), (warning, $warning-color)
    {
      .borderb-tab-#{$border-tab-name} {
        .nav-tabs {
          &.nav-item {
            .nav-link {
              color: $dark-all-font-color;
            }
          }
          .nav-link {
            &.active {
              color: $border-tab-color;
              border-bottom: 2px solid $border-tab-color !important;
            }
          }
        }
      }
    }
    ol {
      &.progtrckr {
        li {
          &.progtrckr-doing,
          &.progtrckr-done {
            color: $dark-small-font-color;
          }
          &.progtrckr-todo {
            &:before {
              background-color: $dark-card-background;
            }
          }
        }
      }
    }
    .search-page {
      ul {
        &.search-info {
          li {
            + li {
              border-left: 1px solid $dark-border-color;
            }
          }
        }
      }
    }
    .styles_modal__gNwvD {
      background-color: $dark-card-background;
    }
    .product-size {
      ul {
        li {
          button {
            color: $dark-small-font-color;
          }
        }
      }
    }
    .product-wrapper {
      &.sidebaron {
        .product-sidebar {
          .filter-section {
            .card {
              .left-filter {
                background-color: $dark-card-background;
              }
            }
          }
        }
      }
    }
    .mega-inline,
    .mega-horizontal {
      .card {
        border: 1px solid $dark-border-color;
      }
    }

    // draggable card
    .react-kanban-column {
      background-color: $dark-body-background;
    }
    .react-kanban-card,
    .react-kanban-card-adder-form,
    .react-kanban-card-skeleton {
      background-color: $dark-card-background;
    }
    .react-kanban-card__title {
      border-bottom: 1px solid $dark-border-color;
    }
    .draggable-card {
      .react-kanban-card__title {
        border-bottom: none;
      }
    }

    .input-cropper {
      border: 1px solid $dark-border-color;
    }
    .dzu-previewContainer {
      border-bottom: 1px solid #665f5f;
    }
    .dzu-previewButton {
      filter: brightness(0.5) invert(1);
    }

    // react datatable
    .rdt_Table {
      border: 1px solid $dark-border-color;
    }
    .rdt_TableHeadRow {
      background-color: $dark-card-background;
    }
    .rdt_TableCol,
    .rdt_TableCol_Sortable,
    .___react-data-table-allow-propagation___ {
      color: $dark-all-font-color;
    }
    .rdt_TableRow {
      &:nth-of-type(odd) {
        color: $dark-all-font-color;
        background-color: $dark-body-background;
      }
      &:nth-of-type(even) {
        color: $dark-all-font-color;
        background-color: $dark-card-background;
      }
    }
    .fpZHHx {
      fill: $dark-all-font-color;
      color: $dark-all-font-color;
    }
    .rdt_Pagination {
      background-color: $dark-card-background;
      border-top-color: $dark-border-color;
      color: $dark-all-font-color;
      button {
        color: $dark-all-font-color;
        fill: $dark-all-font-color;
        &:disabled {
          color: $dark-small-font-color;
          fill: $dark-small-font-color;
        }
      }
    }
    .rdt_TableCol_Sortable {
      &:hover {
        color: $dark-all-font-color;
      }
    }
    .rdt_TableHeader {
      background-color: $dark-body-background !important;
      > div {
        &:last-child {
          background-color: $dark-body-background !important;
          color: $dark-all-font-color;
        }
      }
    }

    .popover-header {
      color: $theme-body-font-color;
    }
    .grid-showcase {
      span {
        background-color: $dark-card-background;
        border: 1px solid $dark-border-color;
      }
    }
    .form-control {
      background-color: $dark-body-background;
      border: 1px solid $dark-border-color;
      color: $dark-all-font-color;
    }
    .dropdown-menu {
      background-color: $dark-body-background !important;
    }
    .dropdown-item,
    span.twitter-typeahead .tt-suggestion {
      color: $dark-all-font-color;
      &:hover,
      &:focus,
      &.active {
        background-color: $dark-card-background !important;
        color: $dark-all-font-color !important;
      }
    }

    // kanban board
    .kanban-container {
      .kanban-item {
        .kanban-box {
          background-color: $dark-card-background;
        }
      }
    }
    .jkanban-container {
      .kanban-container {
        .kanban-item {
          .kanban-box {
            border: 1px solid $dark-border-color;
          }
        }
      }
    }

    .modal-header {
      .close {
        color: $dark-small-font-color;
      }
    }
    .modal-footer {
      border-top: 1px solid $dark-border-color;
    }
    .default-according {
      .list-group-item {
        border: 1px solid $dark-border-color !important;
      }
    }
    .tab-vertical {
      .nav-tabs {
        border: 1px solid hsla(0, 0%, 100%, 0.3);
        .nav-link {
          + .nav-link {
            border-top: 1px solid hsla(0, 0%, 100%, 0.3);
          }
          &:hover,
          &:focus {
            background-color: $dark-body-background;
          }
        }
      }
    }
    .bar-chart-widget {
      .num {
        .color-bottom {
          span {
            color: $dark-all-font-color;
          }
        }
      }
    }
    .ecommerce-widget {
      border: 1px solid $dark-border-color;
    }
    .bookmark {
      ul {
        li {
          .search-form {
            .form-control-search {
              input {
                background-color: $dark-body-background;
                border: 1px solid $dark-border-color;
                color: $dark-all-font-color;
              }
              &:before {
                background: $dark-border-color;
              }
            }
          }
        }
      }
    }
    .cart {
      .qty-box {
        .input-group {
          .btn {
            background-color: $dark-border-color !important;
            border-color: $dark-border-color !important;
          }
        }
      }
    }
    .checkout {
      .checkout-details {
        background-color: $dark-body-background;
        border: 1px solid $dark-border-color;
        padding: 40px;
      }
    }
    .order-box {
      .title-box {
        color: #bfc2c6;
        border-bottom: 1px solid $dark-border-color;
      }
      .qty {
        li {
          color: #bfc2c6;
          span {
            color: #bfc2c6;
          }
        }
        border-bottom: 1px solid $dark-border-color;
      }
      .sub-total {
        li {
          color: #bfc2c6;
        }
      }
      .total {
        li {
          color: #bfc2c6;
        }
      }
    }
    .shopping-checkout-option {
      .checkbox_animated {
        &:after {
          border: 2px solid $dark-border-color;
        }
      }
    }
    .animate-chk {
      .radio_animated {
        &:after {
          border: 2px solid $dark-border-color;
        }
      }
    }

    .radio_animated,
    .checkbox_animated {
      &:after {
        background: $dark-body-background;
        border: 2px solid $dark-body-background;
      }
    }
    .card {
      .card-header {
        .card-header-right {
          background-color: $dark-card-background;
        }
      }
    }
    .product-box {
      .product-img {
        .product-hover {
          ul {
            li {
              .btn {
                color: $dark-small-font-color;
              }
              &:hover {
                .btn {
                  color: $white;
                }
              }
            }
          }
        }
      }
    }
    .slider-product {
      border-top: 1px solid $dark-border-color;
      border-bottom: 1px solid $dark-border-color;
    }
    .square-product-setting {
      .icon-grid {
        background-color: $dark-card-background;
        svg {
          color: $dark-all-font-color;
        }
      }
    }
    .bitcoin-form {
      .bitcoin-form-dropdown {
        .onhover-dropdown {
          .btn {
            background-color: $dark-border-color;
            color: $dark-all-font-color;
          }
          .onhover-show-div {
            background-color: $dark-body-background;
            box-shadow: 0 0 2px 2px $dark-body-background;
          }
        }
      }
      .form-row {
        .input-group {
          .input-group-prepend {
            .input-group-text {
              border: 1px solid $dark-border-color;
            }
          }
        }
      }
    }
    .active-order-table,
    .market-table {
      table {
        thead {
          tr {
            th {
              border-top: none !important;
            }
          }
        }
        tbody {
          tr {
            td {
              border-bottom: 1px solid $dark-border-color;
            }
            &:last-child {
              td {
                border-bottom: none;
              }
            }
          }
        }
      }
    }
    .pricing-card-design-2 {
      .pricing-block {
        .pricing-inner {
          ul {
            li {
              border-bottom: 1px solid $dark-border-color;
            }
          }
        }
      }
    }
    .flot-chart-container {
      .legend {
        .table {
          tbody {
            background-color: $dark-card-background;
            color: $white;
            .legendLabel {
              padding-left: 5px;
            }
          }
        }
      }
    }
    .nav-dark,
    .nav-pills.nav-dark {
      .nav-link {
        color: $white;
      }
    }
    // project
    .theme-tab {
      .tab-title {
        border-bottom: 1px solid $dark-border-color;
        li {
          &.react-tabs__tab--selected {
            background-color: $dark-card-background;
          }
        }
      }
    }
    .schedule {
      .d-flex {
        .schedule-dropdown {
          .dropdown {
            background-color: $dark-body-background;
          }
        }
      }
    }
    // datatable
    .ReactTable {
      border: 1px solid $dark-border-color;
      .rt-table {
        .rt-thead {
          border-bottom: 1px solid $dark-border-color;
        }
        .rt-tbody {
          .rt-tr-group {
            .rt-tr {
              &.-odd {
                background-color: $dark-body-background;
              }
              &:hover {
                background-color: #28303c;
              }
            }
          }
        }
      }
      .-pagination {
        border-top: 1px solid $dark-border-color !important;
        .-btn {
          color: $dark-small-font-color;
        }
        input,
        select {
          background: $dark-body-background;
          color: $white;
        }
      }
    }
    .page-wrapper {
      /* main-header start*/
      .page-main-header {
        color: $black;
        .default-according {
          .card {
            .btn-link {
              background-color: $white;
              border: 1px solid $light-color;
              color: $black;
            }
          }
        }
        .collapse {
          .card-body {
            background-color: $white;
          }
        }
        .theme-form {
          .form-group {
            input[type="text"],
            input[type="email"],
            textarea {
              border-color: $light-color;
              background-color: $white;
              color: $black;
            }
            label {
              color: $black;
            }
          }
        }
        #accordionoc {
          #collapseicon,
          #collapseicon1 {
            background-color: $white;
          }
        }
        @media only screen and (max-width: 575px) {
          .nav-right {
            > ul {
              .search-form {
                .form-control-plaintext {
                  background-color: $dark-body-background;
                  border: 1px solid $dark-border-color;
                  color: $dark-all-font-color;
                }
              }
            }
          }
        }
      }
      .right-sidebar {
        background-color: $dark-body-background;
        box-shadow: 0 0 11px rgba(69, 110, 243, 0.13);
        .modal-header {
          border-bottom: 1px solid $dark-border-color;
        }
        .friend-list-search {
          background-color: $dark-card-background;
          input {
            background-color: $dark-body-background;
            border: 1px solid $dark-border-color;
          }
        }
      }
      .page-body-wrapper {
        /* sidebar start */
        &.sidebar-icon {
          .page-sidebar {
            .sidebar-menu {
              .sidebar-header {
                > i {
                  color: $dark-all-font-color;
                }
              }
              li {
                background-color: $dark-card-background;
                &:hover {
                  .single-header,
                  .sidebar-submenu {
                    background-color: $dark-card-background;
                  }
                }
                a:hover + ul {
                  background-color: $dark-card-background;
                }
              }
            }
            .sidebar-user {
              box-shadow: 3px 2px 7px -1px rgb(41, 50, 64);
            }
          }
        }
        .page-sidebar {
          background: $dark-card-background;
          .main-header-left {
            background-color: $white;
          }
          &.native-image-bg {
            background-blend-mode: overlay;
            background-color: #17191d;
          }
          &.navigation-bordered {
            .sidebar-header {
              border-bottom: 1px solid rgba(241, 241, 241, 0.35);
            }
          }
          .sidebar-user {
            background-color: $dark-card-background;
            box-shadow: 3px 2px 8px -1px rgba(255, 255, 255, 0.13);
            h6 {
              color: $light-color;
            }
            p {
              color: $white;
            }
          }
          .sidebar-menu {
            .sidebar-title {
              color: $primary-color;
              border-bottom: 1px solid rgba(241, 241, 241, 0.35);
            }
            > li {
              > a {
                color: $dark-all-font-color;
                &.active {
                  color: $primary-color;
                }
                &:hover {
                  color: $primary-color;
                }
              }
              .label {
                margin-top: 3px;
                margin-right: 5px;
              }
              &:hover > a {
                @extend %common;
                color: $dark-all-font-color;
              }
            }
            li {
              &.sidebar-header {
                color: $warning-color;
              }
            }
            a {
              color: $light-color;
            }
            .sidebar-submenu {
              > li {
                > a {
                  color: $sidebar-submenu-font-color;
                  &:hover {
                    color: $primary-color;
                  }
                  &.active {
                    color: $primary-color;
                  }
                }
              }
            }
          }
          .sidebar-widget {
            border-top: 1px solid $dark-border-color;
            .sidebar-widget-top {
              i {
                background-color: $dark-card-background;
              }
            }
            .sidebar-widget-bottom {
              background-color: $dark-body-background;
            }
          }
        }
        /* sidebar end */
        .page-body {
          .topper-lists {
            table {
              tbody {
                tr {
                  td {
                    p {
                      color: $dark-small-font-color;
                    }
                  }
                }
              }
            }
          }
          .header-small {
            color: $dark-small-font-color;
          }
          .statistics {
            p {
              color: $dark-small-font-color;
            }
          }
          .feather-main,
          .professor-table {
            .flex-grow-1,
            .professor-block {
              p {
                color: $dark-small-font-color;
              }
            }
          }
          .logs-element {
            span {
              + span {
                color: $dark-small-font-color;
              }
            }
          }
          .progress-block {
            .progress-title {
              span {
                + span {
                  color: $dark-small-font-color;
                }
              }
            }
          }
          .ecommerce-icons {
            div {
              span {
                color: $dark-small-font-color;
              }
            }
          }
          .new-users,
          .recent-notification {
            .d-flex {
              .flex-grow-1 {
                p {
                  color: $dark-small-font-color;
                }
              }
            }
          }
          .progress-media {
            .d-flex {
              .flex-grow-1 {
                span {
                  color: $dark-small-font-color;
                }
              }
            }
            .progress-change {
              span {
                color: $dark-small-font-color;
              }
            }
          }
          .topper-lists {
            table {
              tbody {
                tr {
                  td {
                    border-top: none !important;
                    border-bottom: 1px solid $dark-border-color;
                  }
                  &:last-child {
                    td {
                      border-bottom: none;
                    }
                  }
                }
              }
            }
          }
          .notifiaction-media {
            .d-flex {
              .flex-grow-1 {
                .circle-left {
                  border: 5px solid #334154;
                }
              }
            }
          }
          .upcoming-event {
            .upcoming-innner {
              border-bottom: 1px solid $dark-border-color;
              &:last-child {
                border-bottom: none;
              }
            }
          }
          .professor-table {
            table {
              tbody {
                tr {
                  td {
                    border-top: none !important;
                  }
                }
              }
            }
          }
          .number-widgets {
            .d-flex {
              .flex-grow-1 {
                h6 {
                  color: $dark-all-font-color;
                }
              }
            }
          }
          .activity {
            .d-flex {
              .gradient-round {
                &.gradient-line-1,
                &.small-line,
                &.medium-line {
                  &:after {
                    background-color: $dark-border-color;
                  }
                }
              }
              .flex-grow-1 {
                h6 {
                  span {
                    color: $dark-small-font-color;
                  }
                }
              }
            }
          }
          .card.custom-card {
            .card-header {
              border-bottom: none !important;
            }
          }
        }
        .custom-card .card-header img {
          background-color: $black;
          opacity: 0.8;
        }
        /* body start*/
        .page-header {
          .row {
            h3 {
              small {
                color: $dark-small-font-color;
              }
            }
          }
          .breadcrumb-item {
            &.active {
              color: $dark-small-font-color;
            }
          }
        }
        .page-body {
          background-color: $dark-body-background;
          .card {
            background-color: $dark-card-background;
            box-shadow: 0 0 1px 1px #374558;
            .card-header {
              background-color: $dark-card-background;
              border-bottom: 1px solid $dark-border-color;
              .buttons-right {
                .right-header-dropdown.onhover-show-div {
                  background-color: $dark-body-background;
                  box-shadow: 0 0 2px 2px $dark-body-background;
                  a {
                    color: $dark-all-font-color;
                  }
                }
              }
              &.card-header-border {
                .right-header {
                  span {
                    &:first-child {
                      color: $dark-small-font-color;
                    }
                  }
                  .right-header-dropdown.onhover-show-div {
                    background-color: $dark-body-background;
                    box-shadow: 0 0 2px 2px $dark-body-background;
                    a {
                      color: $dark-all-font-color;
                    }
                  }
                }
              }
              > span {
                color: $dark-all-font-color;
              }
              h1,
              h2,
              h3,
              h4,
              h5,
              h6 {
                color: $dark-all-font-color;
              }
              .card-header-right {
                i {
                  color: $dark-all-font-color;
                }
              }
            }
            .alert-dark {
              color: $dark-small-font-color;
              a {
                color: $dark-small-font-color;
              }
            }
            .alert-light.outline,
            .alert-light.outline-2x,
            .alert-light.inverse {
              color: $dark-all-font-color;
            }
            #animation-box {
              .animate-widget {
                p {
                  color: $dark-small-font-color !important;
                }
              }
            }
            .grid-item {
              img {
                background-color: $dark-card-background;
                border: 1px solid $dark-border-color;
              }
            }
            .line {
              color: $dark-all-font-color;
            }
            .table {
              th,
              td {
                color: $dark-all-font-color;
              }
              thead {
                th {
                  border-bottom: 2px solid $dark-border-color;
                }
                .border-bottom-primary {
                  th {
                    border-bottom: 1px solid $primary-color;
                  }
                }
              }
              .table-primary,
              .table-secondary,
              .table-success,
              .table-info,
              .table-warning,
              .table-light,
              .table-danger {
                th,
                td {
                  color: $sidebar-submenu-font-color;
                }
              }
              .bg-primary,
              .bg-secondary,
              .bg-success,
              .bg-info,
              .bg-warning,
              .bg-danger {
                color: $white;
              }
              .bg-light {
                color: $black;
              }
              .thead-light {
                th {
                  color: $black;
                }
              }
              .double,
              .dotted,
              .dashed {
                border-color: $dark-border-color;
              }
              tbody {
                .border-bottom-primary {
                  th,
                  td {
                    border-bottom: 1px solid $primary-color;
                  }
                }
              }
            }
            .table-responsive {
              .table {
                th,
                td {
                  border-top: 1px solid $dark-border-color;
                }
              }
            }
            .table[class*="bg-"] {
              th,
              td {
                color: $white;
              }
            }
            .table-striped {
              tbody {
                tr {
                  &:nth-of-type(odd) {
                    background-color: rgba(0, 0, 0, 0.05);
                    &:hover {
                      th,
                      td {
                        color: $white;
                      }
                    }
                  }
                }
              }
            }
            .table-bordered {
              td,
              th {
                border-color: $dark-border-color;
              }
            }
            .table-inverse {
              tbody {
                tr {
                  &:nth-of-type(odd) {
                    &:hover {
                      td {
                        color: $dark-all-font-color;
                      }
                    }
                  }
                }
              }
            }
            .table-border-vertical {
              tr,
              th,
              td {
                border-right: 1px solid $dark-border-color;
              }
            }
            .table-styling {
              thead,
              tbody {
                th,
                td {
                  color: $white;
                }
              }
            }
            .card-footer {
              background-color: $dark-card-background;
              border-top: 1px solid $dark-border-color;
            }
            .bg-white {
              background-color: $dark-card-background !important;
            }
            .b-l-light {
              border-left: 1px solid $dark-border-color !important;
            }
            .ct-grid {
              stroke: $dark-small-font-color;
            }
            .ct-label {
              color: $dark-small-font-color;
            }
            hr {
              border-top: 1px solid $dark-border-color;
            }
            .text-muted {
              color: $sidebar-submenu-font-color !important;
            }
            .calender-widget {
              .cal-date {
                background-color: $dark-card-background;
                border: 1px solid $dark-border-color;
              }
            }
            .contact-form {
              .theme-form {
                border: 1px solid $dark-border-color;
                .form-icon {
                  background-color: $dark-card-background;
                  border: 1px solid $dark-border-color;
                  color: $dark-all-font-color;
                }
              }
            }
            .btn-outline-light,
            .btn-outline-dark {
              color: $white !important;
            }
            .btn-outline-light {
              &:hover {
                color: $black !important;
              }
            }
            .border-right {
              border-right: 1px solid $dark-border-color !important;
            }
            .flot-chart-placeholder {
              .flot-text {
                color: $dark-all-font-color !important;
              }
              svg {
                text {
                  fill: $dark-all-font-color;
                }
              }
            }
            .chart-overflow {
              &#line-chart,
              &#column-chart1,
              &#column-chart2 {
                rect {
                  fill: $dark-card-background;
                }
              }
              svg {
                > rect {
                  fill: $dark-card-background;
                }
                > g {
                  > g {
                    > g {
                      text {
                        fill: rgb(177, 177, 178);
                      }
                    }
                  }
                  > text {
                    fill: $dark-all-font-color;
                  }
                }
              }
            }
            .radial-bar {
              &:after {
                background-color: $dark-card-background;
                color: $dark-all-font-color;
              }
            }
            .bar-chart-widget {
              .bottom-content {
                .num {
                  color: $dark-all-font-color;
                  .color-bottom {
                    color: $dark-all-font-color;
                  }
                }
              }
            }
            .b-r-light {
              border-right: 1px solid $dark-border-color !important;
            }
            .chart-container {
              .live-products,
              .turnover,
              .monthly,
              .uses {
                .ct-labels {
                  .ct-label {
                    color: $white;
                  }
                }
                .ct-grid {
                  stroke: $white;
                }
              }
              #browser-uses-chart,
              #website-visiter-chart {
                svg {
                  text {
                    fill: $dark-all-font-color;
                  }
                }
              }
            }
            .status-details {
              h4 {
                color: $dark-all-font-color;
                span {
                  color: $dark-all-font-color;
                }
              }
              span {
                color: $dark-small-font-color;
              }
            }
            .clipboaard-container {
              .form-control {
                background-color: $dark-body-background;
                color: $dark-small-font-color;
                border: 1px solid $dark-border-color;
              }
            }
            .img-thumbnail {
              background-color: $dark-body-background;
              border: 1px solid $dark-border-color;
            }
            .page-item.disabled {
              .page-link {
                background-color: $dark-card-background;
                border-color: $dark-border-color;
              }
            }
            .page-link {
              background-color: $dark-card-background;
              border: 1px solid $dark-border-color;
              &:hover {
                background-color: $dark-border-color;
              }
            }
            .page-item.active {
              .page-link {
                background-color: $primary-color;
              }
            }
            .ecommerce-widget {
              .icon {
                color: $dark-body-background;
              }
              .total-num {
                color: $dark-all-font-color;
                span {
                  color: $dark-all-font-color;
                }
              }
              span {
                color: $dark-small-font-color;
              }
            }
            .flot-chart-container-small {
              background-color: $dark-card-background;
              border-top: 1px solid $dark-border-color;
            }
            .product-table {
              #API-2_wrapper {
                #API-2 {
                  tbody {
                    td {
                      span,
                      p {
                        color: $dark-small-font-color;
                      }
                    }
                  }
                }
              }
              h6 {
                color: $dark-small-font-color;
              }
            }
            .scroll-demo {
              border: 1px solid $dark-border-color;
            }
            .search-form {
              input {
                border: 1px solid $dark-border-color;
                color: $dark-all-font-color;
                background-color: $dark-body-background;
              }
              .form-group {
                &:before {
                  background: $dark-card-background;
                }
              }
            }
            .cd-timeline-content {
              background-color: $dark-border-color;
              &::before {
                border-left: 7px solid $dark-border-color;
              }
            }
            .cd-timeline-block {
              &:nth-child(even) {
                .cd-timeline-content {
                  &::before {
                    border-right-color: $dark-border-color;
                    border-left: transparent;
                  }
                }
              }
            }
            .breadcrumb {
              background-color: $dark-body-background;
            }
            .user-status {
              table {
                td,
                th {
                  border-top: none !important;
                }
              }
            }
            #donut-color-chart-morris-daily,
            #donut-color-chart-morris,
            #browser-uses-chart,
            #website-visiter-chart {
              svg {
                opacity: 0.5;
              }
            }
          }
          .gallery-with-description {
            h4 {
              color: $dark-all-font-color;
            }
            a {
              > div {
                border: 1px solid $dark-border-color;
              }
            }
            p {
              color: $dark-small-font-color;
            }
          }
          .sub-title {
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          }
          .navs-icon {
            li {
              a {
                color: $dark-all-font-color;
                &:hover {
                  color: $primary-color;
                }
              }
            }
            .separator {
              border: 1px solid $dark-border-color;
            }
          }
          .default-according {
            li {
              .text-muted {
                color: $dark-all-font-color !important;
              }
            }
          }
          .navs-icon.default-according.style-1 {
            li {
              button {
                &:hover {
                  color: $primary-color !important;
                }
              }
            }
          }
          .nav-list {
            .nav-list-disc {
              li {
                a {
                  color: $dark-all-font-color;
                }
                &:hover {
                  color: $primary-color;
                  a {
                    color: $primary-color;
                  }
                }
              }
            }
          }
          .navs-dropdown {
            .onhover-show-div {
              background-color: $dark-body-background;
              box-shadow: 0 0 2px 2px $dark-border-color;
              .navs-icon {
                li {
                  a {
                    svg {
                      path,
                      line,
                      polyline,
                      polygon,
                      rect {
                        color: $dark-all-font-color !important;
                      }
                    }
                    &:hover {
                      svg {
                        path,
                        line,
                        polyline,
                        polygon,
                        rect {
                          color: $primary-color !important;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          .onhover-dropdown {
            &:hover {
              .onhover-show-div {
                &:before {
                  border-bottom: 7px solid $dark-body-background;
                }
              }
            }
          }
          .default-according {
            .card {
              box-shadow: 0px 0px 1px 1px rgb(55, 66, 82);
              .btn-link {
                color: $dark-all-font-color;
              }
              .card-body {
                color: $dark-small-font-color;
              }
            }
          }
          .border {
            border: 1px solid $dark-border-color !important;
          }
          .blog-box {
            .blog-date {
              color: $dark-all-font-color;
            }
            .blog-details,
            .blog-details-main {
              .blog-social {
                li {
                  color: $dark-small-font-color;
                  border-right: 1px solid $dark-border-color;
                  &:first-child {
                    border-right: 1px solid $dark-border-color;
                  }
                  &:last-child {
                    border-right: none;
                  }
                }
              }
              p {
                color: $dark-all-font-color;
              }
              .single-blog-content-top {
                border-top: 1px solid $dark-border-color;
                p {
                  color: $dark-small-font-color;
                }
              }
            }
          }
          .comment-box {
            .d-flex {
              h6 {
                span {
                  color: $dark-small-font-color;
                }
              }
              img {
                border: 1px solid $dark-border-color;
              }
            }
            p {
              color: $dark-small-font-color;
            }
            .comment-social {
              li {
                color: $dark-small-font-color;
                &:first-child {
                  border-right: 1px solid $dark-border-color;
                }
              }
            }
            hr {
              border-top: 1px solid $dark-border-color;
            }
          }
          .table-hover {
            tbody {
              tr {
                &:hover {
                  background-color: $dark-body-background;
                  td,
                  th {
                    color: $white;
                  }
                }
              }
            }
          }
          ul.the-icons {
            li {
              border: 1px dotted $dark-border-color;
              color: $sidebar-submenu-font-color;
              &:hover {
                background: $black;
                box-shadow: 0 0 3px $dark-card-background;
              }
            }
          }
          .crm-activity {
            > li {
              + li {
                border-top: 1px solid $dark-border-color;
              }
              h6 {
                color: $dark-all-font-color;
              }
            }
            ul.dates {
              li {
                color: $dark-small-font-color;
              }
            }
          }
          #donut-color-chart-morris,
          #donut-color-chart-morris-daily {
            svg {
              text {
                fill: $dark-all-font-color;
              }
            }
          }
          .custom-card {
            .card-profile {
              img {
                background-color: $dark-card-background;
              }
            }
            .profile-details {
              h6 {
                color: $dark-small-font-color;
              }
            }
            .card-social {
              li {
                a {
                  color: $dark-small-font-color;
                }
              }
            }
            .card-footer {
              > div {
                h6 {
                  color: $dark-small-font-color;
                }
                + div {
                  border-left: 1px solid $dark-border-color;
                }
              }
            }
          }
          .form-control {
            background-color: $dark-body-background;
            color: $dark-all-font-color;
            border: 1px solid $dark-border-color !important;
          }
          .select2-drpdwn-project {
            .form-control {
              background-color: $dark-card-background !important;
              border-bottom: 1px solid $primary-color !important;
            }
          }
          .checkbox,
          .radio {
            label {
              &::before {
                background-color: $dark-body-background;
                border: 1px solid $dark-border-color;
                color: $dark-all-font-color;
              }
            }
          }
          .dropdown-basic {
            .dropdown {
              .dropdown-content {
                background-color: $dark-body-background;
                a {
                  color: $dark-all-font-color;
                  &:hover {
                    background-color: $dark-card-background;
                  }
                }
                .dropdown-divider {
                  border: 1px solid $dark-border-color;
                }
              }
            }
          }
          .dropup-basic {
            .dropup {
              .dropup-content {
                background-color: $dark-body-background;
                a {
                  color: $dark-all-font-color;
                  &:hover,
                  &.active {
                    background-color: $dark-card-background;
                  }
                }
              }
            }
          }
          .dropzone.dz-clickable {
            .dz-message {
              h6 {
                color: $dark-all-font-color;
              }
              span {
                color: $dark-small-font-color;
              }
            }
          }
          .dropzone {
            .dz-preview {
              background-color: $dark-body-background;
              .dz-details {
                background-color: $dark-card-background;
              }
            }
          }
          .browser-widget {
            .flex-grow-1 {
              column-rule: 1px solid $dark-border-color;
              h4 {
                color: $dark-all-font-color;
                span {
                  color: $dark-all-font-color;
                }
              }
              span {
                color: $dark-small-font-color;
              }
            }
          }
          .email-wrap {
            .email-app-sidebar {
              .d-flex {
                img {
                  border: 2px solid $dark-body-background;
                }
              }
              .main-menu {
                > li {
                  a {
                    color: $dark-all-font-color;
                    &:hover {
                      background-color: $dark-body-background;
                    }
                  }
                }
              }
            }
            .email-right-aside {
              .email-body {
                .inbox {
                  .d-flex.active {
                    background-color: $dark-body-background;
                  }
                }
              }
            }
            .flex-grow-1 {
              h6 {
                color: $dark-all-font-color;
                small {
                  color: $dark-small-font-color;
                  span {
                    color: $dark-small-font-color;
                  }
                }
              }
              p {
                color: $dark-small-font-color;
              }
            }
            .email-top {
              border-bottom: 1px solid $dark-border-color;
            }
            p {
              color: $dark-small-font-color;
            }
            .email-content {
              .email-top {
                .user-emailid:after {
                  border: 1px solid $dark-border-color;
                }
              }
            }
          }
          .dropdown-menu {
            background-color: $dark-body-background;
            border: 1px solid $dark-border-color;
            a {
              color: $dark-all-font-color;
              &:hover {
                background-color: $dark-card-background;
              }
            }
          }
          .icon-lists {
            div {
              i {
                color: $dark-small-font-color;
              }
              &:hover {
                background-color: $dark-body-background;
              }
            }
          }
          .widget-joins {
            .d-flex {
              .flex-grow-1 {
                > span {
                  color: $dark-small-font-color;
                }
              }
              .details {
                border-left: 1px solid $dark-border-color;
              }
            }
            &:before,
            &:after {
              background-color: $dark-border-color;
            }
          }
          .redial-social-widget {
            i {
              background-color: $dark-card-background;
            }
          }
          .social-widget-card {
            h5,
            h4 {
              color: $dark-all-font-color;
            }
            span {
              color: $dark-small-font-color;
            }
          }
          .b-b-light {
            border-bottom: 1px solid $dark-border-color !important;
          }
          .b-r-dark {
            border-right: 1px solid $white !important;
          }
          .testimonial {
            i {
              color: $dark-border-color;
            }
            p {
              color: $dark-all-font-color;
            }
            h5 {
              color: $dark-all-font-color;
            }
            span {
              color: $dark-small-font-color;
            }
          }
          .grid-showcase {
            span {
              background-color: $dark-card-background;
              border: 1px solid $dark-border-color;
            }
          }
          .grid-align {
            .row {
              background-color: $dark-border-color;
              border: 1px solid $dark-border-color;
            }
          }
          .border-style {
            .card {
              border: 1px solid $dark-border-color;
            }
          }
          .offer-style {
            .card {
              border: 1px dashed $dark-border-color;
            }
          }
          .search-page {
            .info-block {
              + .info-block {
                border-top: 1px solid $dark-border-color;
              }
            }
          }
          .card-absolute {
            .bg-primary,
            .bg-secondary {
              h5 {
                color: $white;
              }
            }
          }
          .timeline-small {
            .d-flex {
              .timeline-round {
                &.timeline-line-1,
                &.small-line,
                &.medium-line {
                  &:after {
                    background-color: $dark-border-color;
                  }
                }
              }
            }
          }
          /* body end*/
        }
        footer {
          border-top: 1px solid $dark-body-background;
          background-color: $dark-card-background;
        }
        .custom-select,
        .custom-file-label {
          background: $dark-body-background;
          color: $dark-all-font-color;
        }
        .footer-fix {
          background-color: $dark-card-background;
          border-top: 1px solid $dark-border-color;
        }
      }
      .chat-box {
        .about {
          .name {
            color: $dark-all-font-color;
          }
        }
        .chat-menu {
          border-left: 1px solid $dark-border-color;
          .nav-tabs {
            border-bottom: 1px solid $dark-border-color;
            .nav-item {
              .nav-link {
                &.active {
                  color: $dark-all-font-color !important;
                }
              }
            }
          }

          .user-profile {
            .image {
              .icon-wrapper {
                background-color: $dark-card-background;
                box-shadow: 1px 1px 3px 1px $dark-body-background;
              }
              .avatar {
                img {
                  border: 5px solid $dark-border-color;
                }
              }
            }
            .social-media {
              a {
                color: $dark-small-font-color;
              }
            }
            .follow {
              span {
                color: $dark-small-font-color;
              }
              .follow-num {
                color: $dark-all-font-color;
              }
            }
          }
        }
        .status {
          color: $dark-small-font-color;
          p {
            color: $dark-all-font-color !important;
          }
        }
        .chat-right-aside {
          .chat {
            .chat-header {
              border-bottom: 1px solid $dark-border-color;
              .chat-menu-icons {
                li {
                  a {
                    i {
                      color: $dark-small-font-color;
                    }
                  }
                }
              }
              img {
                box-shadow: 1px 1px 4px 1px $dark-body-background;
              }
            }
            .chat-msg-box {
              .my-message {
                border: 1px solid $dark-border-color;
              }
              .message {
                color: $dark-all-font-color;
              }
              .other-message {
                background-color: $dark-body-background;
              }
            }
            .chat-message {
              background-color: $dark-card-background;
              border-top: 1px solid $dark-border-color;
              .input-group {
                .form-control {
                  background-color: $dark-card-background;
                }
              }
            }
          }
        }
        .chat-history {
          .call-icons {
            ul {
              li {
                border: 1px solid $dark-border-color;
                a {
                  color: $dark-small-font-color;
                }
              }
            }
          }
        }
        .bitcoin-chat {
          .chat {
            .chat-msg-box {
              .message {
                &.my-message {
                  background-color: #1c222b;
                  .chat-user-img {
                    border: 2px solid $dark-body-background;
                  }
                }
                &.other-message {
                  .chat-user-img {
                    border: 2px solid $dark-body-background;
                  }
                }
              }
            }
          }
        }
      }
      pre {
        background-color: $dark-border-color;
        color: $dark-all-font-color;
      }
      .scorlled {
        background-color: $dark-body-background;
      }
      .input-group-air {
        box-shadow: 0 3px 20px 0 $dark-border-color;
      }
      .input-group-solid {
        .input-group-text,
        .form-control {
          background: $dark-body-background;
          border: 1px solid $dark-border-color;
          color: $dark-all-font-color;
        }
      }
      .semilight-bg-color,
      .header-bg-color {
        background-color: $white;
      }
      .list-group-item {
        background-color: $dark-card-background;
        border: 1px solid rgba(255, 255, 255, 0.3);
        color: $dark-all-font-color;
        &.active {
          background-color: $primary-color;
          border-color: $primary-color;
          color: $white;
        }
        &:hover,
        &:focus {
          z-index: 1;
          text-decoration: none;
        }
      }
      .list-group-item-action {
        &:hover:not(.active),
        &:focus {
          background-color: $dark-body-background;
        }
      }
      .list-group-flush {
        .list-group-item {
          border-left: 0;
          border-right: 0;
          border-radius: 0;
        }
      }
      .list-group-item-primary {
        color: #004085;
        background-color: #b8daff;
      }
      .list-group-item-secondary {
        color: #383d41;
        background-color: #d6d8db;
      }
      .list-group-item-success {
        color: #155724;
        background-color: #c3e6cb;
      }
      .list-group-item-danger {
        color: #721c24;
        background-color: #f5c6cb;
      }
      .list-group-item-warning {
        color: #856404;
        background-color: #ffeeba;
      }
      .list-group-item-info {
        color: #0c5460;
        background-color: #bee5eb;
      }
      .list-group-item-light {
        color: #818182;
        background-color: #fdfdfe;
      }
      .list-group-item-dark {
        color: #1b1e21;
        background-color: #c6c8ca;
      }
      .auth-bg {
        background-blend-mode: overlay;
        background-color: rgba(54, 64, 74, 0.9);
        .authentication-box {
          .card {
            background-color: $dark-card-background;
            .theme-form {
              .form-group {
                input {
                  color: $dark-all-font-color;
                  background-color: $dark-body-background;
                  border: 1px solid $dark-border-color;
                }
                label {
                  color: $dark-all-font-color;
                }
              }
              .checkbox {
                label {
                  color: $dark-all-font-color;
                  &::before {
                    background-color: $dark-body-background;
                    border: 1px solid $dark-border-color;
                  }
                }
              }
            }
          }
          h3,
          h4,
          h6 {
            color: $white;
          }
        }
      }
      .auth-bg-video {
        background-blend-mode: overlay;
        background-color: rgba(54, 64, 74, 0.7);
        .authentication-box {
          h4,
          h3 {
            color: $white;
          }
          h6 {
            color: $dark-small-font-color;
          }
          .card {
            background-color: $dark-card-background;
            .theme-form {
              .form-group {
                input[type="text"],
                input[type="password"] {
                  background-color: $dark-body-background;
                  border: 1px solid $dark-border-color;
                  color: $dark-all-font-color;
                }
              }
              .checkbox {
                label {
                  &::before {
                    background-color: $dark-body-background;
                    border: 1px solid $dark-border-color;
                  }
                }
              }
            }
          }
        }
      }
      .error-wrapper {
        .maintenance-icons {
          li {
            i {
              color: $dark-border-color;
            }
            &:nth-child(3) {
              i {
                color: $dark-border-color;
              }
            }
          }
        }
      }
      .modal-content {
        background-color: $dark-card-background;
        .modal-header {
          border-bottom: 1px solid $dark-border-color;
          .close {
            color: $dark-small-font-color;
          }
        }
        .modal-footer {
          border-top: 1px solid $dark-border-color;
        }
      }
      .comingsoon {
        background-blend-mode: overlay;
        background-color: rgba(54, 64, 74, 0.9);
        .comingsoon-inner {
          h5 {
            color: $dark-all-font-color;
          }
          .countdown {
            border-top: 1px solid $dark-border-color;
            border-bottom: 1px solid $dark-border-color;
            .title {
              color: $dark-all-font-color;
            }
          }
          .coming-soon-bottom-link {
            a {
              color: $dark-all-font-color;
            }
          }
        }
      }
      .theme-form {
        .login-divider {
          border-top: 1px solid $dark-border-color;
          &:before {
            background: $dark-border-color;
            color: $dark-all-font-color;
          }
        }
      }
      .authentication-main {
        background-color: $dark-border-color;
        .auth-innerright {
          .reset-password-box {
            .card {
              background-color: $dark-card-background;
            }
            .reset-password-link {
              color: $dark-small-font-color;
            }
            .theme-form {
              .form-group {
                label {
                  color: $dark-small-font-color;
                }
                .form-control {
                  background-color: $dark-body-background;
                  color: $dark-all-font-color;
                  border-color: $dark-border-color;
                }
              }
              .opt-box {
                background-color: $dark-border-color;
              }
            }
          }
          .authentication-box {
            h4 {
              color: $dark-all-font-color;
            }
            h6 {
              color: $dark-small-font-color;
            }
            h3 {
              color: $dark-all-font-color;
            }
            .card {
              background-color: $dark-card-background;
              .theme-form {
                .form-group {
                  input[type="text"],
                  input[type="password"] {
                    background-color: $dark-body-background;
                    border: 1px solid $dark-border-color;
                    color: $dark-all-font-color;
                  }
                }
                .checkbox {
                  label {
                    &::before {
                      background-color: $dark-body-background;
                      border: 1px solid $dark-border-color;
                    }
                  }
                }
              }
            }
          }
        }
      }
      .default-according {
        .card {
          background-color: $dark-card-background;
          .btn-link {
            background-color: $dark-card-background;
            border: 1px solid $dark-card-background;
            color: $white;
          }
          .text-muted {
            color: $dark-small-font-color !important;
          }
        }
        .bg-primary {
          .btn-link {
            background-color: $primary-color;
            border: 1px solid $primary-color;
          }
        }
        .bg-secondary {
          .btn-link {
            background-color: $secondary-color;
            border: 1px solid $secondary-color;
          }
        }
      }
      .collapse {
        .card-body {
          background-color: $dark-card-background;
        }
      }
      @media screen and (max-width: 1660px) {
        .chat-box {
          .chat-history {
            .call-icons {
              ul {
                li {
                  border: 1px solid $dark-border-color;
                }
              }
            }
            .total-time {
              h2 {
                color: $dark-all-font-color;
              }
            }
          }
        }
        .caller-img {
          img {
            opacity: 0.7;
          }
        }
        .chat-box {
          .chat-history {
            .call-content {
              > div {
                background-color: rgba(0, 0, 0, 0.75);
                background-blend-mode: overlay;
              }
            }
          }
        }
      }
      @media only screen and (max-width: 1199px) {
        .chat-menu {
          border-top: 1px solid $dark-border-color;
          background-color: $dark-card-background;
        }
        .error-wrapper {
          .maintenance-icons {
            li {
              i {
                color: $dark-color;
              }
            }
          }
        }
      }
      @media only screen and (max-width: 575px) {
        .user-profile {
          .hovercard {
            .info {
              .user-designation {
                border-top: 1px solid $dark-border-color;
                border-bottom: 1px solid $dark-border-color;
              }
            }
          }
        }
        .widget-joins {
          .border-after-xs {
            &:after {
              background-color: $dark-border-color;
            }
          }
        }
      }
    }
    .lg-backdrop {
      background-color: $dark-body-background;
    }
    .lg-outer {
      .lg-thumb-outer {
        background-color: $dark-card-background;
      }
    }
    .theme-form {
      .form-group {
        select.form-control:not([size]):not([multiple]) {
          background-color: $dark-body-background;
          color: $dark-all-font-color;
          border-color: $dark-border-color;
        }
        input[type="text"],
        input[type="email"],
        input[type="search"],
        input[type="password"],
        input[type="number"],
        input[type="tel"],
        input[type="date"],
        input[type="datetime-local"],
        input[type="time"],
        input[type="datetime-local"],
        input[type="month"],
        input[type="week"],
        input[type="url"],
        input[type="file"],
        select {
          border-color: $dark-border-color;
          background-color: $dark-body-background;
          color: $dark-all-font-color;
          &::-webkit-input-placeholder {
            color: $dark-small-font-color;
          }
        }
        .form-control {
          background-color: $dark-body-background;
          color: $dark-all-font-color;
        }
        textarea {
          border-color: $dark-border-color;
        }
      }
      .form-divider {
        border-top: 1px solid $dark-border-color;
        &::before {
          background: $dark-card-background;
          color: $dark-all-font-color;
        }
      }
    }
    .CodeMirror {
      background: $dark-card-background;
      border: 1px solid $dark-small-font-color;
      color: $dark-all-font-color;
      .CodeMirror-code {
        pre {
          background: $dark-card-background;
        }
      }
    }
    .editor-toolbar {
      border-top: 1px solid $dark-small-font-color;
      border-left: 1px solid $dark-small-font-color;
      border-right: 1px solid $dark-small-font-color;
      a {
        color: $dark-all-font-color !important;
        &:hover,
        &.active {
          background: $dark-border-color;
        }
      }
      i.separator {
        border-left: 1px solid $dark-small-font-color;
        border-right: 1px solid $dark-small-font-color;
      }
    }
    .editor-toolbar.disabled-for-preview {
      a {
        &:not(.no-disable) {
          background: $dark-border-color;
        }
      }
    }
    .editor-preview {
      background-color: $dark-card-background;
    }
    .editor-toolbar.fullscreen,
    .editor-preview-side {
      background: $dark-card-background;
      border: 1px solid $dark-small-font-color;
    }
    .u-step {
      background: $dark-border-color;
      &.active,
      &.current {
        background: $primary-color;
        color: $white;
      }
    }
    .u-step-title,
    .u-pearl-title {
      color: $dark-all-font-color;
    }
    .u-step-number {
      background-color: $dark-card-background;
    }
    .u-pearl {
      &:before {
        background-color: $dark-border-color;
      }
    }
    .u-pearl-number,
    .u-pearl-icon {
      background: $dark-body-background;
      border: 2px solid $dark-body-background;
    }
    .u-pearl.disabled {
      .u-pearl-icon,
      .u-pearl-number {
        background: $dark-border-color;
        border: 2px solid $dark-border-color;
      }
      &:after {
        background-color: #334053;
      }
    }
    .u-pearl.error {
      &:after {
        background-color: $dark-body-background;
      }
    }
    .note-editor.note-frame {
      border-color: $dark-border-color;
      .note-editing-area {
        .note-editable {
          background-color: $dark-card-background;
          color: $dark-all-font-color;
        }
      }
    }
    .swal-modal {
      background-color: $dark-card-background;
      .swal-title {
        color: $dark-all-font-color;
      }
      .swal-text {
        color: $dark-small-font-color;
      }
      .swal-content__input {
        background-color: $dark-body-background;
        color: $dark-all-font-color;
      }
    }
    .nav-tabs {
      border-bottom: 1px solid $dark-border-color;
      .nav-link {
        &.active {
          background-color: $dark-card-background;
          color: $dark-all-font-color;
          border-color: $dark-border-color $dark-border-color
            $dark-card-background;
        }
        &:hover,
        &:focus {
          border-color: $dark-border-color $dark-border-color $dark-border-color;
        }
      }
      .nav-item.show {
        .nav-link {
          background-color: $dark-card-background;
          color: $dark-all-font-color;
          border-color: $dark-border-color $dark-border-color
            $dark-card-background;
        }
      }
    }
    .nav-tabs.nav-bottom {
      .nav-item {
        .nav-link {
          &.active,
          &.focus,
          &.hover {
            border-color: $dark-card-background $dark-border-color
              $dark-border-color;
          }
        }
      }
    }
    .dropdown-divider {
      border-top: 1px solid $dark-border-color;
    }
    .icon-hover-bottom {
      background-color: $dark-card-background;
      box-shadow: 0px 0px 1px 1px $dark-border-color;
      .icon-title {
        color: $dark-all-font-color;
      }
      span {
        color: $dark-small-font-color;
      }
      .form-group {
        input {
          background-color: $dark-body-background;
          color: $dark-all-font-color;
          border: 1px solid $dark-border-color;
        }
      }
      .icon-first {
        i {
          color: $dark-small-font-color;
        }
      }
    }
    code {
      background-color: #d8ecff;
      border-radius: 2px;
    }
    #cd-timeline {
      &::before {
        background: $dark-border-color;
      }
    }
    .timeliny {
      border-top: 1px solid $dark-border-color;
      border-bottom: 1px solid $dark-border-color;
      &::before {
        background-color: $white;
      }
      .timeliny-dot {
        background-color: $dark-card-background;
        border: 1px solid $white;
        &::before {
          color: $dark-all-font-color;
        }
      }
      .timeliny-timeline {
        .timeliny-timeblock {
          &.active {
            .timeliny-dot {
              &::before {
                color: $dark-all-font-color;
              }
            }
          }
          &:not(.inactive):not(.active) {
            .timeliny-dot {
              &:hover {
                background-color: $white;
                &::before {
                  color: $dark-all-font-color;
                }
              }
            }
          }
          .timeliny-dot {
            &:hover {
              &::after {
                background-color: $dark-card-background;
                border: 1px solid $dark-border-color;
                color: $dark-all-font-color;
              }
            }
          }
        }
      }
    }
    .todo {
      .todo-list-wrapper {
        #todo-list {
          li {
            .task-container {
              background: $dark-card-background;
              border-bottom: 1px solid $dark-border-color;
              .task-label {
                color: $dark-all-font-color;
              }
              &:hover {
                h4 {
                  color: $white;
                }
              }
              .task-action-btn {
                .action-box {
                  &:hover:not(.active) {
                    background: $dark-card-background;
                    border: 1px solid $dark-border-color;
                  }
                }
              }
            }
            &.completed {
              .task-container {
                .task-label {
                  color: $primary-color;
                }
                .complete-btn {
                  &:hover {
                    border: 1px solid $success-color !important;
                  }
                }
              }
            }
          }
        }
        .todo-list-footer {
          .new-task-wrapper {
            textarea {
              background-color: $dark-card-background;
              border: 1px solid $dark-border-color;
              color: $dark-all-font-color;
            }
          }
        }
      }
    }
    .user-profile {
      .ttl-info {
        h6 {
          color: $dark-small-font-color;
        }
        span {
          color: $dark-all-font-color;
        }
      }
      .hovercard {
        .info {
          .title {
            a {
              color: $dark-all-font-color;
            }
          }
        }
        .user-image {
          .avatar {
            img {
              border: 10px solid $dark-card-background;
            }
          }
          .icon-wrapper {
            background-color: $dark-card-background;
          }
        }
        .tabs-scoial {
          border-bottom: none !important;
        }
      }
      .follow {
        .follow-num {
          color: $dark-all-font-color;
        }
      }
      .profile-img-style {
        .user-name {
          color: $dark-all-font-color;
        }
        p {
          color: $dark-small-font-color;
        }
      }
    }
    span.twitter-typeahead {
      .tt-menu {
        background-color: $dark-body-background;
        color: $dark-all-font-color;
      }
      .tt-suggestion {
        color: $dark-all-font-color;
        &:hover,
        &:focus {
          background-color: $dark-card-background;
        }
      }
    }
    .typography {
      small {
        color: $dark-all-font-color;
      }
    }
    .blockquote-footer {
      color: $dark-small-font-color;
    }
    .code-box-copy {
      pre {
        background-color: $dark-body-background;
        code {
          background-color: $dark-body-background;
        }
      }
      pre[class*="language-"] {
        border: 1px solid $dark-border-color;
      }
      .code-box-copy__btn {
        background-color: $dark-body-background;
        border: 1px solid $dark-border-color;
        color: $white;
      }
      code[class*="language-"],
      pre[class*="language-"] {
        text-shadow: 0px 1px $black;
        ::selection {
          text-shadow: none;
          background: $dark-card-background;
        }
      }
    }
    table.fixedHeader-floating {
      background-color: $dark-body-background;
    }
    .error-wrapper {
      background-color: rgba(54, 64, 74, 0.7);
      .sub-content {
        color: $dark-all-font-color;
      }
    }
    .b-light {
      border: 1px solid $dark-border-color !important;
    }
    .modal-content {
      background-color: $dark-card-background;
      .modal-header {
        border-bottom: 1px solid $dark-border-color;
      }
    }
    .modal {
      .theme-close {
        background-color: $dark-card-background !important;
      }
    }
    .loader-box {
      &.card-loader {
        background-color: $dark-card-background;
      }
    }
    .my-gallery {
      &.gallery-with-description {
        img {
          border: 1px solid $dark-border-color !important;
          border-bottom: none !important;
        }
      }
    }
    @each $var in $alert-name {
      $i: index($alert-name, $var);
      .alert-#{$var}.inverse {
        color: $dark-all-font-color;
      }
    }
    .alert-theme {
      span {
        + span {
          + span {
            border: 1px solid $primary-color;
            background-color: $dark-body-background;
            border-radius: 4px;
          }
        }
      }
      i {
        background-color: $primary-color;
        color: $white;
        padding: 20px;
        margin-right: 20px !important;
      }
    }
    .user-card {
      .user-deatils {
        h6 {
          color: $dark-small-font-color;
        }
      }
      .card-footer {
        > div {
          &:first-child {
            border-right: 1px solid $dark-border-color;
          }
        }
        .user-footer {
          h6 {
            color: $dark-small-font-color;
          }
          svg {
            path,
            rect {
              color: $dark-small-font-color;
            }
          }
        }
      }
    }
    .dashboard-chat {
      .chat {
        .d-flex {
          .flex-grow-1 {
            .message-main {
              p {
                background-color: $dark-body-background;
              }
              &.smiley-bg {
                background-color: $dark-body-background;
              }
            }
          }
        }
        .right-side-chat {
          .flex-grow-1 {
            p {
              &:before {
                border-left: 7px solid $dark-body-background;
              }
            }
          }
        }
        .left-side-chat {
          .flex-grow-1 {
            p {
              &:before {
                border-right: 7px solid $dark-body-background;
              }
            }
          }
        }
      }
    }
    .selling-update {
      border: 2px solid $dark-border-color;
    }
    .invest-dropdown {
      .right-header-dropdown {
        &.onhover-show-div {
          background-color: $dark-body-background;
          box-shadow: 0 0 2px 2px $dark-body-background;
          a {
            color: $dark-all-font-color;
          }
        }
      }
    }
    .server-card-bg {
      background-color: #181f27 !important;
    }
    .server-widgets {
      .bottom-server {
        h5 {
          .second-color {
            color: $dark-all-font-color;
          }
        }
      }
    }
    .tabs-project {
      .nav-item {
        .nav-link {
          color: $dark-all-font-color;
        }
      }
    }
    .current-progress,
    .order-history {
      table {
        tr {
          td,
          th {
            border-top: none !important;
          }
        }
      }
      .title-orders {
        background-color: $dark-body-background;
      }
    }
    .navigation-option {
      ul {
        li {
          a {
            color: $dark-all-font-color;
          }
          &:hover {
            background-color: $dark-body-background;
          }
        }
      }
    }
    .product-box {
      .product-details {
        h6 {
          color: $dark-all-font-color;
        }
        span {
          color: $dark-small-font-color;
        }
      }
      .product-img {
        .product-hover {
          ul {
            li {
              background-color: $dark-card-background;
              &:hover {
                background-color: $black;
                color: $white;
              }
            }
          }
        }
      }
    }
    .browse {
      .browse-articles {
        ul {
          li {
            a {
              color: $dark-all-font-color;
            }
            &:hover {
              background-color: $dark-body-background;
            }
          }
        }
      }
    }
    @each $btn-name, $btn-color in (primary, $primary-color),
      (secondary, $secondary-color), (success, $success-color),
      (danger, $danger-color), (info, $info-color), (light, $light-color),
      (dark, $dark-color), (warning, $warning-color)
    {
      .radio-#{$btn-name} {
        input[type="radio"] {
          & + label {
            &::before {
              border-color: $btn-color !important;
            }
            &::after {
              background-color: $btn-color;
            }
          }
          &:checked {
            & + label {
              &::before {
                border-color: $btn-color !important;
              }
              &::after {
                background-color: $btn-color;
              }
            }
          }
        }
      }
    }

    @each $btn-name, $btn-color in (primary, $primary-color),
      (secondary, $secondary-color), (success, $success-color),
      (danger, $danger-color), (info, $info-color), (light, $light-color),
      (dark, $dark-color), (warning, $warning-color)
    {
      .checkbox-#{$btn-name} {
        label {
          &::before {
            border-color: $btn-color !important;
          }
        }
      }
    }

    @each $btn-name, $btn-color in (primary, $primary-color),
      (secondary, $secondary-color), (success, $success-color),
      (danger, $danger-color), (info, $info-color), (light, $light-color),
      (dark, $dark-color), (warning, $warning-color)
    {
      .checkbox-solid-#{$btn-name} {
        label {
          &:before {
            background-color: $btn-color !important;
            border-color: $btn-color !important;
            color: $white !important;
          }
        }
        input[type="checkbox"] {
          &:checked {
            & + label {
              &::before {
                background-color: $btn-color !important;
                border-color: $btn-color !important;
                color: $white !important;
              }
              &::after {
                color: $white !important;
              }
            }
          }
        }
      }
    }
    .bootstrap-datetimepicker-widget {
      ul {
        li {
          color: $dark-all-font-color;
          border-bottom: 1px solid $dark-border-color;
        }
      }
      tr {
        th,
        td {
          &:hover {
            background-color: $dark-card-background;
          }
          span {
            &:hover {
              background-color: $dark-card-background;
            }
          }
          &.prev,
          &.next {
            &:hover {
              background-color: $dark-card-background;
            }
          }
        }
        &:first-child {
          th {
            &:hover {
              background-color: $dark-card-background;
            }
          }
        }
      }
    }
    .btn-transparent {
      color: $dark-all-font-color;
    }
    .categories {
      ul {
        li {
          a {
            color: $dark-small-font-color;
          }
          &:hover {
            background-color: $dark-body-background;
          }
        }
      }
    }
    .main {
      .langChoice {
        background: $dark-body-background;
        color: $white;
        border: 1px solid $dark-border-color;
      }
    }
    .socialprofile {
      .likes-profile {
        h5 {
          span {
            color: $dark-all-font-color;
          }
        }
      }
      span {
        color: $dark-small-font-color;
      }
    }
    .social-status {
      .d-flex {
        .flex-grow-1 {
          span {
            + span {
              color: $dark-small-font-color;
            }
          }
          p,
          .light-span {
            color: $dark-small-font-color;
          }
        }
      }
      form {
        .form-group {
          .form-control-plaintext {
            border: 1px solid $dark-border-color;
          }
        }
      }
    }
    .filter-cards-view,
    .timeline-content {
      p {
        color: $dark-small-font-color;
      }
      .comment-number {
        i {
          color: $dark-small-font-color;
        }
      }
      .comments-box {
        .input-group {
          .form-control {
            border: none !important;
          }
          .input-group-append {
            background-color: $dark-body-background;
          }
        }
      }
    }
    .social-chat {
      .flex-grow-1 {
        border: 1px solid $dark-border-color;
        &:after {
          border-right: 7px solid $dark-card-background;
        }
      }
      span {
        span {
          color: $dark-small-font-color;
        }
      }
    }
    .details-about {
      .your-details {
        p {
          color: $dark-small-font-color;
        }
      }
    }
    .activity-log {
      .my-activity {
        p {
          color: $dark-small-font-color;
        }
      }
    }
    &.rtl {
      .page-main-header {
        .main-header-right {
          .nav-right {
            > ul {
              > li {
                border-right: none;
                &:nth-child(2) {
                  border-right: 1px solid $dark-border-color;
                }
                &:nth-child(5) {
                  border-right: none;
                  border-left: 1px solid $dark-border-color;
                }
                &:nth-child(4) {
                  border-left: none;
                }
              }
            }
          }
        }
      }
    }
    .bookmark {
      ul {
        li {
          a {
            color: $dark-all-font-color;
          }
        }
      }
    }
  }
}
/**=====================
   55. theme layout CSS ends
==========================**/

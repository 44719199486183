/* NX = not expanded #0c497e*/

.side-nav-container {
  position: relative;
  background-color: #fefefefe;
  width: 250px;
  height: 100vh;
  color: white;
  z-index: 10;
  box-shadow: 1px 0px 10px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 1px 0px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 1px 0px 10px 0px rgba(0, 0, 0, 0.1);
  transition: 0.3s ease-in-out;
}

.side-nav-container-NX {
  width: 70px;
  transition: 0.3s ease-in-out;
}

.tooltip-border {
  background: var(--primaryColor);
  width: 15px;
  height: 14px;
  margin-left: -6px;
  transform: rotate(135deg);
}

.menu-item-tooltip {
  display: none;
  position: absolute;
  background-color: var(--primaryColor);
  left: 100%;
  top: 20%;
  color: #fff;
  padding: 5px;
  z-index: 9999999999999;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  margin-left: 10px;
  font-size: 10px;
  width: auto;
  overflow: visible;
  white-space: nowrap;
}

.menu-item:hover .menu-item-tooltip {
  display: flex;
}

.nav-heading {
  display: flex;
  justify-content: center;
  height: 65px;
  background: none;
}

.nav-brand {
  display: flex;
  margin: 0 auto;
}

.nav-brand img {
  width: 150px;
  object-fit: contain;
}

.right-menu-items {
  position: absolute;
  left: calc(100%);
  background-color: #fefefe;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  color: white;
  flex-direction: column;
  z-index: 1000;
  box-shadow: 5px 7px 10px rgba(0, 0, 0, 0.2);
  width: 200px;
}

.right-menu-items .menu-item {
  color: white; /* Text color for sub-menu items */
  text-decoration: none; /* Remove underlines for links */
  padding: 8px 0; /* Add padding as needed */
}

.hamburger-in {
  background: white;
  display: flex;
  align-self: center;
  position: absolute;
  padding: 0.5px;
  bottom: 16px;
  right: -14px;
  border-radius: 50%;
  border: 1px solid gainsboro;
  /* box-shadow: 0px 1px 8px rgb(0 0 0 / 0.2); */
  transition: -webkit-transform 0.4s ease-in-out;
  transition: transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  cursor: pointer;
}

.hamburger-in:hover {
  background-color: whitesmoke;
}

.hamburger-out:hover {
  background-color: whitesmoke;
}

.hamburger-out {
  background: white;
  display: flex;
  align-self: center;
  position: absolute;
  bottom: 16px;
  right: -18px;
  padding: 0.5px;
  border-radius: 50%;
  border: 1px solid gainsboro;
  /* box-shadow: 0px 1px 8px rgb(0 0 0 / 0.2); */
  transition: -webkit-transform 0.4s ease-in-out;
  transition: transform 0.4s ease-in-out, -webkit-transform 0.4s ease-in-out;
  cursor: pointer;
}

.nav-menu {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  overflow-x: hidden;
}

.menu-item {
  position: relative;
  display: flex;
  align-items: center;

  gap: 14px;
  color: #44546f;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.2px;
  text-decoration: none;
  padding: 10px 24px;
}

.menu-children-item {
  display: flex;
  align-items: center;
  gap: 14px;
  color: #44546f;
  font-size: 14px;
  text-decoration: none;
  padding: 8px 58px;
}

.menu-children-item-nx {
  display: flex;
  align-items: center;
  gap: 14px;
  color: #44546f;
  width: 200px;
  font-size: 14px;
  text-decoration: none;
  padding: 8px 14px;
}

.submenu-item {
  display: flex;
  color: white;
  text-decoration: none;
  flex-direction: column;
  cursor: pointer;
}

.width70 {
  width: 70% !important;
}

.vertical-line {
  display: flex;
  align-items: center;
  border-left: 1px solid white;
  margin-left: 6px;
}

.submenu-header {
  display: flex;
  align-items: center;
  gap: 14px;
  color: #44546f;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.2px;
  text-decoration: none;
  padding: 10px 24px;
}

.sub-menu-items {
  font-size: 13px;
}

.menu-item-NX {
  margin: auto;
}

.menu-item:hover {
  background-color: var(--activeMenuBgColor);
  color: #44546f !important;
}

.menu-children-item:hover {
  background-color: var(--activeMenuBgColor);
  color: #44546f !important;
}
.menu-children-item-nx.active {
  border-right: 3px solid var(--primaryColor) !important;
  color: var(--primaryColor) !important;
}
.menu-children-item-nx:hover {
  background-color: var(--activeMenuBgColor);
  color: #44546f !important;
}

.menu-item.active {
  border-right: 3px solid var(--primaryColor);
  background-color: var(--activeMenuBgColor) !important;
  color: var(--primaryColor) !important;
}

.menu-children-item.active {
  border-right: 3px solid var(--primaryColor) !important;
  background-color: var(--activeMenuBgColor);
  color: var(--primaryColor) !important;
}

.submenu-header:hover {
  background-color: var(--activeMenuBgColor);
  color: #44546f !important;
}

.submenu-header.active {
  border-right: 3px solid var(--primaryColor);
  background-color: var(--activeMenuBgColor);
  color: var(--primaryColor) !important;
}

.menu-item img {
  width: 30px;
  padding: 0 20px;
}

.nav-footer {
  height: 65px;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.nav-details {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 14px 16px;
  gap: 16px;
}

.nav-details img {
  width: 36px;
  height: 36px;
}

.nav-footer-user-name {
  font-size: 14px;
  font-weight: 700;
  color: #44546f;
}

.logout-icon {
  width: 30px;
  margin: auto;
  border-radius: 90px;
  padding: 20px;
  margin-left: 5px;
}

.logout-icon:hover {
  background-color: var(--primary);
}

.menu-item-icon {
  width: 20px !important;
  height: 20px !important;
}

/* Dropdown container */
.nav-details {
  position: relative;
  cursor: pointer;
}

.width-auto {
  width: auto !important;
}

.border-line {
  display: flex;
  margin: 0 auto;
  width: 85%;
  border-bottom: 1px solid gainsboro;
}

.border-line-nx {
  display: flex;
  align-self: center;
  justify-self: center;
  width: 50px;
  border-bottom: 1px solid gainsboro;
}

/* Dropdown content (hidden by default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #fefefe;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 5px;
}

/* Dropdown links */
.dropdown-content a {
  color: #44546f;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Show the dropdown content when the dropdown is active */
.nav-details:hover .dropdown-content {
  display: block;
}

/* Style for the user name */
.nav-footer-user-name {
  margin: 0;
}

/* Adjust the dropdown position to open upwards */
.dropdown-content {
  bottom: 50px; /* Adjust as needed */
  left: 20px; /* Adjust as needed */
  top: auto; /* Reset top property */
}

.dropdown-items {
  display: flex !important;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

.dropdown-items:hover {
  background-color: whitesmoke;
}

.dropdown-items-logout {
  display: flex !important;
  width: 100%;
  height: 40px;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  color: red !important;
  border-top: 1px solid gainsboro;
}

.dropdown-items-logout:hover {
  background-color: whitesmoke;
}

.settings-button {
  position: absolute;
  bottom: 70px;
  width: 100% !important;
  cursor: pointer;
}

.buttonSettings {
  display: flex;
  align-items: center;
  gap: 14px;
  color: #44546f;
  background: none;
  border: none;
  font-size: 16px;
  font-weight: 500;
  width: 100% !important;
  letter-spacing: 0.2px;
  text-decoration: none;
  padding: 10px 20px;
  cursor: pointer;
}

.buttonSettings:hover {
  background-color: whitesmoke;
}

.open-modal-button {
  display: flex;
  align-items: center;
  border: none;
  background: none;
  gap: 12px;
  color: #44546f;
  padding: 10px 15px;
}

.open-modal-button:hover {
  background: whitesmoke;
}

.ql-token .ql-custom-token-button {
  display: flex !important;
  align-items: top !important;
  padding: 6px !important;
}

/* Dropdown styling */
.ql-token .ql-custom-token-dropdown {
  display: none;
  position: absolute !important;
  background: white !important;
  border: 1px solid #ddd !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
  z-index: 1000 !important;
  margin-top: 8px !important;
  border-radius: 4px !important;
  padding: 10px !important;
  width: 400px !important; /* Adjust as needed */
  max-height: 350px !important;
  overflow-y: auto !important; /* Ensure vertical scrolling */
  overflow-x: hidden !important; /* Ensure no horizontal scroll */
  left: auto !important; /* Disable any left alignment */
  right: 0 !important; /* Align the dropdown to the right side of the button */
}

.ql-token .ql-custom-token-option {
  padding: 5px !important;
  cursor: pointer !important;
  border-radius: 3px !important;
  white-space: nowrap !important; /* Prevent text wrapping */
  overflow: hidden !important; /* Hide any overflow text */
  text-overflow: ellipsis !important; /* Add ellipsis for overflow text */
  width: 100% !important; /* Ensure the option takes full width */
  box-sizing: border-box !important; /* Include padding and border in width */
  color: initial !important; /* Ensure the text color is set to default */
}

.ql-token .ql-custom-token-option:hover {
  background-color: #f1f1f1 !important;
  color: initial !important; /* Keep text color unchanged on hover */
}

/* Ensure the option contains both tag and category */
.ql-custom-token-option {
  display: flex !important;
  justify-content: space-between !important;
  padding: 5px !important;
  cursor: pointer !important;
  border-radius: 3px !important;
  background-color: white !important;
  color: black !important;
}

.ql-token-tag-name {
  flex: 1 !important;
  text-align: left !important;
}

.ql-token-category-name {
  text-align: right !important;
  color: white !important; /* Adjust this color to match the style of the asset image */
  font-weight: bold !important; /* Add bold if you want it like the asset */
  background-color: #135f95 !important; /* Adjust the background color as needed */
  border-radius: 10px !important;
  padding: 0px 10px !important;
}

/* Add hover effect */
.ql-custom-token-button:hover {
  background-color: #f0f0f0 !important; /* Adjust the background color as needed */
}
.ql-custom-token-button:focus {
  background-color: #f0f7ff !important; /* Change the background color when active */
}
